import {AxiosInstance} from "axios";
import API from "../../utils/api";


export class DocumentTypesService {
    private api: AxiosInstance = API;

    async getDocumentTypes(): Promise<string[]> {
        return this.api.get('documents/types').then(v => v.data);
    }

    async getInvoiceDocumentTypes(): Promise<string[]> {
        return ["regular", "mr_auto"];
    }

    async getPackingListDocumentTypes(): Promise<string[]> {
        return ["default", "uit_romania"];
    }
}

export const documentTypesService = new DocumentTypesService();
