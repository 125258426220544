import { action, computed, makeObservable, observable } from "mobx";
import React, { Context, Key } from "react";
import { isEmpty } from "lodash";
import {FilterButtonConfig} from "../../../../models/filtered-table/filtered.table.props";
import {FilterDataHandler} from "../../../../models/filter/filter.data.handler";
import {
    Parcel,
    ParcelItem,
    Shipment,
    ShipmentItem,
    ShipmentRequest,
    TableShipment
} from "../../../../models/parcels/shipment";
import {PaginatedRequest} from "../../../../models/core/paginated.request";
import {FieldGroupSnapshot} from "../../../../models/filter/filter.field.snapshot";
import {Carier} from "../../../../models/cariers/carier";
import {shipmentsService} from "../../../../services/shipments/shipments.service";
import {cariersService} from "../../../../services/cariers/cariers.service";
import {PaginatedResult} from "../../../../models/core/paginated.result";

export enum ShipmentsFilterButtonsTypes {
    PROCESSED_SHIPMENTS = "processed_shipments",
    NOT_PROCESSED_SHIPMENTS = "not_processed_shipments",
    NO_LABELS_SHIPMENTS = "no_labels_shipments",
}

export type ActiveShipmentFilterButton =
    | typeof ShipmentsFilterButtonsTypes.PROCESSED_SHIPMENTS
    | typeof ShipmentsFilterButtonsTypes.NOT_PROCESSED_SHIPMENTS
    | typeof ShipmentsFilterButtonsTypes.NO_LABELS_SHIPMENTS
    | undefined;

export class ShipmentsDropshipStore {
    public defaultStatuses: string[] = ["label_not_created", "awaiting"];
    public statuses: string[] = ["label_not_created", "awaiting", "packed", "shipped", "delivered"];

    private notProcessedShipmentsStatuses: string[] = ["label_not_created", "awaiting"];
    private shipmentsWithoutLabelsStatuses: string[] = ["label_not_created"];
    private processedShipmentsStatuses: string[] = ["packed", "shipped", "delivered"];

    public filterButtonConfigs: FilterButtonConfig<ActiveShipmentFilterButton>[] = [
        {
            type: ShipmentsFilterButtonsTypes.NOT_PROCESSED_SHIPMENTS,
            text: "SHIPMENTS.NOT_PROCESSED",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: this.notProcessedShipmentsStatuses.join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: ShipmentsFilterButtonsTypes.NO_LABELS_SHIPMENTS,
            text: "SHIPMENTS.STATUS.LABEL_NOT_CREATED",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: this.shipmentsWithoutLabelsStatuses.join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: ShipmentsFilterButtonsTypes.PROCESSED_SHIPMENTS,
            text: "SHIPMENTS.PROCESSED",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: this.processedShipmentsStatuses.join(","),
                        },
                    ],
                },
            ],
        },
    ];

    public dataHandler: FilterDataHandler<Shipment>;

    public request: PaginatedRequest | null = null;

    public types: string[] = ["box", "pallet"];

    @observable
    public labelsLink: string = "orders/shipments/labels?";

    @observable
    public filters: FieldGroupSnapshot[] = [
        {
            name: "statuses",
            snapshots: [
                {
                    name: "statuses",
                    value: this.defaultStatuses.join(","),
                },
            ],
        },
    ];

    @observable
    public dispatching: boolean = false;

    @observable
    public modalShown: boolean = false;

    @observable
    public editModalShown: boolean = false;

    @observable
    public fetchingModalItem: boolean = false;

    @observable
    public modalItem: ShipmentItem | null = null;

    @observable
    public editModalItem: ShipmentItem | null = null;

    @observable
    public selectedShipments: TableShipment[] = [];

    @observable
    public selectedParcelItemsKeys: { [key: number]: number[] } = {};

    @observable
    public printing: boolean = false;

    @observable
    public cariers: Carier[] = [];

    constructor(private readonly token: string) {
        makeObservable(this);
        this.closeModal = this.closeModal.bind(this);
        this.setRequest = this.setRequest.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.printStickers = this.printStickers.bind(this);
        this.createStickers = this.createStickers.bind(this);
        ShipmentsDropshipStore.convertDataForTable = ShipmentsDropshipStore.convertDataForTable.bind(this);

        this.dataHandler = new FilterDataHandler<Shipment>(
            (request) => {
                request.filters["processingType"] = "dropship";
                return shipmentsService.getShipments(request);
            },
            ShipmentsDropshipStore.convertDataForTable
        );

        this.getCariers();
    }

    @action
    public setEditModalShown(value: boolean): void {
        this.editModalShown = value;
    }

    @action
    public setEditModalItem(value: ShipmentItem | null): void {
        this.editModalItem = value;
    }

    @action
    public setModalShown(value: boolean): void {
        this.modalShown = value;
    }

    @action
    public setModalItem(value: ShipmentItem | null): void {
        this.modalItem = value;
    }

    @action
    public setFetchingModalItem(value: boolean): void {
        this.fetchingModalItem = value;
    }

    @action
    public setCariers(value: Carier[]): void {
        this.cariers = value;
    }

    @action
    public setPrinting(value: boolean): void {
        this.printing = value;
    }

    @action
    private setSelectedShipments(value: TableShipment[]): void {
        this.selectedShipments = value;
    }

    @action
    private resetSelectedParcelItemsKeys(): void {
        this.selectedParcelItemsKeys = {};
    }

    @action
    private setSelectedParcelItemsKeys(parcel: Parcel, value: Key[] & number[]) {
        this.selectedParcelItemsKeys[parcel.id] = value;
    }
    @action
    private removeSelectedParcelItemKey(parcel: Parcel, item: ParcelItem): void {
        const index = this.selectedParcelItemsKeys[parcel.id].indexOf(item.id);
        if (index !== -1) {
            this.selectedParcelItemsKeys[parcel.id].splice(index, 1);
        }
    }

    @action
    private setDispatching(value: boolean): void {
        this.dispatching = value;
    }

    @action
    private setLabelsLink(value: string): void {
        this.labelsLink = value;
    }

    private generateLabelsLink(): void {
        const getParamsString: string =
            "/orders/shipments/labels?id[]=" +
            this.selectedShipments.map((item) => item.id).join("&id[]=") +
            "&token=" +
            this.token;
        this.setLabelsLink(getParamsString);
    }

    private async getCariers() {
        this.setCariers(await cariersService.getCariers());
    }

    public async openEditModal(item: TableShipment): Promise<void> {
        this.setEditModalItem(await shipmentsService.getShipment(item.id));
        this.setEditModalShown(true);
    }

    public selectShipments(shipments: TableShipment[]): void {
        this.setSelectedShipments(shipments.filter((value) => !value.id.includes("CHILD")));
        this.generateLabelsLink();
    }

    public closeEditModal(): void {
        this.setEditModalShown(false);
        this.setEditModalItem(null);
    }

    public selectParcelsItems(parcel: Parcel, parcelItemsKeys: Key[]): void {
        this.setSelectedParcelItemsKeys(parcel, parcelItemsKeys as number[]);
    }

    public onParcelItemClick(parcel: Parcel, record: ParcelItem): void {
        if (!this.selectedParcelItemsKeys[parcel.id]) {
            this.setSelectedParcelItemsKeys(parcel, [record.id]);
            return;
        }
        if (!this.selectedParcelItemsKeys[parcel.id].includes(record.id)) {
            this.setSelectedParcelItemsKeys(parcel, [...this.selectedParcelItemsKeys[parcel.id], record.id]);
            return;
        }
        if (this.selectedParcelItemsKeys[parcel.id].includes(record.id)) {
            this.removeSelectedParcelItemKey(parcel, record);
            return;
        }
    }

    public async openModal(recordId: string) {
        this.setModalShown(true);
        this.setFetchingModalItem(true);
        this.setModalItem(await shipmentsService.getShipment(recordId));
        this.setFetchingModalItem(false);
    }

    public closeModal(): void {
        this.setModalShown(false);
        this.resetSelectedParcelItemsKeys();
        this.setModalItem(null);
    }

    public printStickers() {
        setTimeout(() => {
            this.selectShipments([]);
        }, 500);
    }

    public async createStickers() {
        await shipmentsService.createStickers(this.selectedShipments.map((item) => parseInt(item.id, 10)));
        this.setSelectedShipments([]);
        await this.dataHandler.reloadItems(this.request!);
    }

    public async dispatch(displayNotificationCallback: (type: "success" | "error", message: string) => void) {
        this.setDispatching(true);
        try {
            await shipmentsService.dispatchShipment(this.modalItem!.id);
            await this.dataHandler.reloadItems(this.request!);
            this.resetSelectedParcelItemsKeys();
            this.setDispatching(false);
            this.setModalShown(false);
            this.setModalItem(null);
            displayNotificationCallback("success", "SHIPMENT.DISPATCH.SUCCESS");
        } catch (e: any) {
            displayNotificationCallback("error", "SHIPMENT.DISPATCH.ERROR");
            this.setDispatching(false);
        }
    }

    public async updateShipment(request: ShipmentRequest, item: Shipment) {
        const updatedShipment = await shipmentsService.updateShipment(item.id, request);
        this.dataHandler.updateItem(item, updatedShipment);
    }

    @computed
    public get isDispatchDisabled(): boolean {
        if (this.modalItem) {
            if (isEmpty(this.selectedParcelItemsKeys)) {
                return true;
            }
            const selectedParcelItemsKeysLength = Object.keys(this.selectedParcelItemsKeys)
                .map((key: string) => this.selectedParcelItemsKeys[parseInt(key, 10)])
                .reduce(
                    (previousValue: number[], currentValue: number[]): number[] => previousValue.concat(currentValue),
                    []
                ).length;

            const allParcelItemsLength = this.modalItem.parcels
                .map((parcel) => parcel.items)
                .reduce(
                    (previousValue: ParcelItem[], currentValue: ParcelItem[]): ParcelItem[] =>
                        previousValue.concat(currentValue),
                    []
                ).length;
            return selectedParcelItemsKeysLength !== allParcelItemsLength;
        }
        return false;
    }

    @computed
    public get isPrintDisabled(): boolean {
        if (!this.selectedShipments.length || this.printing) {
            return true;
        }
        return !this.selectedShipments.every((item) => item.warehouse?.id === this.selectedShipments[0].warehouse!.id);
    }

    public setRequest(value: PaginatedRequest | null): void {
        this.request = value;
    }

    public static convertDataForTable(data: PaginatedResult<Shipment>): PaginatedResult<TableShipment> {
        const items: TableShipment[] = data.items.map((shipment, index) => {
            const firstParcel = shipment.parcels && shipment.parcels[0] ? shipment.parcels[0] : null;
            const otherParcels =
                shipment.parcels && shipment.parcels.length > 1 ? shipment.parcels.slice(1) : undefined;

            return {
                id: shipment.id + "",
                isShipment: true,
                shipmentNumber: shipment.number,
                reference: shipment.reference,
                warehouse: shipment.warehouse,
                agent: shipment.agent,
                carrier: shipment.carrier.name,
                carrierCode: shipment.carrier.id,
                packedAt: shipment.packedAt,
                status: shipment.status,
                parcelId: firstParcel!.id,
                orders: shipment.orders,
                trackCode: firstParcel ? firstParcel.trackCode : "",
                type: firstParcel ? firstParcel.type : "",
                number: firstParcel ? firstParcel.number + "" : "",
                children: otherParcels
                    ? otherParcels.map((parcel) => ({
                          id: "CHILD" + shipment.id + "_" + parcel.id + parcel.boxCode + parcel.trackCode,
                          agent: shipment.agent,
                          isShipment: false,
                          parcelId: parcel.id,
                          trackCode: parcel.trackCode,
                          type: parcel.type,
                          number: parcel.number + "",
                          packedAt: null,
                          parentId: shipment.id + "",
                      }))
                    : undefined,
            };
        });
        return { meta: data.meta, items };
    }

    public async setCode(code: string) {
        this.setModalShown(true);
        this.setFetchingModalItem(true);
        this.setModalItem(await shipmentsService.getShipmentByParcelId(code));
        this.setFetchingModalItem(false);
    }
}

export const ShipmentsStoreContext = React.createContext<ShipmentsDropshipStore | null>(null) as Context<ShipmentsDropshipStore>;
