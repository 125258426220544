import {Translate} from "../../../../../../hooks/translate.hook";
import {ColumnFilterItem, ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Link} from "react-router-dom";
import {NewOrderTableData, OrderShippingType} from "../../../../../../models/orders/orders-list/order";
import {Warehouse} from "../../../../../../models/warehouses/warehouse";
import {Amount} from "../../../../../../models/prices/price";
import {displayPrice} from "../../../../../../models/prices/price.helper";
import Date from "../../../../../date/date";
import {strictComparator} from "../../../../../ui/table/filter-dropdown/filter-dropdown.comparator";
import {FilterDropdown} from "../../../../../ui/table/filter-dropdown/filter-dropdown";
import {SystemUser} from "../../../../../../models/users/user/system.user";

export const columnsFactory = (orders: NewOrderTableData[], t: Translate): ColumnsType<NewOrderTableData> => {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (id: number, order) => <Link to={"/orders/" + id}>{id}</Link>
        },
        // {
        //     title: t("CLIENT.NUMBER"),
        //     dataIndex: "clientOrderId",
        //     key: "clientOrderId",
        //     width: "225px",
        //     render: value => value || "-",
        //     sorter: (a, b) => a.clientOrderId > b.clientOrderId ? 1 : -1
        // },
        {
            title: t("FPES"),
            dataIndex: "fpes",
            key: "fpes",
            render: value => value || "-",
            sorter: (a, b) => a.fpes > b.fpes ? 1 : -1
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "warehouse",
            render: (warehouse: Warehouse) => warehouse?.name || "-",
            sorter: (a, b) => (a.warehouse?.id || "") > (b.warehouse?.id || "") ? 1 : -1,
            onFilter: strictComparator((o: NewOrderTableData) => o.warehouse?.id || ""),
            filterDropdown: FilterDropdown,
            filters: (() => {
                const warehouses: Record<string, ColumnFilterItem> = {};
                orders.forEach(i => {
                    if (i.warehouse) {
                        warehouses[i.warehouse.id] = {text: i.warehouse.name, value: i.warehouse.id};
                    } else {
                        warehouses[""] = {text: "-", value: ""};
                    }
                })
                return Object
                    .values(warehouses)
                    .sort((a, b) => (a.text || "") > (b.text || "") ? 1 : -1)
            })()
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "subtotal",
            key: "subtotal",
            render: (item: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(item)}</span>,
            sorter: (a, b) => a.subtotal.value - b.subtotal.value,
        },
        {
            title: t("COMMENT"),
            dataIndex: "joinedComments",
            key: "comments",
            sorter: (a, b) => a.joinedComments === b.joinedComments ? 0 : (a.joinedComments > b.joinedComments ? 1 : -1),
            onFilter: strictComparator((o: NewOrderTableData) => o.joinedComments),
            filterDropdown: FilterDropdown,
            filters: (() => {
                return orders
                    .map(o => o.joinedComments)
                    .filter((o, i, a) => a.indexOf(o) === i)
                    .map(c => ({text: c, value: c}))
            })()
        },
        {
            title: t("SHIPPING.TYPE"),
            dataIndex: "shipmentType",
            key: "shipmentType",
            render: (t: OrderShippingType | null) => t?.courierName || "-"
        },
        {
            title: t("ORDER.DELIVERY.RECEIVER_DETAILS"),
            dataIndex: "receiverDetails",
            key: "receiverDetails",
            onFilter: strictComparator(v => v.receiverDetails),
            filterDropdown: FilterDropdown,
            filters: orders
                .map(v => v.receiverDetails)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map(v => ({text: v, value: v}))
        },
        {
            title: t("USER.TITLE"),
            dataIndex: "user",
            key: "user",
            render: (user: SystemUser) => <>{user.firstName} {user.lastName}</>,
            sorter: (a, b) => a.user.firstName > a.user.lastName ? 1 : -1,
            filterDropdown: FilterDropdown,
            filters: orders
                .map(v => v.user.firstName + ' ' + v.user.lastName)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map(v => ({text: v, value: v}))
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item: string) => <Date dateString={item} showTime />,
            sorter: (a, b) => a.createdAt > b.createdAt ? 1 : -1
        }
    ]
}