import React from "react";
import {Col, Form, Row, Select} from "antd";
import {config} from "../config";
import {useTranslate} from "../../../../../../../hooks/translate.hook";

export interface ProductFieldsProps {
    loading: boolean;
    courierName: string;
}

export const CourierServicePayerFields: React.FC<ProductFieldsProps> = ({loading, courierName}) => {

    const t = useTranslate();

    const values = config[courierName]?.courierServicePayer;
    if (!values) {
        return null;
    }

    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    name="courierServicePayer"
                    label="Payer"
                    rules={[
                        {
                            required: true,
                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                        },
                    ]}
                >
                    <Select disabled={loading}>
                        {Object.keys(values).map(product =>
                            <Select.Option value={product}>{values[product]}</Select.Option>)}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    )
}