import React, {useMemo} from "react";
import {Amount} from "../../../models/prices/price";
import {DisplayType, format, formatCurrencyCode} from "../../../models/prices/price.helper";
import {RootStoreContext} from "../../../stores/root/root.store";
import {useRequiredStore} from "../../../utils/store";

interface Props {
    value: Amount;
    toK?: boolean;
}

export const displayPrice = (pt: DisplayType, value: Amount, toK?: boolean) => {
    if (toK && (value.value / 1000) > 1) {
        return (value.value / 1000).toFixed(0) + "K";
    } else {
        return format(value.value, pt) + " " + formatCurrencyCode(value.currencyCode);
    }
}

export const Price: React.FC<Props> = ({value, toK}) => {

    const {authStore: {lz}} = useRequiredStore(RootStoreContext)

    const displayedValue = useMemo(() => displayPrice(lz.pt, value, toK), [value.value, value.currencyCode, toK, lz.pt]);

    return (
        <span style={{whiteSpace: "nowrap", textAlign: "right" }}>
            {displayedValue}
        </span>
    );
}