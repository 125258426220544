import React, {useState} from "react";
import {Col, Form, Modal, Row, Upload} from "antd";
import {AxiosError} from "axios";
import dayjs from "dayjs";
import {articleRestrictionsService} from "../../../../../../services/articles/articles/article.restrictions.service";
import {ErrorResponseData} from "../../../../../../models/core/response";
import {ArticleData} from "../../../../../../models/articles/article.data";
import {useTranslate} from "../../../../../../hooks/translate.hook";

interface NextPriceModalProps {
    onCancel: () => void;
    onComplete: () => void;
    article: ArticleData;
}

export const UploadModal: React.FC<NextPriceModalProps> = ({onCancel, onComplete, article}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("Upload As CSV")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onCancel();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    applyForParentPrice: true,
                    applyNextPriceAt: dayjs().add(1, 'M').startOf('month')
                }}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        articleRestrictionsService.uploadAsCSV(article.id, values)
                            .then(
                                () => {
                                    onCancel();
                                    onComplete();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(
                                            e.response?.data?.fields
                                        );
                                    }
                                }
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            label={t("CSV")}
                            name="file"
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                            valuePropName={""}
                            getValueFromEvent={(e) => e.file || null}
                        >
                            <Upload.Dragger
                                beforeUpload={() => false}
                                accept={".csv"}
                                maxCount={1}
                                disabled={loading}
                            >
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Required one column with country code: countryCode<br />
                                    Example row: EE
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
