import React from "react";
import {Space} from "antd";
import {ArticleDataAvailabilityHistoryChange} from "./change/change";
import {ArticleDataAvailabilityHistoryHistory} from "./history/history";

export const ArticleDataAvailabilityHistory: React.FC = () => {

    return (
        <Space direction={"vertical"}>
            <ArticleDataAvailabilityHistoryHistory />
            <ArticleDataAvailabilityHistoryChange />
        </Space>
    );
};
