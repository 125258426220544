import {HistoryArticleWarehouse} from "../../../../../../models/articles/articles.history";
import React from "react";
import {SmartColumn} from "../../../../../common/smart-table/smart-table.model";
import {Translate} from "../../../../../../hooks/translate.hook";
import {FieldType} from "../../../../../common/smart-table/smart-table.field-config";
import {WarehouseWidget} from "../../../../../common/smart-table/filter/dropdown/widget/custom/warehouse.widget";

export const columnsFactory = (t: Translate): SmartColumn<any>[] => {
    return [
        {
            visibleName: t("WAREHOUSES.NAME"),
            name: "warehouse",
            render: (item: HistoryArticleWarehouse) => <>{item.name}</>,
            type: FieldType.STRING,
            widget: WarehouseWidget
        },
        {
            visibleName: t("QUANTITY"),
            name: "amount",
            type: FieldType.INTEGER
        },
        {
            visibleName: t("CREATED_AT"),
            name: "createdAt",
            type: FieldType.DATETIME
        }
    ]
}