import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {
    PreShipment,
    PreShipmentGroup,
    PreShipmentPallet,
    PreShipmentParcelInfo
} from "../../models/parcels/pre-shipments";
import {Shipment} from "../../models/parcels/shipment";
import {stringify} from "query-string";
import {PayerOrReceiverDetails} from "../../models/payer-receiver-seller/payer.reveiver.seller";

export class PreShipmentsService {
    private api: AxiosInstance = API;

    public getPreShipmentsReadyToShip(): Promise<PreShipment[]> {
        return this.api.get("pre-shipments/to-ship").then(x => x.data);
    }

    public getPreShipmentsReadyToShipGrouped(): Promise<PreShipmentGroup[]> {
        return this.api.get("pre-shipments/to-ship/grouped").then(x => x.data);
    }

    public getPreShipmentsReadyToShipByAgentCode(agentCode: string): Promise<PreShipment[]> {
        return this.api.get("pre-shipments/to-ship/by-agent/" + agentCode).then(x => x.data);
    }

    public getReadyToShipParcelsByDocuments(documentIds: number[]): Promise<{parcels: PreShipmentParcelInfo[], address: PayerOrReceiverDetails}> {
        return this.api.get("pre-shipments/parcels", {params: {documents: documentIds}, paramsSerializer: p => stringify(p, {arrayFormat: "bracket"})}).then(x => x.data);
    }

    public customBatchShip(documents: number[], courier: string, parcelInfo: PreShipmentParcelInfo[], address: object): Promise<Shipment> {

        const formData = new FormData();

        const body: any[] = [];

        parcelInfo.forEach((info, key) => {
            formData.append('parcelInfo[' + key + '][label]', info.label!);
            const cloned = {...info};
            delete cloned.label;
            body.push(cloned);
        })

        formData.append('_', JSON.stringify({documents, parcelInfo: body, courier, address}));

        return this.api.post("pre-shipments/custom-batch-ship", formData).then(x => x.data);
    }

    public batchShip(documents: number[], parcelInfo: PreShipmentParcelInfo[], data: Record<string, any>, address: object): Promise<Shipment> {
        return this.api.post("pre-shipments/batch-ship", {documents, parcelInfo, ...data, address}).then(x => x.data);
    }

    public ship(id: number, courierCode: string): Promise<void> {
        return this.api.post("pre-shipments/" + id + "/ship", {courier: courierCode});
    }

    public shipWithReturn(id: number, courierCode: string): Promise<void> {
        return this.api.post("pre-shipments/" + id + "/ship-with-return", {courier: courierCode});
    }

    public cancel(id: number): Promise<void> {
        return this.api.post("pre-shipments/" + id + "/cancel");
    }

    public getPreShipmentsReadyToShipPallets(): Promise<PreShipmentPallet[]> {
        return this.api.get("pre-shipments/pallets").then(x => x.data);
    }

    public repackPallet(palletNumber: string, packageType: "box" | "pallet"): Promise<any> {
        return this.api.post("pre-shipments/pallets/repack", {palletNumber, packageType});
    }
}

export const preShipmentsService = new PreShipmentsService();