import React from "react";
import { action, makeObservable, observable } from "mobx";
import { agentDataService } from "../../../services/agents/agent.data.service";
import { AgentDataAccountResponse } from "../../../models/agents/agent-data/agent-data-accounts/agent.data.account.response";
import { AgentDataAccountFormValues } from "../../../models/agents/agent-data/agent-data-accounts/agent.data.account.form.values";
import { FilterDataHandler } from "../../../models/filter/filter.data.handler";
import { AgentDataCustomPrice } from "../../../models/agents/agent-data/agent-data-custom-price/agent.data.custom.price";
import { createAccessRequest } from "../../../models/agents/agent-data/agent.data.helper";
import { articlesService } from "../../../services/articles/articles.service";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import {
    AgentDataIntegration,
    IntegrationConnectionTestValues,
} from "../../../models/agents/agent-data/agent-data-integrations/agent.data.integration";
import { CustomerAccountAddress } from "../../../models/accounts/customer-account/address/customer.account.address";
import { CustomerAccountContact } from "../../../models/accounts/customer-account/contact/customer.account.contact";
import { CustomerAccountAgreement } from "../../../models/accounts/customer-account/agreement/customer.account.agreement";
import { CustomerAccountAccess } from "../../../models/accounts/customer-account/access/customer.account.access";
import { GeneratedProgressBarData } from "../../../models/progress-bar/progress-bar";
import {PagesStore} from "../../../stores/pages/pages.store";
import {WholesaleAccount} from "../../../models/accounts/customer-account/wholesale-account/wholesale.account";
import {WholesaleAccountMeta} from "../../../models/agents/agent-data/agent.data";
import {accountService} from "../../../services/accounts/account.service";
import {createVisiblePrice} from "../../../models/prices/price.helper";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {AuthStore} from "../../../stores/auth/auth.store";
import {displayPrice} from "../../common/price/price";

export class AgentDataStore {
    @observable
    public account: WholesaleAccount | null = null;

    @observable
    public accountMeta: WholesaleAccountMeta | null = null;

    @observable
    public loading: boolean = false;

    @observable
    public copyingToSandbox: boolean = false;

    @observable
    public progressBarData: GeneratedProgressBarData | null = null;

    public agreementTypes: string[] = [];

    public dataHandler: FilterDataHandler<AgentDataCustomPrice>;
    public request = new PaginatedRequest();

    constructor(private number: string, private pageStore: PagesStore, private authStore: AuthStore, private url: string, private navigate: NavigateFunction) {
        makeObservable(this);
        this.loadData();
        this.createContact = this.createContact.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.removeContact = this.removeContact.bind(this);
        this.dataHandler = new FilterDataHandler((request) =>
            agentDataService.getCustomPrices(
                request,
                this.account?.id!
            )
        );

        this.activate = this.activate.bind(this);

        this.createAddress = this.createAddress.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.removeAddress = this.removeAddress.bind(this);

        this.createAccount = this.createAccount.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
        this.removeAccount = this.removeAccount.bind(this);

        this.createAgreement = this.createAgreement.bind(this);
        this.updateAgreement = this.updateAgreement.bind(this);
        this.removeAgreement = this.removeAgreement.bind(this);

        this.searchArticle = this.searchArticle.bind(this);

        this.removeCustomPrice = this.removeCustomPrice.bind(this);
        this.updateCustomPrice = this.updateCustomPrice.bind(this);

        this.updateIntegration = this.updateIntegration.bind(this);
        this.createIntegration = this.createIntegration.bind(this);

        this.updateAdvancedOrdersCount =
            this.updateAdvancedOrdersCount.bind(this);
        this.decreaseAdvancedOrderAmount =
            this.decreaseAdvancedOrderAmount.bind(this);
    }

    @action
    private setBrogressBarData(value: GeneratedProgressBarData): void {
        this.progressBarData = value;
    }

    @action
    private setAgentData(account: WholesaleAccount): void {
        this.account = account;
    }

    @action
    private setAgentMeta(accountMeta: WholesaleAccountMeta): void {
        this.accountMeta = accountMeta;
    }

    @action
    private setLoading(status: boolean): void {
        this.loading = status;
    }

    @action
    private setCopyingToSandbox(value: boolean): void {
        this.copyingToSandbox = value;
    }

    @action
    private makeNewAddress(newAddress: CustomerAccountAddress): void {
        if (newAddress.type === "shipping") {
            this.account?.shippingAddresses.push(newAddress);
        }
    }

    @action
    private addContact(newContact: CustomerAccountContact): void {
        this.account?.contacts.push(newContact);
    }

    @action
    private addAccount({
        user,
        systemAccesses,
    }: AgentDataAccountResponse): void {
        this.account?.accesses.push({ user, systemAccesses });
    }

    @action
    private addAgreement(agreement: CustomerAccountAgreement): void {
        this.account?.agreements.push(agreement);
    }

    @action
    private editAccount({
        user,
        systemAccesses,
    }: AgentDataAccountResponse): void {
        const accessIndex = this.account!.accesses.findIndex(
            (item) => item.user.id === user.id
        );
        if (accessIndex !== -1) {
            this.account!.accesses[accessIndex] = {
                user,
                systemAccesses,
            };
        }
    }

    @action
    private editItemInArray(item: any, items: any[]): void {
        const index = items.findIndex((val) => val.id === item.id);
        if (index !== -1) {
            items[index] = item;
        }
    }

    @action
    private removeItem(item: any, items: any[]): void {
        const index = items.indexOf(item);
        if (index !== -1) {
            items.splice(index, 1);
        }
    }

    @action
    private addIntegration(value: AgentDataIntegration): void {
        this.account?.integrations.push(value);
    }

    private setAgreementTypes(types: string[]) {
        this.agreementTypes = types;
    }

    public async createContact(contact: CustomerAccountContact) {
        const createdItem = await agentDataService.createContact(
            contact,
            this.account!.id
        );
        this.addContact(createdItem);
    }

    public async updateContact(
        contact: CustomerAccountContact,
        contactId: number
    ) {
        const updatedContact = await agentDataService.updateContact(
            contact,
            contactId
        );
        this.editItemInArray(updatedContact, this.account!.contacts);
    }

    public async removeContact(contact: CustomerAccountContact) {
        await agentDataService.removeContact(contact.id);
        this.removeItem(contact, this.account?.contacts!);
    }

    public async createAgreement(agreement: CustomerAccountAgreement) {
        const newAgreement = await agentDataService.createAgreement(
            agreement,
            this.account!.id
        );
        this.addAgreement(newAgreement);
    }

    public async updateAgreement(
        agreement: CustomerAccountAgreement,
        agreementId: number
    ) {
        const updatedAgreement = await agentDataService.updateAgreement(
            agreement,
            agreementId
        );
        this.editItemInArray(
            updatedAgreement,
            this.account!.agreements
        );
    }

    public async removeAgreement(agreement: CustomerAccountAgreement) {
        await agentDataService.removeAgreement(agreement.id);
        this.removeItem(agreement, this.account!.agreements);
    }

    public async activate(values: any) {
        await accountService.activate(this.account!.id, values);
        await this.loadData()
        this.navigate("/clients/" + this.account?.agent.number + "/settings");
    }

    public async deactivate() {
        await accountService.deactivate(this.account!.id);
        await this.loadData()
    }

    public async reactivate() {
        await accountService.reactivate(this.account!.id);
        await this.loadData()
    }

    public async cancel() {
        await accountService.cancel(this.account!.id);
        await this.loadData()
    }

    public async suspend() {
        await accountService.suspend(this.account!.id);
        await this.loadData()
    }

    public async unsuspend() {
        await accountService.unsuspend(this.account!.id);
        await this.loadData()
    }

    public async createAddress(
        address: CustomerAccountAddress & { addSameShippingAddress?: boolean }
    ) {
        if (address.addSameShippingAddress) {
            delete address.addSameShippingAddress;
            const newShippingAddress = await agentDataService.createAddress(
                {
                    ...address,
                    type: "shipping",
                },
                this.account!.id
            );
            this.makeNewAddress(newShippingAddress);
        }
        delete address.addSameShippingAddress;
        const newAddress = await agentDataService.createAddress(
            address,
            this.account!.id
        );
        this.makeNewAddress(newAddress);
    }

    public async updateAddress(
        address: CustomerAccountAddress,
        addressId: number
    ) {
        const updatedAddress = await agentDataService.updateAddress(
            address,
            addressId
        );
        if (updatedAddress.type === "shipping") {
            this.editItemInArray(
                updatedAddress,
                this.account!.shippingAddresses
            );
        } else {
            this.editItemInArray(
                updatedAddress,
                [this.account!.billingAddress]
            );
        }
    }

    @action
    private resetDefaultAddresses(type: string): void {
        if (type === "shipping") {
            this.account!.shippingAddresses =
                this.account!.shippingAddresses.map((item) => ({
                    ...item,
                    default: false,
                }));
        }
    }

    public async setDefaultAddress(address: CustomerAccountAddress) {
        this.resetDefaultAddresses(address.type);
        const newDefaultAddress = await agentDataService.updateDefaultAddress(
            address,
            address.id
        );
        if (address.type === "shipping") {
            this.editItemInArray(
                newDefaultAddress,
                this.account!.shippingAddresses
            );
        } else {
            this.editItemInArray(
                newDefaultAddress,
                [this.account!.billingAddress]
            );
        }
    }

    public async removeAddress(address: CustomerAccountAddress) {
        await agentDataService.removeAddress(address.id);
        if (address.type === "shipping") {
            this.removeItem(
                address,
                this.account!.shippingAddresses
            );
        }
    }

    public async createAccount(accountData: AgentDataAccountFormValues) {
        const response = await agentDataService.createAccount(
            createAccessRequest(accountData),
            this.account!.id
        );
        this.addAccount(response);
    }

    public async updateAccount(accountData: AgentDataAccountFormValues) {
        const response = await agentDataService.updateAccount(
            createAccessRequest(accountData),
            this.account!.id,
            accountData.id!
        );
        this.editAccount(response);
    }

    public async removeAccount(access: CustomerAccountAccess) {
        await agentDataService.removeAccount(
            this.account!.id,
            access.user.id
        );
        if (this.account) {
            this.removeItem(access, this.account?.accesses);
        }
    }

    public async searchArticle(search: string) {
        const articles = await articlesService.searchArticle(search);
        return articles.map(({ id, name, description }) => ({
            value: id,
            label: name + " - " + description,
        }));
    }

    public async createCustomPrice(values: {
        article: string;
        amount: string;
    }) {
        const newCustomPrice = await agentDataService.createCustomPrice(
            this.account!.id,
            values
        );
        this.dataHandler.addItem(newCustomPrice);
    }

    public async updateCustomPrice(id: number, values: { amount: string }) {
        await agentDataService.updateCustomPrices(id, values);
        await this.dataHandler.reloadItems(this.request);
    }

    public async removeCustomPrice(customPrice: AgentDataCustomPrice) {
        await agentDataService.removeCustomPrice(customPrice.id);
        this.dataHandler.removeItem(customPrice);
    }

    public async createIntegration(values: AgentDataIntegration) {
        const integrationWithSortedFields =
            this.getIntegrationValuesWithSortedFields(values);
        const newIntegration = await agentDataService.createAgentIntegration(
            integrationWithSortedFields,
            this.account!.id
        );
        this.addIntegration(newIntegration);
    }

    public async updateIntegration(values: AgentDataIntegration, id: number) {
        const integrationWithSortedFields = this.getIntegrationValuesWithSortedFields(values);
        const updatedIntegration = await agentDataService.updateAgentIntegration(integrationWithSortedFields, id);
        this.editItemInArray(updatedIntegration, this.account!.integrations);
    }

    private getIntegrationValuesWithSortedFields(values: AgentDataIntegration): AgentDataIntegration {
        return {
            ...values,
            fields: values.fields.map((field, index: number) => ({
                ...field,
                sort: index,
            })),
        };
    }

    public async removeIntegration(integration: AgentDataIntegration) {
        await agentDataService.removeAgentIntegration(integration.id);
        this.removeItem(integration, this.account!.integrations);
    }

    public testIntegrationConnection = async (values: IntegrationConnectionTestValues, id?: number) => {
        return await agentDataService.testIntegrationConnection(values, id);
    }

    public async uploadIntegrationData(integration: AgentDataIntegration) {
        return await agentDataService.uploadIntegrationData(integration.id);
    }

    public async copyToSandbox(
        openNotification: (type: "error" | "success", message: string) => void
    ) {
        this.setCopyingToSandbox(true);
        try {
            await agentDataService.copyToSandBox(this.account!.id);
            openNotification("success", "CLIENT.COPY_TO_SANDBOX.SUCCESS");
            this.setCopyingToSandbox(false);
        } catch (e: any) {
            openNotification("error", "CLIENT.COPY_TO_SANDBOX.ERROR");
            this.setCopyingToSandbox(false);
        }
    }

    public async loadData() {
        this.setLoading(true);
        const { data, meta } = await agentDataService.getWholesaleAccountByAgentNumber(
            this.number
        );
        this.setAgentData(data);
        this.setAgentMeta(meta);
        this.setBrogressBarData(this.generateAgentProgressBarData());

        this.setAgreementTypes(await agentDataService.getAgreementTypes());
        this.pageStore.updateRouteName(this.url, this.account?.agent.name);
        this.setLoading(false);
    }

    private generateAgentProgressBarData(): GeneratedProgressBarData {

        const points = this.accountMeta!.aftersale.thresholds.map((item) => ({
            label:
                displayPrice(this.authStore.lz.pt, {
                    value: item.threshold,
                    currencyCode: this.accountMeta!.paidInvoices.currencyCode,
                }, true) +
                (item.percentage !== 0 ? " (" + item.percentage + "%)" : ""),
            value: item.threshold,
        }));
        const bars = [
            {
                value: this.accountMeta!.paidInvoices.value,
                color: "#1891FF",
                // hint: "hint for bar 1",
            }
        ];

        return { points, bars };
    }

    public updateAdvancedOrdersCount(amount: number): void {
        this.setAgentMeta({ ...this.accountMeta!, notCompletedOrders: amount });
    }

    public decreaseAdvancedOrderAmount(): void {
        if (this.accountMeta) {
            this.setAgentMeta({
                ...this.accountMeta,
                notCompletedOrders: this.accountMeta.notCompletedOrders - 1,
            });
        }
    }
}

export const AgentDataStoreContext = React.createContext<AgentDataStore | null>(
    null
);
