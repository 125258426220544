import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useRequiredStore } from "../../../utils/store";
import {useTranslate} from "../../../hooks/translate.hook";
import { RootStoreContext } from "../../../stores/root/root.store";
import { agentService } from "../../../services/agents/agent.service";
import FilteredTable from "../../common/filtered-table/filtered.table";
import { Link } from "react-router-dom";
import { agentTypesService } from "../../../services/agents/agent.types.service";
import {Button, Row, Col, Tag, Tooltip, Typography, Space, Dropdown, Menu, Form, Select, Checkbox} from "antd";
import { statusesService } from "../../../services/statuses/statuses.service";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    AgentExtended,
    WholesaleAccount
} from "../../../models/accounts/customer-account/wholesale-account/wholesale.account";
import { FilterKey } from "../../../models/filtered-table/filtered.table.props";
import {Export} from "../../common/export/export";
import {PaginatedRequest} from "../../../models/core/paginated.request";
import Date from "../../date/date";
import {convertTableColumnsToColumnConfigs} from "../../../utils/table";
import {AgentNewModal} from "../agent-data/agent-new/agent.new.modal";
import {accountService} from "../../../services/accounts/account.service";
import {filterButtonConfigs} from "./agents.list.filter-buttons";
import {useNavigate} from "react-router";
import {PurchasingAlliance} from "../../../models/settings/purchasing-alliances";
import {purchasingAlliancesService} from "../../../services/settings/purchasing-alliances.service";

const exportContactsMethods = [
    "users",
    "addresses",
    "contacts",
    "full"
];

interface AgentsListProps extends ComponentPropsFromRoute {}

export const AgentsList: React.FC<AgentsListProps> = observer(({ name }) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const rootStore = useRequiredStore(RootStoreContext);
    const langStore = rootStore.langStore;

    const [agentTypes, setAgentTypes] = useState<string[]>([]);
    const [agentStatuses, setAgentStatuses] = useState<string[]>([]);
    const [purchasingAlliances, setPurchasingAlliances] = useState<PurchasingAlliance[]>([]);
    const [purchasingAlliances2, setPurchasingAlliances2] = useState<PurchasingAlliance[]>([]);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const [exportContactsModalShown, setExportContactsModalShown] = useState<boolean>(false);
    const [request, setRequest] = useState<PaginatedRequest | null>(null);

    useEffect(() => {
        agentTypesService.getTypes().then((types) => setAgentTypes(types));
        statusesService.getStatuses().then((statuses) => setAgentStatuses(statuses));
        purchasingAlliancesService.getAll('tier1').then(purchasingAlliances => setPurchasingAlliances(purchasingAlliances));
        purchasingAlliancesService.getAll('tier2').then(purchasingAlliances2 => setPurchasingAlliances2(purchasingAlliances2));
    }, []);

    const tagColor: { [K: string]: string } = {
        active: "green",
        inactive: "red",
        potential: "blue",
        suspended_shipment: "orange",
    };

    const columns: any = [
        {
            title: t("CLIENT.CODE"),
            key: "number",
            render: (_: any, item: WholesaleAccount) => <Link to={"/clients/" + item.agent.number}>{item.agent.number}</Link>,
        },
        {
            title: t("DEPARTMENT_ID"),
            key: "branch",
            render: (_: any, item: WholesaleAccount) => item.branch?.id.toUpperCase()
        },
        {
            title: t("CLIENT.NAME"),
            key: "name",
            render: (_: any, item: WholesaleAccount) => item.agent.name
        },
        {
            title: t("BUSINESS_NAME"),
            key: "businessName",
            render: (_: any, item: WholesaleAccount) => item.agent.businessName
        },
        {
            title: t("CLIENT.STATUS"),
            dataIndex: "status",
            key: "status",
            render: (status: string) => (
                <Tag color={tagColor[status]}>{t("CLIENT." + status.toUpperCase())}</Tag>
            ),
        },
        {
            title: "VAT",
            key: "vat",
            render: (_: any, item: WholesaleAccount) => item.agent.vat || "-",
        },
        {
            title: "EORI",
            key: "customsCode",
            render: (_: any, item: WholesaleAccount) => item.agent.customsCode || "-",
        },
        {
            title: t("REG_NUMBER"),
            key: "regCode",
            render: (_: any, item: WholesaleAccount) => item.agent.regCode || "-",
        },
        {
            title: t("COUNTRY"),
            key: "country",
            render: (_: any, account: WholesaleAccount) => <>{account.agent.country.id}</>,
        },
        {
            title: t("DISCOUNT"),
            dataIndex: "discount",
            key: "discount"
        },
        {
            title: t("PAYMENT_TERMS"),
            dataIndex: "paymentTerms",
            key: "paymentTerms"
        },
        {
            title: t("ORIGINAL_COUNTRY"),
            key: "original_country",
            render: (_: any, account: WholesaleAccount) => <>{account.agent.country.id}</>,
        },
        {
            title: t("CLIENT.GROUP"),
            key: "group",
            render: (_: any, account: WholesaleAccount) => <>{account.agent.group}</>,
        },
        {
            title: t("DATE"),
            key: "agent",
            dataIndex: "agent",
            render: (agent: AgentExtended) => <Date dateString={agent.createdAt} />,
        },
        {
            title: t("CLIENT.PURCHASING_ALLIANCE.TITLE"),
            key: "purchasingAlliance",
            dataIndex: "purchasingAlliance",
            render: (alliance: PurchasingAlliance) => alliance?.name || '(none)',
        }
    ];

    const filters: FilterKey[] = [
        {
            key: "number",
            label: t("CLIENT.CODE"),
            type: "string",
        },
        {
            key: "name",
            label: t("CLIENT.NAME"),
            type: "string",
        },
        {
            key: "businessName",
            label: t("BUSINESS_NAME"),
            type: "string",
        },
        {
            key: "vat",
            label: "VAT.TITLE",
            type: "string",
        },
        {
            key: "customsCode",
            label: "EORI",
            type: "string",
        },
        {
            key: "regCode",
            label: t("REG_NUMBER"),
            type: "string",
        },
        {
            key: "email",
            label: t("EMAIL"),
            type: "string"
        },
        {
            key: "contactFirstName",
            label: t("CONTACTS.FIRST_NAME"),
            type: "string"
        },
        {
            key: "contactLastName",
            label: t("CONTACTS.LAST_NAME"),
            type: "string"
        },
        {
            key: "userFirstName",
            label: t("ACCOUNTS.FIRST_NAME"),
            type: "string"
        },
        {
            key: "userLastName",
            label: t("ACCOUNTS.LAST_NAME"),
            type: "string"
        },
        {
            key: "statuses",
            label: t("CLIENT.STATUS"),
            type: "select",
            options: agentStatuses.map((item) => ({ value: item, label: t("CLIENT." + item.toUpperCase()) })),
        },
        {
            key: "types",
            label: t("TYPE"),
            type: "select",
            options: agentTypes.map((item) => ({ value: item, label: item.toUpperCase() })),
        },
        {
            key: "countryCodes",
            label: t("COUNTRY"),
            type: "select",
            options: langStore.countries.map(({ id, name }) => ({ value: id as string, label: name })),
        },
        {
            key: "discount",
            label: t("DISCOUNT"),
            type: "int",
        },
        {
            key: "paymentTerms",
            label: t("PAYMENT_TERMS"),
            type: "int",
        },
        {
            key: "country",
            label: t("ORIGINAL_COUNTRY"),
            type: "select",
            options: langStore.countries.map(({ id, name }) => ({ value: id as string, label: name })),
        },
        {
            key: "groups",
            label: t("CLIENT.GROUP"),
            type: "select",
            options: [
                { value: "wholesale", label: "Wholesale" },
                { value: "retail", label: "Retail" }
            ],
        },
        {
            key: "purchasingAlliance",
            label: t("CLIENT.PURCHASING_ALLIANCE.TIER1"),
            type: "select",
            options: purchasingAlliances.map(({ id, name }) => ({ value: String(id), label: name })),
        },
        {
            key: "purchasingAlliance2",
            label: t("CLIENT.PURCHASING_ALLIANCE.TIER2"),
            type: "select",
            options: purchasingAlliances2.map(({ id, name }) => ({ value: String(id), label: name })),
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date"
        }
    ];

    const createAgent = async (data: any): Promise<any> => {
        return accountService.create(data).then((data) => {
            navigate("/clients/" + data.number + "/settings");
            return data;
        });
    };

    return (
        <>
            <Space direction="vertical" size={[0, 0]}>
                <Row justify="space-between" gutter={[0, 0]}>
                    <Col>
                        <Row>
                            <Typography.Title level={3}>{t("CLIENTS.LIST_TITLE")}</Typography.Title>
                            <Tooltip key={2} placement="topLeft" title={t("CLIENT.ADD")}>
                                <Button
                                    className="btn"
                                    style={{ margin: "3px 0 0 5px" }}
                                    onClick={() => setModalShown(true)}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
                                />
                            </Tooltip>
                        </Row>
                    </Col>
                    <Col>
                        <Dropdown
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key={1}
                                        onClick={() => setExportContactsModalShown(true)}
                                    >
                                        {t("CLIENTS.EXPORT_CONTACTS.TITLE")}
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button
                                className={"table-page-button"}
                                type={"primary"}
                            >
                                {t("ACTION")}
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            </Space>

            <FilteredTable
                filterKeys={filters}
                filterButtons={filterButtonConfigs}
                loadDataCallback={(request) => agentService.getAll(request)}
                columns={columns}
                columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name }}
                getRequest={request => setRequest(request)}
            />

            <Export
                title={"CLIENTS.EXPORT_CONTACTS.TITLE"}
                withBasicExportFields
                isShown={exportContactsModalShown}
                config={{
                    link: "/api/v3/accounts/wholesale",
                    query: request?.toParams()!,
                }}
                additionalDefaultValues={{"method": exportContactsMethods[0]}}
                onClose={() => setExportContactsModalShown(false)}
            >
                <Col span={24}>
                    <Form.Item label={t("CLIENTS.EXPORT_CONTACTS.METHODS.TITLE")} name={"method"}>
                        <Select>
                            {exportContactsMethods.map(option => (
                                <Select.Option
                                    key={option}
                                    value={option}
                                >
                                    {t("CLIENTS.EXPORT_CONTACTS.METHODS." + option.toUpperCase())}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={"useFilters"}
                        valuePropName={"checked"}
                    >
                        <Checkbox>
                            {t("ARTICLES.WAREHOUSES.DOWNLOAD.USE_FILTERS")}
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Export>

            <AgentNewModal
                onComplete={(v) => createAgent(v)}
                visible={modalShown}
                onClose={() => setModalShown(false)}
            />
        </>
    );
});
