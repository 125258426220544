import {Translate} from "../../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {PriceList} from "../../../../../../models/price-lists/price.lists";
import {Amount} from "../../../../../../models/prices/price";
import {displayPrice} from "../../../../../../models/prices/price.helper";
import {Price} from "../../../../../../models/articles/articles.prices";

export const columnsFactory = (t: Translate): ColumnsType<Price> => {
    return [
        {
            title: t("NAME"),
            dataIndex: "price",
            key: "price",
            render: (price: PriceList) => <>{price.name}</>
        },
        {
            title: t("PRICE"),
            dataIndex: "amount",
            key: "amount",
            render: (item: Amount) => <>{displayPrice(item)}</>
        }
    ];
}