import {Translate} from "../../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {Stocks} from "../../../../../../models/articles/articles.stock";
import {Warehouse} from "../../../../../../models/warehouses/warehouse";

export const columnsFactory = (t: Translate): ColumnsType<Stocks> => {
    return [
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "warehouse",
            className: "bold",
            render: (warehouse: Warehouse) => warehouse.name
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "amount",
            key: "amount"
        }
    ];
}