import {
    TableThresholds,
    ThresholdsCreateValues,
    ThresholdsCreateValuesThreshold,
} from "../../../../../models/thresholds/threshold";
import { action, makeObservable, observable } from "mobx";
import React from "react";
import { PaginatedRequest } from "../../../../../models/core/paginated.request";
import { PaginatedResult } from "../../../../../models/core/paginated.result";
import { FilterDataHandler } from "../../../../../models/filter/filter.data.handler";
import { Threshold } from "../../../../../models/thresholds/threshold";
import { thresholdsService } from "../../../../../services/thresholds/thresholds.service";
import { CurrencyCode } from "../../../../../models/prices/price";

export class AgentDataThresholdsStore {
    public request: PaginatedRequest | null = null;
    dataHandler: FilterDataHandler<Threshold>;

    @observable
    public modalShown: boolean = false;

    @observable
    public modalItem: TableThresholds | null = null;

    constructor(private accountId: number, private currencyCode: CurrencyCode) {
        this.addThreshold = this.addThreshold.bind(this);
        this.editThreshold = this.editThreshold.bind(this);

        this.openModal = this.openModal.bind(this);
        this.generateThresholdsData = this.generateThresholdsData.bind(this);
        this.convertToTableData = this.convertToTableData.bind(this);

        makeObservable(this);
        this.dataHandler = new FilterDataHandler(
            (request) =>
                thresholdsService.getThresholds(request, this.accountId),
            this.generateThresholdsData
        );
    }

    @action
    private setModalItem(value: TableThresholds | null) {
        this.modalItem = value;
    }

    @action
    private setModalShown(value: boolean): void {
        this.modalShown = value;
    }

    public closeModal(): void {
        this.setModalShown(false);
        this.setModalItem(null);
    }
    public openModal(): void {
        this.setModalShown(true);
    }

    public openEditModal(item: TableThresholds): void {
        this.setModalItem(item);
        this.setModalShown(true);
    }

    public async addThreshold(values: ThresholdsCreateValues) {
        await thresholdsService.createThreshold(
            this.accountId,
            {
                thresholds: values.thresholds.map((item) => ({
                    threshold: parseInt(item.threshold as string, 10),
                    percentage: parseInt(item.percentage as string, 10),
                })),
                year: parseInt(values.year.format("YYYY"), 10),
            }
        );
        this.dataHandler.reloadWithLastRequest();
    }

    public async editThreshold(values: {
        thresholds: ThresholdsCreateValuesThreshold[];
    }) {
        await thresholdsService.updateThreshold(
            this.modalItem!.id,
            {
                thresholds: values.thresholds.map((item) => ({
                    threshold: parseFloat(item.threshold as string),
                    percentage: parseFloat(item.percentage as string),
                })),
            }
        );

        this.dataHandler.reloadWithLastRequest();
        this.setModalItem(null);
    }

    public async deleteThreshold(item: TableThresholds) {
        this.dataHandler.setLoading(true);
        await thresholdsService.removeThreshold(item.id);
        this.dataHandler.removeItem(item as any);
        this.dataHandler.setLoading(false);
    }

    private convertToTableData(item: Threshold): TableThresholds {
        return {
            id: item.id,
            year: item.year,
            threshold: {
                value: item.thresholds[0]?.threshold,
                currencyCode: this.currencyCode,
            },
            percentage: item.thresholds[0]?.percentage,
            children: item.thresholds.slice(1).map((childThreshold) => ({
                id: childThreshold.id,
                threshold: {
                    value: childThreshold.threshold,
                    currencyCode: this.currencyCode,
                },
                percentage: childThreshold.percentage,
                year: "",
                children: [],
            })),
        };
    }

    private generateThresholdsData(data: PaginatedResult<Threshold>): any {
        const items: TableThresholds[] = data.items.map((item) => {
            const firstThreshold = item.thresholds[0];
            const otherThresholds = item.thresholds.slice(1);
            return {
                id: item.id,
                year: item.year,
                threshold: {
                    value: firstThreshold.threshold,
                    currencyCode: this.currencyCode,
                },
                percentage: firstThreshold.percentage,
                children: otherThresholds.map(
                    ({ threshold, percentage, id }, index) => ({
                        id,
                        year: "",
                        threshold: {
                            value: threshold,
                            currencyCode: this.currencyCode,
                        },
                        percentage,
                        children: [],
                    })
                ),
            };
        });

        return {
            meta: data.meta,
            items,
        };
    }
}

export const AgentDataThresholdsStoreContex =
    React.createContext<AgentDataThresholdsStore | null>(null);
