import {createContext, useContext} from "react";
import {ArticleData as Article} from "../../../models/articles/article.data";

interface ArticleContextData {
    article: Article;
}

export const ArticleContext = createContext<ArticleContextData | undefined>(undefined);

export const useArticle = (): ArticleContextData => {
    const context = useContext(ArticleContext);
    if (!context) {
        throw new Error("useArticle must be used within an ArticleProvider");
    }

    return context;
};