import React, {useEffect, useState} from "react";
import {Button, Space, Table} from "antd";
import {Link} from "react-router-dom";
import {ShipContext} from "../ship-modal";
import {PreShipment} from "../../../../../../../models/parcels/pre-shipments";
import {preShipmentsService} from "../../../../../../../services/shipments/pre-shipments.service";
import Date from "../../../../../../date/date";

export const SelectInvoicesStep: React.FC = () => {

    const c = React.useContext(ShipContext);

    const [documents, setDocuments] = useState<PreShipment[]>([]);

    useEffect(() => {
        preShipmentsService.getPreShipmentsReadyToShipByAgentCode(c.agentCode).then(documents => setDocuments(documents));
    }, []);

    return <Space direction="vertical" size={[15, 15]}>
        <Table
            columns={[
                {
                    title: 'Document',
                    dataIndex: 'number',
                    width: 180
                },
                {
                    title: 'Date',
                    dataIndex: 'createdAt',
                    render: (date) => <Date dateString={date} showTime={true} />,
                    width: 200
                },
                {
                    title: 'Orders',
                    dataIndex: 'orderIds',
                    render: (ids: number[]) => ids
                        .map<React.ReactNode>(id => (<Link target={"_blank"} to={'/orders/' + id}>#{id}</Link>))
                        .reduce((prev, curr) => [prev, ', ', curr])
                }
            ]}
            dataSource={documents}
            rowSelection={{onChange: (keys, rows) => c.setSelectedDocuments(rows.map(row => row.id))}}
            pagination={false}
            rowKey={"number"}
        />
        <Space direction="horizontal" size={[15, 15]}>
            <Button type={"primary"} disabled={c.selectedDocuments.length === 0} onClick={() => c.setStep(1)}>Next</Button>
        </Space>
    </Space>
}