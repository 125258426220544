import {Warehouse} from "../warehouses/warehouse";
import {Amount} from "../prices/price";

export interface Container {
    id: number;
    name: string;
    status: ContainerStatus,
    estimatedArrivalDate: string,
    arrivalDate: string
    loadDate: string
    warehouse: Warehouse
    items: ContainerItem[]
}

export type ContainerStatus = "in_transit" | "arrived" | "handled"

export interface ContainerItem {

}

export interface ContainerSummary {
    total: Amount;
}

export type ContainerStatusColor = Record<ContainerStatus, string>;

export const containerStatusColor: ContainerStatusColor =
    {
        in_transit: "gold",
        arrived: "green",
        handled: "green"
    }