import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Collapse, Space, Table, Typography} from "antd";
import "./vehicles.scss";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ArticleVehicle} from "../../../../../models/articles/article.data";
import {columnsFactory} from "./vehicles.columns";
import {articlesService} from "../../../../../services/articles/articles.service";
import {useArticle} from "../../article.context";
import {LoadingSpin} from "../../../../common/loading-spin/loading.spin";

export const ArticleDataVehicles: React.FC = observer(() => {
    const t = useTranslate();
    const columns = columnsFactory(t);
    const [vehicles, setVehicles] = useState<ArticleVehicle[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const {article} = useArticle();

    useEffect(() => {
        articlesService.getArticleVehicles(article.name).then(v => {
            setVehicles(v)
            setLoading(false)
        })
    }, [article.name]);

    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <Space size={[0, 0]} direction={"vertical"} className={"article"}>
            <Typography.Title level={3}>{t("ARTICLES.USED_IN")}</Typography.Title>
            <div>
                {vehicles.map((vehicle, index) => (
                    <Collapse className="article_collapse-inner" key={vehicle.name} expandIconPosition="right" bordered={false}>
                        <Collapse.Panel key={index} header={<span style={{fontWeight: "bold"}}>{vehicle.name}</span>}>
                            {vehicle.models.map((model, i) => (
                                <Collapse className="article_collapse-inner article_collapse-model" key={model.name} expandIconPosition="right" bordered={false}>
                                    <Collapse.Panel key={i} header={model.name}>
                                        <Table
                                            rowKey={"number"}
                                            columns={columns}
                                            dataSource={model.variants}
                                            scroll={{x: 700}}
                                            pagination={false}
                                        />
                                    </Collapse.Panel>
                                </Collapse>
                            ))}
                        </Collapse.Panel>
                    </Collapse>
                ))}
            </div>
        </Space>
    );
})
