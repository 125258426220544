import React, {useEffect, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import { observer } from "mobx-react";
import {Button, Space} from "antd";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { RouteTabs } from "../../common/route-tabs/route.tabs";
import { AgentDataHeader } from "./agent-data-header/agent.data.header";
import { AgentDataStore, AgentDataStoreContext } from "./agent.data.store";
import { LoadingSpin } from "../../common/loading-spin/loading.spin";
import "./agent.data.scss";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {AgentDataContacts} from "./agent-data-addresses/agent-data-contacts/agent.data.contacts";
import {AgentNewModal} from "./agent-new/agent.new.modal";
import {AgentDataAddressWrapper} from "./agent-data-addresses/agent.data.address.wrapper";
import {useTranslate} from "../../../hooks/translate.hook";
import {useNavigate} from "react-router";

export interface MatchParams {
    data: string;
}

interface AgentDataProps extends ComponentPropsFromRoute {}

export const AgentData: React.FC<AgentDataProps> = observer(({ name, routeChildren, ...rest }) => {
    const t = useTranslate();
    const { number } = useParams<{ number: string }>();
    const {pageStore, authStore} = useRequiredStore(RootStoreContext);
    const navigate  = useNavigate();
    const location = useLocation();
    const [store] = useState(() => new AgentDataStore(number!, pageStore, authStore, location.pathname, navigate));

    const [isModalShown, setIsModalShown] = useState<boolean>(false);

    useEffect(() => {
        if (location.pathname === "/clients/" + number) {
            navigate("/clients/" + number + "/documents", {replace: true})
        }
    }, [location.pathname])

    if (store.loading) {
        return <LoadingSpin />;
    }

    return (
        <AgentDataStoreContext.Provider value={store}>
            <Space size={[10, 10]} direction={"vertical"}>
                <AgentDataHeader />
                {store.account?.status === "potential" || store.account?.status === "cancelled" ? (
                    <Space direction={"vertical"} size={[40, 40]}>
                        <AgentDataContacts changeable={false} withTitle={true} />
                        <AgentDataAddressWrapper changeable={false} addresses={[store.account?.billingAddress!]} type="billing" title="CLIENT.PAYER_DATA" />
                        <Space direction={"horizontal"}>
                            <Button type={"primary"} onClick={() => setIsModalShown(true)}>Accept</Button>
                            {store.account.status === "potential" ? (
                                <Button type={"primary"} onClick={() => store.cancel()} danger>Reject</Button>
                            ) : null}
                        </Space>
                        {isModalShown ? (
                            <AgentNewModal
                                data={store.account}
                                onComplete={(v) => store.activate(v)}
                                visible={true}
                                onClose={() => setIsModalShown(false)}
                            />) : null}
                    </Space>
                ) : (
                    <Space direction={"vertical"} size={[20, 20]}>
                        <Space direction={"horizontal"}>
                            <Link to={"/clients/" + number + "/cart"}>
                                <Button type={"default"}>
                                    {t("ORDERS.CREATE.TITLE")}
                                </Button>
                            </Link>
                            <Link to={"/clients/" + number + "/documents/new-sale-document"}>
                                <Button type={"default"}>
                                    {t("ORDERS.CREATE_SALE_DOCUMENT")}
                                </Button>
                            </Link>
                        </Space>
                        <RouteTabs
                            routes={routeChildren!}
                        />
                    </Space>
                )}
            </Space>
        </AgentDataStoreContext.Provider>
    );
});
