import { AxiosInstance } from "axios";
import API from "../../utils/api";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {Container, ContainerItem, ContainerSummary} from "../../models/containers/containers";
import {Order} from "../../models/orders/orders-list/order";

export class ContainersService {
    private api: AxiosInstance = API;

    get(id: number): Promise<Container> {
        return this.api
            .get<Container>("containers/" + id)
            .then((x) => x.data);
    }

    getItems(id: number, request: PaginatedRequest): Promise<PaginatedResult<ContainerItem>> {
        return this.api
            .get<PaginatedResult<ContainerItem>>("containers/" + id + "/items", {params: request.toParams()})
            .then((x) => x.data);
    }

    getOrders(id: number, request: PaginatedRequest): Promise<PaginatedResult<Order>> {
        return this.api
            .get<PaginatedResult<Order>>("containers/" + id + "/orders", {params: request.toParams()})
            .then((x) => x.data);
    }

    getAll(request: PaginatedRequest): Promise<PaginatedResult<Container>> {
        return this.api
            .get<PaginatedResult<Container>>("containers", {params: request.toParams()})
            .then((x) => x.data);
    }

    setArrived(id: number, date: any): Promise<any> {
        return this.api
            .post("containers/" + id + "/arrival", {date: date});
    }

    changeEstimatedArrivalDate(id: number, date: any): Promise<any> {
        return this.api
            .post("containers/" + id + "/estimated-arrival-date", {date: date});
    }

    setHandled(id: number): Promise<any> {
        return this.api
            .post("containers/" + id + "/handled");
    }

    import(warehouse: string, file: File): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('warehouse', warehouse);

        return this.api
            .post("containers/import", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    getSummary(): Promise<ContainerSummary> {
        return this.api
            .get<ContainerSummary>("containers/summary")
            .then((x) => x.data);
    }
}

export const containersService = new ContainersService();