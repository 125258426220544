import React, {useMemo} from "react";
import {format} from "../../../models/prices/price.helper";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";

interface Props {
    value: number;
}

export const PriceValue: React.FC<Props> = ({value}) => {

    const {authStore: {lz}} = useRequiredStore(RootStoreContext)

    const displayedValue = useMemo(() => format(value, lz.pt),
        [value, lz.pt]);

    return (
        <span style={{whiteSpace: "nowrap", textAlign: "right" }}>
            {displayedValue}
        </span>
    );
}