import React, {useEffect, useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {Button, Space, Table, Typography} from "antd";
import {Link} from "react-router-dom";
import {columnsFactory} from "./pallet-management.columns";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {preShipmentsService} from "../../../../../services/shipments/pre-shipments.service";
import {PreShipmentPallet} from "../../../../../models/parcels/pre-shipments";
import {notifications} from "../../../../../services/notifications/notifications";

export const ShipmentsWholesalePalletManagement: React.FC<ComponentPropsFromRoute> = ({ name, routeChildren }) => {

    const t = useTranslate();
    const [data, setData] = useState<PreShipmentPallet[]>([]);

    const reload = async () => {
        setData(await preShipmentsService.getPreShipmentsReadyToShipPallets());
    }

    const repack = useMemo(() => async (palletNumber: string, packageType: "pallet" | "box") => {
        await preShipmentsService.repackPallet(palletNumber, packageType);
        await reload();
        notifications.successfully();
    }, []);

    const columns = useMemo(() => columnsFactory(t, repack), [t, repack]);

    useEffect(() => {
        reload()
    }, [])

    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                rowKey={"palletNumber"}
                pagination={false}
                style={{width:'100%'}}
            />
        </>
    )
}