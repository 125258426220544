import React from "react";
import {observer} from "mobx-react";
import {Space, Table, Typography} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {useArticle} from "../../../article.context";
import {columnsFactory} from "./article.data.prices.colums";

export const ArticleDataPrices: React.FC = observer(() => {
    const t = useTranslate();
    const {article} = useArticle();
    const columns = columnsFactory(t);

    return (
        <Space direction={"vertical"} size={0}>
            <Typography.Title level={3}>{t("ARTICLES.PRICES.TITLE")}</Typography.Title>
            <Table
                columns={columns}
                rowKey={"id"}
                dataSource={[...article.prices]}
                scroll={{x: true}}
                pagination={false}
            />
        </Space>
    );
})
