import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {useParams, useLocation} from "react-router-dom";
import {UserDataStore} from "./user.data.store";
import {Space, Spin} from "antd";
import {UserDataHeader} from "./user-data-header/user.data.header";
import {UserDataBody} from "./user-data-body/user.data.body";
import {UserDataStoreContext} from "./user.data.store";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";

export const UserData = observer(() => {
    const {userId} = useParams<{ userId: string }>();
    const {pageStore} = useRequiredStore(RootStoreContext);
    const match = useLocation();
    const [store] = useState(() => new UserDataStore(userId!, pageStore, match.pathname));

    if (store.loading || !store.userData) {
        return (
            <Space direction={"horizontal"} align={"center"} style={{height: "calc(100vh - 90px)", justifyContent: "center"}}>
                <Spin/>
            </Space>
        );
    }

    return (
        <UserDataStoreContext.Provider value={store}>
            <Space size={[30, 30]} direction={"vertical"}>
                <UserDataHeader/>
                <UserDataBody/>
            </Space>
        </UserDataStoreContext.Provider>
    );
});
