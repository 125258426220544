import React, {useEffect, useState} from "react";
import {Space, Spin} from "antd";
import { ArticleDataHeader } from "./article-data-header/article.data.header";
import {RouteTabs} from "../../common/route-tabs/route.tabs";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {articlesService} from "../../../services/articles/articles.service";
import {ArticleData as Article} from "../../../models/articles/article.data";
import {useParams} from "react-router-dom";
import {ArticleContext} from "./article.context";

export const ArticleData: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

    const {articleId} = useParams();
    const [article, setArticle] = useState<Article | null>(null);

    useEffect(() => {
        if (articleId) {
            articlesService.getArticle(articleId).then(a => setArticle(a));
        }
    }, [articleId]);

    if (!article) {
        return (
            <Space
                direction={"horizontal"}
                align={"center"}
                style={{
                    width: "100%",
                    height: "calc(100vh - 90px)",
                    justifyContent: "center",
                }}
            >
                <Spin />
            </Space>
        );
    }

    return (
        <ArticleContext.Provider value={{ article }}>
            <Space direction={"vertical"} size={[50, 50]}>
                <ArticleDataHeader />
                <RouteTabs routes={routeChildren!} />
            </Space>
        </ArticleContext.Provider>
    );
};
