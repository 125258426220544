import React, {useState} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {Button, Checkbox, Col, DatePicker, Form, InputNumber, Row, Select, Typography} from "antd";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {accountService} from "../../../../../services/accounts/account.service";
import {notifications} from "../../../../../services/notifications/notifications";
import dayjs from "dayjs";
import {currencies} from "../../../../../models/prices/price.helper";
import {Explanation} from "../../../../common/explanation/explanation";

export const paymentTermsDays = [
    0, 7, 10, 12, 14, 15, 21, 30, 40, 45, 60, 90, 120, 150, 180, 240, 270, 365
];

export const AgentDataPrice: React.FC<ComponentPropsFromRoute> = observer(({name}) => {
    const {account} = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const { commonStore } = useRequiredStore(RootStoreContext);

    if (!account) {
        return <></>;
    }

    const submit = async (values: any) => {
        setLoading(true)
        try {
            await accountService.updatePrice(account.id, values)
            notifications.successfully()
        } catch (e: any) {
            if (e.response?.data.fields) {
                form.setFields(e.response?.data?.fields);
            }
        } finally {
            setLoading(false);
        }
    }

    const defaultValues = {
        price: account.price?.id,
        discount: account.discount,
        paymentTerms: account.paymentTerms,
        futureDiscount: account.futureDiscount,
        futureDiscountAppliedAt: account.futureDiscountAppliedAt ? dayjs(account.futureDiscountAppliedAt) : undefined,
        applyMinimalPrice: account.applyMinimalPrice,
        availableCurrencyCodes: account.availableCurrencyCodes
    }

    return <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"client_form"}
            initialValues={defaultValues}
            onFinish={async (values) => {
                values.bank = values.bank?.id
                await form.validateFields();
                await submit(values)
            }}
        >
        <Typography.Title level={3}>{t("CLIENT.PRICE.TITLE")}</Typography.Title>
        <Row gutter={[30, 0]} align={"top"}>
                <Col span={24}>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={6}>
                            <Form.Item
                                name={["price"]}
                                label={t("PRICE_LIST")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder={t("PRICE_LIST")}
                                    showSearch
                                    disabled={loading}
                                    optionFilterProp={"children"}
                                >
                                    {commonStore.priceLists.map((item) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="availableCurrencyCodes"
                                label={
                                    <Explanation
                                        title={t("AVAILABLE_CURRENCY_CODES")}
                                        tooltip={t("AVAILABLE_CURRENCY_CODES_EXPL")}
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp={"children"}
                                    placeholder={t("CURRENCY")}
                                    disabled={loading}
                                    mode={"multiple"}
                                >
                                    {Object.keys(currencies).map((currency) => (
                                        <Select.Option value={currency.toLowerCase()} key={currency}>
                                            {currency.toUpperCase()}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={6}>
                            <Form.Item
                                name={["discount"]}
                                label={
                                    <Explanation
                                        title={t("DISCOUNT")}
                                        tooltip={t("DISCOUNT_EXPL")}
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <InputNumber disabled={loading} placeholder={t("EXAMPLE") + ": -10"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={["paymentTerms"]}
                                label={t("PAYMENT_TERMS")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    disabled={loading}
                                >
                                    {paymentTermsDays.map(value =>
                                        <Select.Option value={value}>{value} {t("DAYS")}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={6}>
                            <Form.Item
                                name={["futureDiscount"]}
                                label={
                                    <Explanation
                                        title={t("COMMON.FUTURE.DISCOUNT")}
                                        tooltip={t("COMMON.FUTURE.DISCOUNT_EXPL")}
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <InputNumber disabled={loading} placeholder={t("EXAMPLE") + ": -10"} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={["futureDiscountAppliedAt"]}
                                label={t("COMMON.FUTURE.DISCOUNT_APPLIED_AT")}
                            >
                                <DatePicker
                                    allowClear
                                    showTime={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={24}>
                            <Form.Item name={["applyMinimalPrice"]} valuePropName="checked">
                                <Checkbox>
                                    <Explanation
                                        title={t("CLIENT.APPLY_MINIMAL_PRICE")}
                                        tooltip={t("CLIENT.APPLY_MINIMAL_PRICE_EXPL")}
                                    />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={24}>
                            <Button type={"primary"} onClick={() => form.submit()}>{t("SAVE")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
});
