import React, {useEffect, useState} from "react";
import {Button, Space, Table} from "antd";
import { Link } from "react-router-dom";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {PreShipmentGroup} from "../../../../../models/parcels/pre-shipments";
import {preShipmentsService} from "../../../../../services/shipments/pre-shipments.service";
import {ShipModal} from "../modal/ship/ship-modal";

export const ShipmentsWholesaleReadyToShip: React.FC<ComponentPropsFromRoute> = ({ name, routeChildren }) => {

    const [agentCode, setAgentCode] = useState("");

    const [groups, setGroups] = useState<PreShipmentGroup[]>([]);

    const reload = () => {
        preShipmentsService.getPreShipmentsReadyToShipGrouped().then(preShipments => {
            setGroups(preShipments);
        });
    }

    useEffect(() => {
        reload();
    }, []);

    return (
        <Space direction="vertical" size={[15, 15]}>
            {groups.length ? (
                <Space direction={"vertical"}>
                    <div style={{width: '100%'}}>
                        <Table
                            columns={[
                                {
                                    title: 'Code',
                                    dataIndex: 'agentCode',
                                    render: (code: string) => <Link to={"/clients/" + code}>{code}</Link>
                                },
                                {
                                    title: 'Agent',
                                    dataIndex: 'agentBusinessName'
                                },
                                {
                                    title: 'Documents',
                                    dataIndex: 'docNumbers',
                                    render: (ids: number[]) => ids
                                        .map<React.ReactNode>(id => (<>#{id}</>))
                                        .reduce((prev, curr) => [prev, ', ', curr])
                                },
                                {
                                    title: 'Total',
                                    dataIndex: 'total'
                                },
                                {
                                    title: 'Actions',
                                    render: (_, item: PreShipmentGroup) =><Button
                                        type={"primary"}
                                        onClick={() => setAgentCode(item.agentCode)}
                                    >Ship</Button>
                                }
                            ]}
                            dataSource={groups}
                            rowKey={"agentCode"}
                            pagination={false}
                        />
                    </div>
                </Space>
            ) : null}
            {agentCode !== "" ? (
                <ShipModal
                    agentCode={agentCode}
                    onCancel={() => {
                        setAgentCode("");
                        reload();
                    }}
                    visible={true}
                />
            ) : null}
        </Space>
    )
}