import React, {useEffect} from "react";
import {Select} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {observer} from "mobx-react";

export interface WidgetProps
{
    disabled: boolean;
    value?: any;
    onChange?: (value: any) => void;
}
export const UIInputWidgetAccountBank: React.FC<WidgetProps> = observer(({disabled, value, onChange}) => {

    const t = useTranslate();

    const { commonStore } = useRequiredStore(RootStoreContext);

    useEffect(() => {
        if (!value && onChange && commonStore.banks.length === 1) {
            onChange(commonStore.banks[0].id);
        }
    }, [value, onChange, commonStore.banks]);

    return (
        <Select
            showSearch
            optionFilterProp={"children"}
            disabled={disabled}
            placeholder={t("BANK.NAME")}
            value={value}
            onChange={onChange}
        >
            {commonStore.banks.map((bank) => (
                <Select.Option key={bank.id} value={bank.id}>
                    {bank.visibleName}
                </Select.Option>
            ))}
        </Select>
    )
});