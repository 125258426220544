import { AxiosInstance } from "axios";
import API, {BlobAndFilename, getBlobAndFileName} from "../../../utils/api";
import { PaginatedRequest } from "../../../models/core/paginated.request";
import { PaginatedResult } from "../../../models/core/paginated.result";
import {ArticleRestriction} from "../../../models/articles/article.restrictions";

export class ArticleRestrictionsService {
    api: AxiosInstance = API;

    getAll(
        id: number,
        request: PaginatedRequest
    ): Promise<PaginatedResult<ArticleRestriction>> {
        return this.api
            .get<PaginatedResult<ArticleRestriction>>("articles/" + id + "/restrictions", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    downloadAsCSV(id: number): Promise<BlobAndFilename> {
        return this.api.get("articles/" + id + "/restrictions/csv", {responseType: "blob"}).then(getBlobAndFileName);
    }

    uploadAsCSV(id: number, dto: {file: File}): Promise<any> {

        const formData = new FormData();
        formData.append('file', dto.file);

        return this.api
            .post("articles/" + id + "/restrictions/csv", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    }

    clearAll(id: number): Promise<any> {
        return this.api.delete("articles/" + id + "/restrictions", {responseType: "blob"});
    }
}

export const articleRestrictionsService = new ArticleRestrictionsService();
