import React, {Key, useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {Button, Dropdown, Menu, Space, Tooltip, Typography} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {useRequiredStore} from "../../../../../utils/store";
import {RootStoreContext} from "../../../../../stores/root/root.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Courier, Shipment, TableShipment} from "../../../../../models/parcels/shipment";
import {shipmentsService} from "../../../../../services/shipments/shipments.service";
import {FilterButtonConfig, FilterKey} from "../../../../../models/filtered-table/filtered.table.props";
import {Warehouse} from "../../../../../models/warehouses/warehouse";
import Date from "../../../../date/date";
import {FilterDataHandler} from "../../../../../models/filter/filter.data.handler";
import {ShipmentsDropshipStore} from "../../dropship/shipments-dropship.store";
import {notifications} from "../../../../../services/notifications/notifications";
import {downloadBlobFile} from "../../../../../utils/blob.download";
import {ShipmentsDispatchModal} from "../../../modal/shipments-dispatch/shipments-dispatch-modal";
import FilteredTable from "../../../../common/filtered-table/filtered.table";
import {FieldGroupSnapshot} from "../../../../../models/filter/filter.field.snapshot";
import {Agent} from "../../../../../models/accounts/customer-account/wholesale-account/wholesale.account";
import {ShipmentStatus} from "../../../common/status/shipment.status";

export enum ShipmentsFilterButtonsTypes {
    SYNC = "sync",
    DISPATCHED_SHIPMENTS = "processed_shipments",
    NOT_DISPATCHED_SHIPMENTS = "not_processed_shipments",
    NO_LABELS_SHIPMENTS = "no_labels_shipments",
}

export type ActiveShipmentFilterButton =
    | typeof ShipmentsFilterButtonsTypes.DISPATCHED_SHIPMENTS
    | typeof ShipmentsFilterButtonsTypes.NOT_DISPATCHED_SHIPMENTS
    | typeof ShipmentsFilterButtonsTypes.NO_LABELS_SHIPMENTS
    | typeof ShipmentsFilterButtonsTypes.SYNC
    | undefined;

const notDispatchedShipmentsStatuses: string[] = ["awaiting"];
const shipmentsWithoutLabelsStatuses: string[] = ["label_not_created"];
const dispatchedShipmentsStatuses: string[] = ["packed", "shipped", "delivered"];
const syncStatuses: string[] = ["sync"];

const defaultStatuses: string[] = ["label_not_created", "awaiting"];
const statuses: string[] = ["label_not_created", "awaiting", "packed", "shipped", "delivered", "sync"];

export const ShipmentsWholesaleList: React.FC<ComponentPropsFromRoute> = observer(({}) => {
    const {
        commonStore: { warehouses },
        authStore: { token },
    } = useRequiredStore(RootStoreContext);

    const t = useTranslate();

    const [couriers, setCouriers] = useState<Courier[]>([]);

    const [shipmentsToDispatch, setShipmentsToDispatch] = useState<string[]>([]);

    const [dispatchModalShown, setDispatchModalShown] = useState<boolean>(false);

    useEffect(() => {
        shipmentsService.getCouriers().then(couriers => setCouriers(couriers));
    }, [])

    const filterButtonConfigs: FilterButtonConfig<ActiveShipmentFilterButton>[] = [
        {
            type: ShipmentsFilterButtonsTypes.NOT_DISPATCHED_SHIPMENTS,
            text: "Not dispatched",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: notDispatchedShipmentsStatuses.join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: ShipmentsFilterButtonsTypes.SYNC,
            text: "Sync",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: syncStatuses.join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: ShipmentsFilterButtonsTypes.DISPATCHED_SHIPMENTS,
            text: "Dispatched",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: dispatchedShipmentsStatuses.join(","),
                        },
                    ],
                },
            ],
        },
        {
            type: ShipmentsFilterButtonsTypes.NO_LABELS_SHIPMENTS,
            text: "SHIPMENTS.STATUS.LABEL_NOT_CREATED",
            filters: [
                {
                    name: "statuses",
                    snapshots: [
                        {
                            rule: undefined,
                            name: "statuses",
                            value: shipmentsWithoutLabelsStatuses.join(","),
                        },
                    ],
                },
            ],
        }
    ];

    const columns: ColumnsType<TableShipment> = [
        {
            title: t("NUMBER"),
            key: "shipmentNumber",
            width: 200,
            render: (_, item) =>
                <Link to={"/shipment/" + item.id}>
                    {item.shipmentNumber}
                    {item.reference !== item.shipmentNumber ? (<> ({item.reference})</>) : null}
                </Link>
        },
        {
            title: t("CLIENT.NAME"),
            dataIndex: "agent",
            key: "agent",
            width: "230px",
            render: (agent: Agent) => <Link to={"/clients/" + agent.number}>{agent.name}</Link>
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (status: string) => (status ? <ShipmentStatus status={status} /> : null),
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "warehouse",
            width: 160,
            render: (warehouse: Warehouse) => (warehouse ? warehouse.name : ""),
        },
        {
            title: t("COURIER.TITLE"),
            dataIndex: "carrier",
            key: "carrier",
            width: 110
        },
        {
            title: t("SHIPMENTS.PARCELS.TRACK_CODE"),
            dataIndex: "trackCode",
            key: "trackCode",
            width: 250,
        },
        {
            title: t("SHIPPED_AT"),
            dataIndex: "packedAt",
            key: "packedAt",
            render: (dateString: string | null) => {
                return dateString ? <Date dateString={dateString} showTime={false} /> : null;
            },
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            className: "table-button-cell",
            render: (_: any, item: TableShipment) =>
                <Space direction="horizontal" size={[5, 5]}>
                    {item.isShipment ? (
                        <>
                            <Tooltip placement="topRight" title={t("DOWNLOAD.DO_DOWNLOAD")}>
                                <a href={"/orders/shipments/labels?id[]=" + item.id}>
                                    <Button
                                        className="table-btn btn"
                                        type="default"
                                        icon={<FontAwesomeIcon icon={["fas", "download"]} />}
                                    />
                                </a>
                            </Tooltip>
                            <Tooltip placement="topRight" title={t("DISPATCH.DO_DISPATCH")}>
                                <Button
                                    className="table-btn btn"
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "box"]} />}
                                    onClick={() => dispatch(item)}
                                />
                            </Tooltip>
                        </>
                        ): null }
                </Space>
        },
    ];
    const filterKeys: FilterKey[] = [
        {
            label: t("NUMBER"),
            key: "number",
            type: "string",
        },
        {
            label: t("REFERENCE.TITLE"),
            key: "reference",
            type: "string",
        },
        {
            label: t("CLIENT.NAME"),
            key: "agentName",
            type: "string"
        },
        {
            label: t("WAREHOUSES.TITLE"),
            key: "warehouses",
            type: "select",
            options: warehouses.map(({ id, name }) => ({ value: id, label: name })),
        },
        {
            label: t("COURIER.TITLE"),
            key: "carriers",
            type: "select",
            options: couriers.map((courier) => ({
                value: courier.id,
                label: courier.name
            })),
        },
        {
            label: t("STATUS"),
            key: "statuses",
            type: "select",
            options: statuses.map((status) => ({
                value: status,
                label: t("SHIPMENTS.STATUS." + status.toUpperCase()),
            })),
        },
        {
            label: t("SHIPPED_AT"),
            key: "packedAt",
            type: "date",
        },
        {
            label: t("SHIPMENTS.PARCELS.NUMBER"),
            key: "parcelId",
            type: "int",
        },
        {
            label: t("SHIPMENTS.PARCELS.TRACK_CODE"),
            key: "trackCode",
            type: "string",
        }
    ];

    const filters: FieldGroupSnapshot[] = [
        {
            name: "statuses",
            snapshots: [
                {
                    name: "statuses",
                    value: defaultStatuses.join(",")
                },
            ],
        },
    ];

    const [selectedRows, setSelectedRows] = useState<Key[]>([]);
    const [selectedShipments, setSelectedShipments] = useState<TableShipment[]>([]);

    const dataHandler = useMemo(() => {
        const handler = new FilterDataHandler<Shipment>(
            (request) => {
                request.filters["processingType"] = "wholesale";
                return shipmentsService.getShipments(request);
            }, ShipmentsDropshipStore.convertDataForTable
        )

        return handler;
    }, []);

    const createStickers = async () => {
        await shipmentsService.createStickers(selectedShipments.map((item) => parseInt(item.id), 10));
        setSelectedShipments([]);
        setSelectedRows([]);
        await dataHandler.reloadWithLastRequest();
        notifications.successfully();
    }

    const dispatch = async (shipment?: TableShipment) => {
        let shipments: string[];
        if (shipment) {
            shipments = [shipment.id];
        } else {
            shipments = selectedShipments.map(s => s.id);
        }

        setShipmentsToDispatch(shipments)
        setDispatchModalShown(true);
    }

    const onDispatchSuccess = async () => {
        setSelectedShipments([]);
        setSelectedRows([]);
        await dataHandler.reloadWithLastRequest();
        notifications.successfully();
    }

    const createDHLManifest = async () => {
        const {blob, filename} = await shipmentsService.createDHLManifest(selectedShipments.map((item) => parseInt(item.id), 10));
        downloadBlobFile(blob, filename);
        setSelectedShipments([]);
        setSelectedRows([]);
        notifications.successfully();
    }

    const createTNTManifest = async () => {
        const {blob, filename} = await shipmentsService.createTNTManifest(selectedShipments.map((item) => parseInt(item.id), 10));
        downloadBlobFile(blob, filename);
        setSelectedShipments([]);
        setSelectedRows([]);
        notifications.successfully();
    }

    const createVenipakManifest = async () => {
        const {blob, filename} = await shipmentsService.createVenipakManifest();
        downloadBlobFile(blob, filename);
        setSelectedShipments([]);
        setSelectedRows([]);
        notifications.successfully();
    }


    const createList = async () => {
        const {blob, filename} = await shipmentsService.createList(selectedShipments.map((item) => parseInt(item.id)));
        downloadBlobFile(blob, filename);
        setSelectedShipments([]);
        setSelectedRows([]);
        notifications.successfully();
    }

    const handoverSpeedy = async () => {
        await shipmentsService.handoverSpeedy(selectedShipments.map((item) => parseInt(item.id)));
        setSelectedShipments([]);
        setSelectedRows([]);
        notifications.successfully();
    }

    return (
        <>
            <FilteredTable
                className="shipments-table"
                columns={columns}
                filterKeys={filterKeys}
                dataHandler={dataHandler}
                filters={filters}
                filterButtons={filterButtonConfigs}
                buttons={[
                    <Dropdown
                         trigger={["click"]}
                         overlay={
                             <Menu>
                                 <Menu.Item key={1} onClick={() => dispatch()}>
                                     {t("DISPATCH.DO_DISPATCH")}
                                 </Menu.Item>
                                 <Menu.Item key={2} onClick={createStickers}>
                                     {t("PRINT.CREATE")}
                                 </Menu.Item>
                                 <Menu.Item key={4} onClick={createDHLManifest}>
                                     Create DHL Manifest
                                 </Menu.Item>
                                 <Menu.Item key={5} onClick={createTNTManifest}>
                                     Create TNT Manifest
                                 </Menu.Item>
                                 <Menu.Item key={6} onClick={createVenipakManifest}>
                                     Create Venipak Manifest
                                 </Menu.Item>
                                 <Menu.Item key={7} onClick={handoverSpeedy}>
                                     Handover to Speedy Courier
                                 </Menu.Item>
                                 <Menu.Item key={3} onClick={createList}>
                                     Download selected as PDF
                                 </Menu.Item>
                             </Menu>
                         }
                     >
                         <Button type="primary">{t("ACTION")}</Button>
                    </Dropdown>
                ]}
                expandable={{
                    rowExpandable: (record: TableShipment) => !!record.children,
                }}
                rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: selectedRows,
                    checkStrictly: true,
                    onChange: (selectedRowKeys: Key[], selectedRows) => {
                        setSelectedRows(selectedRowKeys)
                        setSelectedShipments(selectedRows.filter(r => r.isShipment))
                    },
                    renderCell: (checked, record, index, originNode) => {
                        if (record.parentId) {
                            return <></>;
                        }
                        return originNode;
                    },
                }}
            />
            {dispatchModalShown ? (
                <ShipmentsDispatchModal
                    shipments={shipmentsToDispatch.map(x => parseInt(x))}
                    onClose={() => setDispatchModalShown(false)}
                    onSuccess={() => onDispatchSuccess()}
                />
            ) : null}
        </>
    );
});
