import React, {useMemo} from "react";
import {Space, Typography} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import i18n from "i18next";
import {Claim} from "../../../../../models/claims/claims";
import {ColumnsConfig, convertTableColumnsToColumnConfigs} from "../../../../../utils/table";
import {filterGroupsFactory} from "./claims.filter-groups";
import {FieldGroupConfig} from "../../../../../models/filter/filter.group";
import {FilterDataHandler} from "../../../../../models/filter/filter.data.handler";
import {claimService} from "../../../../../services/claims/claims.service";
import {columnsFactory} from "./claims.columns";
import FilteredTable from "../../../../common/filtered-table/filtered.table";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {useArticle} from "../../article.context";

export const ArticleDataClaims: React.FC<ComponentPropsFromRoute> = ({}) => {
    const t = useTranslate();
    const {article} = useArticle();

    const columns: ColumnsType<Claim> = useMemo(() => columnsFactory(t), [i18n.language]);
    const columnsConfigs: ColumnsConfig = useMemo(() => convertTableColumnsToColumnConfigs<Claim>(columns), [i18n.language])
    const fcgConfigs: FieldGroupConfig[] = useMemo(() => filterGroupsFactory(t), []);
    const dataHandler: FilterDataHandler<Claim> = useMemo(() => new FilterDataHandler(request => claimService.getAllReclamationsByArticle(request, article.id)), []);

    return (
        <Space direction={"vertical"} size={0}>
            <Typography.Title level={3}>{t("CLAIMS.TITLE")}</Typography.Title>
            <FilteredTable
                columns={columns}
                columnsConfigs={{ columns: columnsConfigs, pageKey: "article" }}
                dataHandler={dataHandler}
                filterGroups={fcgConfigs}
            />
        </Space>
    );
}