import { Amount } from "./price";
import { splitNumber } from "../../utils/helpers";
import {Price} from "../../components/common/price/price";

export type Currency = "EUR" | "GBP" | "USD" | "BGN" | "AED";
export type CurrencyMap = Record<Currency, string>;

export const currencies: CurrencyMap = {
    EUR: "€",
    GBP: "₤",
    USD: "$",
    BGN: "BGN",
    AED: "AED"
};

export enum DisplayType {
    DOT = 'dot',
    COMMA = 'comma'
}

export const replaceDotToComma = (value: string): string => {
    return value.replace(".", ",");
}

export const formatCurrencyCode = (code: string): string => {
    const ucCode = code.toUpperCase() as Currency;
    return currencies[ucCode] || ucCode;
}

export const formatWithDot = (value: number): string => {
    return splitNumber(value.toFixed(2));
}

export const formatWithComma = (value: number) => {
    return replaceDotToComma(splitNumber(value.toFixed(2)));
}

export const format = (value: number, type: DisplayType): string => {
    switch (type) {
        case DisplayType.DOT:
            return formatWithDot(value);
        case DisplayType.COMMA:
            return formatWithComma(value);
        default:
            return value.toFixed(2);
    }
}

export const displayCurrencyCode = (code: string) => {
    return currencies[code.toUpperCase() as keyof CurrencyMap] || code;
}

export const displayPrice = (amount: Amount) => {
    return (
        <Price value={amount} />
    );
};

export const createVisiblePrice = (amount: Amount, toK = false) => {
    return <Price value={amount} toK={toK} />
};
