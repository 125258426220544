import React, {useEffect, useMemo, useState} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {columnsFactory} from "./containers.list.columns";
import FilteredTable from "../../common/filtered-table/filtered.table";
import {FilterDataHandler} from "../../../models/filter/filter.data.handler";
import {containersService} from "../../../services/containers/containers.service";
import {Button, Row, Space, Typography} from "antd";
import {ContainersImportModal} from "../import/containers.import.modal";
import {useTranslate} from "../../../hooks/translate.hook";
import {Price} from "../../common/price/price";
import {ContainerSummary} from "../../../models/containers/containers";

export const ContainersList: React.FC<ComponentPropsFromRoute> = ({}) => {
    const t = useTranslate();

    const columns = useMemo(() => columnsFactory(t), []);
    const dataHandler = useMemo(() => new FilterDataHandler(request => containersService.getAll(request)), []);

    const [importModalShown, setImportModalShown] = useState<boolean>(false);

    const [summary, setSummary] = useState<ContainerSummary>();

    useEffect(() => {
        containersService.getSummary().then(s => setSummary(s));
    }, []);

    return <>
        <Space direction={"horizontal"} style={{ marginBottom: 10, justifyContent: "space-between" }}>
            <Space direction={"horizontal"} style={{ alignItems: "baseline" }}>
                <Typography.Title level={3}>{t("CONTAINERS.LIST.TITLE")}</Typography.Title>
                {summary ? (
                    <span>Orders in transit: <Price value={summary.total} /></span>
                ) : null}
            </Space>
            <Button onClick={() => setImportModalShown(true)} type="primary">
                {t("IMPORT.DO_IMPORT")}
            </Button>
        </Space>

        <FilteredTable
            columns={columns}
            dataHandler={dataHandler}
        />

        {importModalShown ? (
            <ContainersImportModal
                onClose={() => setImportModalShown(false)}
                onCompleted={() => dataHandler.reloadWithLastRequest()}
            />
        ) : null}
    </>
}