import {Translate} from "../../../../../hooks/translate.hook";
import {SmartColumn} from "../../../../common/smart-table/smart-table.model";
import {FieldType} from "../../../../common/smart-table/smart-table.field-config";

export const columnsFactory = (t: Translate): SmartColumn<any>[] => {
    return [
        {
            visibleName: t("COUNTRY"),
            name: "countryName",
            type: FieldType.STRING,
        },
        {
            visibleName: t("DATE"),
            name: "date",
            type: FieldType.DATETIME
        },
    ]
}