import React from "react";
import {ArticleDataOem} from "./oem/article.data.oem";
import {ArticleDataAnalogues} from "./analogues/article.data.analogues";
import {Row, Col} from "antd";

export const ArticleDataCrosses: React.FC = () => {
    return (
        <Row gutter={[20, 20]}>
            <Col span={12}>
                <ArticleDataOem />
            </Col>
            <Col span={12}>
                <ArticleDataAnalogues />
            </Col>
        </Row>
    )
}