import {Translate} from "../../../../../hooks/translate.hook";
import {Link} from "react-router-dom";
import React from "react";
import {ColumnsType} from "antd/lib/table/interface";
import Date from "../../../../date/date";
import {Button, Space} from "antd";

export const columnsFactory = (
    t: Translate,
    repack: (palletNumber: string, packageType: "pallet" | "box") => void
): ColumnsType<{}> => {
    return [
        {
            title: 'Pallet number',
            dataIndex: 'palletNumber',
            width: '20%'
        },
        {
            title: 'Agents',
            dataIndex: 'agentList',
            width: '40%',
            render: (codes: string[]) => codes
                .map<React.ReactNode>(code => (<Link to={"/clients/" + code}>{code}</Link>))
                .reduce((prev, curr) => [prev, ', ', curr])
        },
        {
            title: 'In boxes',
            dataIndex: 'asBox'
        },
        {
            title: 'In pallets',
            dataIndex: 'asPallet'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            render: date => <Date dateString={date} />,
        },
        {
            title: 'Actions',
            dataIndex: 'palletNumber',
            render: (palletNumber: string) => <Space direction={'horizontal'} size={[20, 20]}>
                <Button
                    size={"small"}
                    onClick={() => repack(palletNumber, "box")}
                >Repack to boxes</Button>
                <Button
                    size={"small"}
                    onClick={() => repack(palletNumber, "pallet")}
                >Undo</Button>
            </Space>
        }
    ];
}