import React from "react";
import {Row, Col} from "antd";
import {ArticleDataAttributes} from "./attributes/article.data.attributes";
import {ArticleDataStatus} from "./status/article.data.status";

export const ArticleDataBasic: React.FC = () => {

    return (
        <Row gutter={[20, 20]}>
            <Col span={12}>
                <ArticleDataAttributes />
            </Col>
            <Col span={12}>
                <ArticleDataStatus />
            </Col>
        </Row>
    )
}