import React from "react";
import {observer} from "mobx-react";
import {Descriptions, Space, Typography} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {useArticle} from "../../../article.context";

export const ArticleDataAttributes: React.FC = observer(() => {
    const t = useTranslate();
    const {article} = useArticle();

    return (
        <Space direction={"vertical"} size={0}>
            <Typography.Title level={3}>{t("ARTICLES.ATTRIBUTES.TITLE")}</Typography.Title>
            {article.attributes.length ? (
                <Descriptions
                    bordered
                    size={"middle"}
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
                    {article.attributes.map(({name, value}) => (
                        <Descriptions.Item key={name} label={name}>{value}</Descriptions.Item>
                    ))}
                </Descriptions>
            ) : t("NO_DATA")}
        </Space>
    );
})
