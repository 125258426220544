import {ColumnsType} from "antd/es/table";
import {Invoice, InvoicePackingListDocument} from "../../../../models/documents/invoices/invoice";
import {Link} from "react-router-dom";
import {DocumentStatus} from "../../../../models/documents/status";
import {DocumentsStatus} from "../../../common/documents/status/documents.status";
import {Amount} from "../../../../models/prices/price";
import {displayPrice} from "../../../../models/prices/price.helper";
import Date from "../../../date/date";
import React from "react";
import {Translate} from "../../../../hooks/translate.hook";
import {InvoicePaymentStatus} from "../invoice-payment-status/invoice-payment.status";

export const columnsFactory = (t: Translate, payAllowed: boolean): ColumnsType<Invoice> => {
    const columns: ColumnsType<Invoice> = [
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "docNumber",
            key: "invoices_docNumber",
            width: "120px",
            render: (docNumber: string, row) => <Link to={"/invoices/" + row.id}>{docNumber}</Link>
        },
        // {
        //     title: t("ORDERS.ORDER.TITLE"),
        //     dataIndex: "masterOrder",
        //     key: "invoices_masterOrder",
        //     width: "100px",
        //     render: (item) => <Link to={"/orders/" + item.id}>{item.id}</Link>,
        // },
        {
            title: t("INVOICES.PACKING_LIST_NR"),
            dataIndex: "packingListDocument",
            key: "invoices_packingListDocument",
            width: "150px",
            render: (packingListDocument: InvoicePackingListDocument) => packingListDocument ? (
                <Link to={"/packing-lists/" + packingListDocument.id}>{packingListDocument.docNumber}</Link>
            ) : null
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (status: DocumentStatus) => <DocumentsStatus status={status} />,
        }
    ];

    if (payAllowed) {
        columns.push(
            {
                title: t("PAY"),
                dataIndex: "paid",
                key: "paid",
                render: (value) => (
                    <InvoicePaymentStatus paid={value} />
                ),
            }
        );
    }

    columns.push(
        {
            title: t("CLIENT.NAME"),
            dataIndex: "agent",
            key: "invoices_agent",
            render: (agent) => (
                <Link to={"/clients/" + agent.number}>{agent.name}</Link>
            ),
        },

        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "invoices_grandTotal",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },

        {
            title: t("WEIGHT_BRUTTO"),
            dataIndex: "totalGrossWeight",
            key: "invoices_totalGrossWeight",
            render: (item: number) => item,
        },
        {
            title: t("PAYMENT_TERMS"),
            dataIndex: "paymentTerms",
            key: "paymentTerms",
            width: 105
        },
        {
            title: t("INVOICES.DISPATCH.IS_DISPATCHED"),
            dataIndex: "dispatchStatus",
            key: "invoices_dispatched",
            render: (value: string) => t("INVOICES.DISPATCH." + value.toUpperCase()),
            width: 200
        },
        {
            title: t("INVOICES.DISPATCH.DISPATCH_DATE"),
            dataIndex: "date",
            key: "invoices_date",
            render: (date: string) => <Date dateString={date} showTime withSeconds/>,
        }
    );

    return columns;
}