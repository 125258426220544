import React, {useEffect, useState} from "react";
import { observer } from "mobx-react";
import {Space, Table, Typography} from "antd";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {columnsFactory} from "./article.data.stock.colums";
import {articlesStockService} from "../../../../../../services/articles/articles/articles.stock.service";
import {useArticle} from "../../../article.context";
import {Stocks} from "../../../../../../models/articles/articles.stock";
import {PaginatedRequest} from "../../../../../../models/core/paginated.request";

export const ArticleDataStock: React.FC = observer(() => {

    const t = useTranslate();
    const {article} = useArticle();

    const columns = columnsFactory(t);
    const [stock, setStock] = useState<Stocks[]>([]);

    useEffect(() => {
        const request = new PaginatedRequest({id: article.id});
        articlesStockService.getAll(request).then( r => setStock(r.items[0].stocks))
    }, []);

    return (
        <Space direction={"vertical"} size={0}>
            <Typography.Title level={3}>
                {t("ARTICLES.WAREHOUSES.TITLE")}
            </Typography.Title>
            <Table
                columns={columns}
                rowKey={"id"}
                dataSource={stock}
                scroll={{x: true}}
                pagination={false}
            />
        </Space>
    );
});
