import React from "react";
import {Button, Checkbox, Col, Form, FormInstance, InputNumber, Row, Select, Tooltip, Typography} from "antd";
import {renderSortButtons} from "../../../models/users/user/user.data.helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslate} from "../../../hooks/translate.hook";
import {WarehouseWithSort} from "../../../models/warehouses/warehouse";
import {NumberInput} from "../number-input/number.input";
import {useRequiredStore} from "../../../utils/store";
import {RootStoreContext} from "../../../stores/root/root.store";
import {observer} from "mobx-react";
import {Explanation} from "../explanation/explanation";


interface WarehousesFormListProps {
    parentFieldName?: string,
    withMaxAmount?: boolean,
    withTransactionType?: boolean,
    disabled: boolean,
    form: FormInstance,
}


export const WarehousesFormList: React.FC<WarehousesFormListProps> = observer(({parentFieldName, disabled, form, withMaxAmount, withTransactionType = true}) => {
    const t = useTranslate();
    const {commonStore: {warehouses}} = useRequiredStore(RootStoreContext);
    const isSelectOptionDisabled = (id: string) => form.getFieldValue(parentFieldName ? [parentFieldName, "warehouses"] : "warehouses").map((item: WarehouseWithSort) => item.warehouse).includes(id);

    if (!warehouses.length) {
        return null;
    }

    const getDefaultValue = () => {
        const value: any = {};

        if (withTransactionType) {
            value.transactionType = "regular";
        }

        return value;
    }

    return (
        <>
            <Typography.Paragraph style={{marginBottom: 8}}>{t("WAREHOUSES.TITLE")}</Typography.Paragraph>
            <Form.List
                name={parentFieldName ? [parentFieldName, "warehouses"] : "warehouses"}
                rules={[{
                    validator: async (rule, names) => {
                        try {
                            if (!names || names.length === 0) {
                                return Promise.reject((t('FROM.ERROR.PLEASE_ADD_VALUE')));

                            }
                        } catch (error) {
                            throw new Error(error as string);
                        }
                    }
                }]}
            >
                {(fields, {add, remove}, {errors}) => (
                    <>
                        <Row justify={"space-between"} gutter={5} style={{marginBottom: 8, color: '#aaaaaa'}}>
                            <Col span={4}>
                                Sort
                            </Col>
                            <Col span={withMaxAmount ? (withTransactionType ? 6 : 11) : 14}>
                                Warehouse
                            </Col>
                            {withMaxAmount ? (
                                <Col span={4}>
                                    <Explanation
                                        title={t("ACCOUNT.SETTINGS.WAREHOUSE.MAX_AMOUNT.TITLE")}
                                        tooltip={t("ACCOUNT.SETTINGS.WAREHOUSE.MAX_AMOUNT.EXPL")}
                                    />
                                </Col>
                            ) : null}
                            {withTransactionType ? (
                                <>
                                    <Col span={5}>
                                        <Explanation
                                            title={t("ACCOUNT.SETTINGS.WAREHOUSE.TRANSACTION_TYPE.TITLE")}
                                            tooltip={t("ACCOUNT.SETTINGS.WAREHOUSE.TRANSACTION_TYPE.EXPL")}
                                        />
                                    </Col>
                                    <Col span={3}>
                                        <Explanation
                                            title={t("ACCOUNT.SETTINGS.WAREHOUSE.READONLY.TITLE")}
                                            tooltip={t("ACCOUNT.SETTINGS.WAREHOUSE.READONLY.EXPL")}
                                        />
                                    </Col>
                                </>
                            ) : null}
                            <Col span={2}>
                                Actions
                            </Col>
                        </Row>
                        {fields.map((field, index) => (
                            <Row justify={"space-between"} gutter={5} key={field.key}>
                                {renderSortButtons(form, index, fields.length, disabled, parentFieldName ? [parentFieldName, "warehouses"] : "warehouses", "warehouses")}
                                <Col span={withMaxAmount ? (withTransactionType ? 6 : 11) : 14}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(oldValue, newValue) => oldValue.changeNumberEnabled !== newValue.changeNumberEnabled}
                                    >
                                        {() => (
                                            <Form.Item
                                                {...field}
                                                name={parentFieldName ? [field.name, "warehouse"] : [field.name, "warehouse"]}
                                                
                                                rules={[{
                                                    required: true,
                                                    message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                                }]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    optionFilterProp={"children"}
                                                    disabled={disabled}
                                                    placeholder={t("WAREHOUSES.WAREHOUSE_TITLE")}
                                                >
                                                    {warehouses.map(item => (
                                                        <Select.Option
                                                            key={item.id}
                                                            value={item.id}
                                                            disabled={isSelectOptionDisabled(item.id)}
                                                        >
                                                            {item.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                                {withMaxAmount ? (
                                    <Col span={4}>
                                        <Form.Item
                                            {...field}
                                            name={parentFieldName ? [field.name, "maxAmount"] : [field.name, "maxAmount"]}
                                            rules={[{
                                                required: true,
                                                message: t('FROM.ERROR.PLEASE_SELECT_VALUE'),
                                            }]}
                                        >
                                            <InputNumber disabled={disabled} placeholder={t("MAX_AMOUNT")}/>
                                        </Form.Item>
                                    </Col>
                                ) : null}
                                {withTransactionType ? (
                                    <>
                                        <Col span={5}>
                                            <Form.Item
                                                {...field}
                                                name={parentFieldName ? [field.name, "transactionType"] : [field.name, "transactionType"]}

                                                rules={[{
                                                    required: true,
                                                    message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                                }]}
                                            >
                                                <Select
                                                    disabled={disabled}
                                                    placeholder={t("INVOICING.TRANSACTION_TYPE.TITLE")}
                                                >
                                                    {["regular", "triangular"].map(item => (
                                                        <Select.Option
                                                            key={item}
                                                            value={item}
                                                        >
                                                            {item}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Form.Item
                                                {...field}
                                                name={parentFieldName ? [field.name, "readonly"] : [field.name, "readonly"]}
                                                valuePropName={"checked"}
                                            >
                                                <Checkbox disabled={disabled} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                ) : null}
                                <Col span={2}>
                                    <Tooltip placement="topRight" title={t('REMOVE')}>
                                        <Button
                                            type="default"
                                            danger
                                            onClick={() => remove(field.name)}
                                            icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                            style={{width: "100%"}}
                                            className="btn-flex-center"
                                            disabled={disabled}
                                        />
                                    </Tooltip>
                                </Col>
                            </Row>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                disabled={disabled}
                                onClick={() => {
                                    add(getDefaultValue());
                                }}
                                block
                                icon={<FontAwesomeIcon icon={["fas", "plus-circle"]}/>}
                            >
                                {t("WAREHOUSES.ADD")}
                            </Button>
                            <Form.ErrorList errors={errors}/>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    );
});
