import {Translate} from "../../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {ArticleOEM} from "../../../../../../models/articles/article.data";

export const columnsFactory = (t: Translate): ColumnsType<ArticleOEM> => {
    return [
        {
            title: t("ARTICLES.MANUFACTURER"),
            dataIndex: "brandName",
            key: "brandName"
        },
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name"
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "description"
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status"
        },
    ];
}