import { DatePicker, Select, Space, Typography } from "antd";
import React, {lazy, LazyExoticComponent, useEffect, useMemo, useState} from "react";
import dayjs from "dayjs";
import {ArticleAvailabilityStats} from "../../../../../../models/articles/article.availability.stats";
import {GraphType} from "../../../../../common/graph/graph";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {useRequiredStore} from "../../../../../../utils/store";
import {RootStoreContext} from "../../../../../../stores/root/root.store";
import {FieldGroupSnapshot} from "../../../../../../models/filter/filter.field.snapshot";
import {GraphDataWithMeta} from "../../../../../../models/graph/graph";
import {ChartConfigDataset} from "../../../../../../models/reports/reports.chart.configs";
import {articlesService} from "../../../../../../services/articles/articles.service";
import {useArticle} from "../../../article.context";

const Chart: LazyExoticComponent<GraphType<ArticleAvailabilityStats, any>> = lazy(() => import("../../../../../common/graph/graph"));

export const ArticleDataAvailabilityHistoryHistory: React.FC = () => {
    const t = useTranslate();
    const {article} = useArticle();

    const {
        commonStore: { warehouses },
    } = useRequiredStore(RootStoreContext);

    const [selectedWarehouses, setSelectedWarehouses] = useState<string[]>([]);
    const [value, setValue] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([dayjs().add(-2, "M"), dayjs()]);

    const filters: FieldGroupSnapshot[] = useMemo(() => [
        {
            name: "from",
            snapshots: [
                {
                    value: value[0].format("YYYY-MM-DD"),
                    rule: undefined,
                    name: "from",
                },
            ],
        },
        {
            name: "until",
            snapshots: [
                {
                    value: value[1].format("YYYY-MM-DD"),
                    rule: undefined,
                    name: "until",
                },
            ],
        },
        {
            name: "warehouses",
            snapshots: [
                {
                    name: "warehouses",
                    rule: undefined,
                    value: selectedWarehouses,
                },
            ],
        },
    ], [value, selectedWarehouses]);

    useEffect(() => {
        if (warehouses.length > 0) {
            setSelectedWarehouses(
                warehouses.filter((item) => (item.type === "retail" || item.type === "customs") && item.status === "active").map((item) => item.id)
            );
        }
    }, [warehouses.length]);

    if (selectedWarehouses.length === 0) {
        return null;
    }

    const getArticleAvailabilityGraphLabels = (data: GraphDataWithMeta<ArticleAvailabilityStats, any>): string[] => {
        const dates = [];
        let valueFirst = value[0].clone();
        while (valueFirst.isBefore(value[1])) {
            dates.push(valueFirst.format("DD-MM-YYYY"));
            valueFirst = valueFirst.add(1, "d");
        }

        return dates;
    };

    const getArticleAvailabilityGraphValues = (
        data: GraphDataWithMeta<ArticleAvailabilityStats, any>
    ): ChartConfigDataset[] => {
        const dates: string[] = [];
        let valueFirst = value[0].clone();
        while (valueFirst.isBefore(value[1])) {
            dates.push(valueFirst.format("YYYY-MM-DD"));
            valueFirst = valueFirst.add(1, "d");
        }

        return data.items.map((item) => {
            const valuesMap: { [date: string]: number } = {};

            dates.forEach((date) => {
                valuesMap[date] = 0;
            });

            item.data.forEach(({ date, availability }) => {
                if (valuesMap[date] !== undefined) {
                    valuesMap[date] = availability;
                }
            });

            const warehouse = warehouses.find((warehouse) => warehouse.id === item.warehouse);

            return {
                fill: false,
                data: Object.values(valuesMap),
                label: warehouse ? warehouse.name : "",
            };
        });
    };

    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>{t("ARTICLES.HISTORY")}</Typography.Title>
            <Space direction="vertical" size={[5, 5]}>
                <DatePicker.RangePicker
                    showNow
                    allowClear={false}
                    style={{ width: "100%" }}
                    value={value}
                    disabledDate={(date, {from}) => {
                        if (from) {
                            return date.diff(from, 'day') > 65
                        }
                        return false;
                    }}
                    onChange={(val) => setValue(val as any)}
                />

                <Select
                    showSearch
                    allowClear={false}
                    mode="multiple"
                    placeholder={t("WAREHOUSES.TITLE")}
                    value={selectedWarehouses}
                    optionFilterProp={"children"}
                    onChange={(value) => setSelectedWarehouses(value)}
                    // disabled={loading}
                    style={{ width: "100%" }}
                >
                    {warehouses.map(({ id, name }) => (
                        <Select.Option key={id} value={id}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
            </Space>

            <Chart
                // filterKeys
                loadOnInit={false}
                filtersHidden
                snapshots={filters}
                loadDataCallback={(request) =>
                    articlesService.getArticleAvailabilityStatistics(article.id, request)
                }
                getGraphData={getArticleAvailabilityGraphValues}
                getGraphLabelsData={getArticleAvailabilityGraphLabels}
                yAxeLabel="WAREHOUSES.AVAILABILITY"
                xAxeLabel="DATE"
            />
        </Space>
    );
};
