import { AxiosInstance } from "axios";
import API, {BlobAndFilename, getBlobAndFileName} from "../../utils/api";
import { Branch } from "../../models/branches/branch";
import { PaginatedRequest } from "../../models/core/paginated.request";
import { PaginatedResult } from "../../models/core/paginated.result";
import { BranchBank, BranchBankRequestData, BranchData, BranchRequestValues } from "../../models/branches/branch-data";
import { WarehouseExpanded } from "../../models/warehouses/warehouses";
import {
    ShippingCourier,
    ShippingCourierWithPrice,
    ShippingPrice,
    ShippingRule,
    TestSelection
} from "../../models/shipping/shipping";
import {WarehousePriorityGroup} from "../../models/warehouses/warehouse";
import {PriceList, UploadPriceRequest} from "../../models/price-lists/price.lists";
import {DocumentFile} from "../../models/documents/common/files";
import {WarehousesTaxesRequest, WarehouseTaxes} from "../../models/warehouses/warehouses.taxes";
import {City, CityArea, Country} from "../../models/countries/country";

export class BranchesService {
    private api: AxiosInstance = API;

    getBranches(request: PaginatedRequest): Promise<PaginatedResult<Branch>> {
        return this.api.get<PaginatedResult<Branch>>("branches").then((x) => x.data);
    }

    getAllBranches(): Promise<Branch[]> {
        return this.api.get<Branch[]>("branches/all").then((x) => x.data);
    }

    getBranch(id: string): Promise<BranchData> {
        return this.api.get<BranchData>("branches/" + id).then((x) => x.data);
    }

    updateBranch(id: string, values: BranchRequestValues): Promise<BranchData> {
        return this.api.post<BranchData>("branches/" + id, values).then((x) => x.data);
    }

    getBranchesBanks(id: string): Promise<BranchBank[]> {
        return this.api.get<BranchBank[]>("branches/" + id + "/banks").then((x) => x.data);
    }

    createBranchesBank(id: string, values: BranchBankRequestData): Promise<BranchBank> {
        return this.api.post<BranchBank>("branches/" + id + "/banks", values).then((x) => x.data);
    }

    updateBranchesBank(id: number, values: BranchBankRequestData): Promise<BranchBank> {
        return this.api.post<BranchBank>("branches/banks/" + id, values).then((x) => x.data);
    }

    attachToAllAccounts(id: number): Promise<any> {
        return this.api.post<any>("branches/banks/" + id + "/set-for-all");
    }

    removeBranchBank(id: number) {
        return this.api.delete("branches/banks/" + id);
    }

    getWarehousesTaxes(id: string, request: PaginatedRequest): Promise<PaginatedResult<WarehouseTaxes>> {
        return this.api.get<PaginatedResult<WarehouseTaxes>>(
            "branches/" + id + "/tax-rules",
            {
                params: request.toParams()
            }
        ).then(x => x.data);
    }

    createTaxRule(id: string, request: WarehousesTaxesRequest): Promise<WarehouseTaxes> {
        return this.api.post<WarehouseTaxes>("branches/" + id + "/tax-rules", request).then(x => x.data);
    }

    editTaxRule(request: WarehousesTaxesRequest, id: number): Promise<WarehouseTaxes> {
        return this.api.post<WarehouseTaxes>("branches/tax-rules/" + id, request).then(x => x.data);
    }

    removeTaxRule(id: number): Promise<void> {
        return this.api.delete("branches/tax-rules/" + id);
    }

    getWarehouses(id: string, request: PaginatedRequest): Promise<PaginatedResult<WarehouseExpanded>> {
        return this.api
            .get<PaginatedResult<WarehouseExpanded>>("branches/" + id + "/warehouses", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getPrices(id: string, request: PaginatedRequest): Promise<PaginatedResult<PriceList>> {
        return this.api
            .get<PaginatedResult<PriceList>>("branches/" + id + "/prices", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getAllPrices(id: string): Promise<PriceList[]> {
        return this.api
            .get<PriceList[]>("branches/" + id + "/prices/all")
            .then((x) => x.data);
    }

    edit(priceId: number, dto: object): Promise<void> {
        return this.api
            .post("branches/prices/" + priceId, dto);
    }

    uploadPrice(priceId: number, dto: UploadPriceRequest): Promise<DocumentFile> {

        const formData = new FormData();

        formData.append('file', dto.file);
        formData.append('applyForParentPrice', dto.applyForParentPrice ? "1" : "0");
        if (dto.applyNextPriceAt) {
            formData.append('applyNextPriceAt', dto.applyNextPriceAt?.format());
        }

        return this.api
            .post("branches/prices/" + priceId + "/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(x => x.data);
    }

    downloadPrice(priceId: number): Promise<BlobAndFilename> {
        return this.api.get("branches/prices/" + priceId + "/csv", {responseType: "blob"}).then(getBlobAndFileName);
    }

    getShippingCouriers(branchId: string): Promise<ShippingCourier[]> {
        return this.api
            .get<ShippingCourier[]>("branches/" + branchId + "/shipping/couriers")
            .then((x) => x.data);
    }

    downloadShippingCouriersAsXLSX(branchId: string): Promise<BlobAndFilename> {
        return this.api
            .get("branches/" + branchId + "/shipping/couriers/export-xlsx", {responseType: "blob"}).then(getBlobAndFileName);
    }

    getShippingCourier(id: string): Promise<ShippingCourier> {
        return this.api
            .get<ShippingCourier>("branches/shipping/couriers/" + id)
            .then((x) => x.data);
    }

    editShippingCourier(id: string, courier: ShippingCourier): Promise<ShippingCourier> {
        return this.api
            .post<ShippingCourier>("branches/shipping/couriers/" + id, courier)
            .then((x) => x.data);
    }

    getShippingPrices(courierId: string, request: PaginatedRequest): Promise<PaginatedResult<ShippingPrice>> {
        return this.api
            .get<PaginatedResult<ShippingPrice>>("branches/couriers/" + courierId + "/prices", {
                params: request.toParams(),
            })
            .then((x) => x.data);
    }

    getAllShippingRules(courierId: string): Promise<ShippingRule[]> {
        return this.api
            .get<ShippingRule[]>("branches/couriers/" + courierId + "/rules")
            .then((x) => x.data);
    }

    addShippingRule(courierId: string, rule: ShippingRule): Promise<ShippingRule> {
        return this.api
            .post<ShippingRule>("branches/couriers/" + courierId + "/rules", rule)
            .then((x) => x.data);
    }

    editShippingRule(ruleId: string, rule: ShippingRule): Promise<ShippingRule> {
        return this.api
            .post<ShippingRule>("branches/couriers/rules/" + ruleId, rule)
            .then((x) => x.data);
    }

    removeShippingRule(ruleId: string): Promise<void> {
        return this.api
            .delete("branches/couriers/rules/" + ruleId);
    }

    fullImportShippingRules(courierId: string, file: File): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.api
            .post("branches/couriers/" + courierId + "/full-upload-rules", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    shortImportShippingRules(courierId: string, file: File): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.api
            .post("branches/couriers/" + courierId + "/short-upload-rules", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }


    getAllWarehousePriorityGroups(branchId: string): Promise<WarehousePriorityGroup[]> {
        return this.api
            .get("branches/" + branchId + "/warehouses-priority-groups")
            .then(x => x.data);
    }

    createWarehousePriorityGroup(branchId: string, dto: WarehousePriorityGroup): Promise<WarehousePriorityGroup> {
        return this.api
            .post("branches/" + branchId + "/warehouses-priority-groups", dto)
            .then(x => x.data);
    }

    updateWarehousePriorityGroup(id: number, dto: WarehousePriorityGroup): Promise<WarehousePriorityGroup> {
        return this.api
            .post("branches/warehouses-priority-groups/" + id, dto)
            .then(x => x.data);
    }

    testCourierSelection(dto: TestSelection): Promise<ShippingCourierWithPrice[]> {
        return this.api
            .get("branches/couriers/test-selection", {params: dto})
            .then(x => x.data);
    }

    createCourier(branchId: string, name: string): Promise<ShippingCourierWithPrice> {
        return this.api
            .post("branches/" + branchId + "/couriers", {name: name});
    }

    getEmployees(branchId: string, request: PaginatedRequest): Promise<PaginatedResult<any>> {
        return this.api
            .get("branches/" + branchId + "/employees", {params: request.toParams()})
            .then(x => x.data);
    }

    getCountries(branchId: string): Promise<Country[]> {
        return this.api
            .get("branches/" + branchId + "/countries")
            .then(x => x.data);
    }

    getCities(countryCode: string): Promise<City[]> {
        return this.api
            .get("branches/countries/" + countryCode + "/city")
            .then(x => x.data);
    }
    createCity(countryCode: string, dto: any): Promise<Country[]> {
        return this.api
            .post("branches/countries/" + countryCode + "/city", dto)
            .then(x => x.data);
    }

    removeCity(cityId: number): Promise<Country[]> {
        return this.api
            .delete("branches/countries/city/" + cityId);
    }

    getCityAreas(cityId: number): Promise<CityArea[]> {
        return this.api
            .get("branches/countries/city/" + cityId + "/area")
            .then(x => x.data);
    }

    createCityArea(cityId: number, dto: any): Promise<Country[]> {
        return this.api
            .post("branches/countries/city/" + cityId + "/area", dto)
            .then(x => x.data);
    }

    removeCityArea(areaId: number): Promise<Country[]> {
        return this.api
            .delete("branches/countries/area/" + areaId);
    }
}

export const branchesService = new BranchesService();
