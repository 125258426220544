import React from "react";
import {Row, Col} from "antd";
import {ArticleDataPrices} from "./prices/article.data.prices";
import {ArticleDataStock} from "./stock/article.data.stock";

export const ArticleDataPricesAndAvailability: React.FC = () => {
    return (
        <Row gutter={[20, 20]}>
            <Col span={12}>
                <ArticleDataPrices />
            </Col>
            <Col span={12}>
                <ArticleDataStock />
            </Col>
        </Row>
    )
}