import React from "react";
import { observer } from "mobx-react";
import {
    Alert,
    Button,
    Descriptions,
    Dropdown,
    Menu,
    notification,
    Row,
    Space,
    Tooltip,
    Typography
} from "antd";
import { Link } from "react-router-dom";
import { useRequiredStore } from "../../../../../utils/store";
import { InvoiceDataStoreContext } from "../invoice.data.store";
import { RootStoreContext } from "../../../../../stores/root/root.store";
import { InvoicesDispatchModal } from "../../invoices-dispatch-modal/invoices.dispatch.modal";
import { Export } from "../../../../common/export/export";
import { RecalcWeightModal } from "../../../../common/recalc-weight-modal/recalc.weight.modal";
import "./invoice.data.header.scss";
import { DocumentsStatus } from "../../../../common/documents/status/documents.status";
import Date from "../../../../date/date";
import {isAllowed} from "../../../../../utils/helpers";
import {invoiceService} from "../../../../../services/documents/invoices/invoices.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InvoiceEditModal} from "../invoice-edit-modal/invoice-edit-modal";
import {InvoicesChangeDateModal} from "../../invoices-change-date-modal/invoices.change-date.modal";
import {SourcesListModal} from "../../../common/sources/modal/sources-list/sources-list.modal";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";
import {
    WrongDeliveryModal
} from "../../../../agents/agent-data/agent-data-documents/new/wrong-delivery-modal/wrong-delivery-modal";
import {InvoicePaymentStatus} from "../../invoice-payment-status/invoice-payment.status";

export const InvoiceDataHeader: React.FC = observer(() => {
    const t = useTranslate();
    const navigate = useNavigate();

    const {
        authStore: { attributes, lz },
    } = useRequiredStore(RootStoreContext);

    const store = useRequiredStore(InvoiceDataStoreContext);

    const openNotification = (type: "success" | "error", message: string): void => {
        notification[type]({
            message: t(type.toUpperCase()),
            description: t(message),
            duration: 2
        });
    };

    return (
        <>
            <PageHeader
                className="invoice-header"
                style={{ padding: 0 }}
                ghost={false}
                title={
                    <Typography.Title level={1} style={{ margin: 0 }}>
                        {t("INVOICES.INVOICE.TITLE") + " " + store.invoice?.docNumber}
                    </Typography.Title>
                }
                onBack={() => {
                    navigate("/invoices");
                }}
                extra={
                    <Space direction={"horizontal"}>
                        {lz.py ? (
                            <>
                                <InvoicePaymentStatus large paid={store.invoice!.paid} />
                            </>
                        ) : null}
                        <DocumentsStatus large status={store.invoice?.status!} />
                    </Space>
                }
            >
                <Space direction="vertical" size={[15, 15]}>
                    <Row justify={"space-between"}>
                        <Space className={"invoice-data-header-buttons"} direction={"horizontal"} size={[20, 20]} wrap>
                            <Dropdown
                                placement={"bottomLeft"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        {store.invoice?.orders && store.invoice.orders.length
                                            ? store.invoice.orders.map((order) => (
                                                  <Menu.Item key={order}>
                                                      <Link
                                                          style={{ textAlign: "left" }}
                                                          className={"ant-btn ant-btn-link"}
                                                          to={"/orders/" + order}
                                                      >
                                                          {order}
                                                      </Link>
                                                  </Menu.Item>
                                              ))
                                            : null}
                                    </Menu>
                                }
                            >
                                <Button type="primary">{t("ORDERS.LIST")}</Button>
                            </Dropdown>
                            <Dropdown
                                placement={"bottomRight"}
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        <Menu.Item key={0}>
                                            <a
                                                rel="noopener noreferrer"
                                                style={{ textAlign: "left" }}
                                                target="_blank"
                                                className={"ant-btn ant-btn-link"}
                                                href={"/documents/" + store.invoice!.id}
                                            >
                                                {t("DOCUMENTS.FILES.SHOW_PDF")}
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item key={1} onClick={() => store.handleModal("export", true)}>
                                            {t("DOWNLOAD.CSV_EXCEL")}
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                            </Dropdown>

                            {store.invoice?.packingListDocument ? (
                                <Dropdown
                                    placement={"bottomRight"}
                                    trigger={["click"]}
                                    overlay={
                                        <Menu>
                                            <Menu.Item key={0}>
                                                <Link to={"/packing-lists/" + store.invoice?.packingListDocument.id}>
                                                    {t("ORDERS.ORDER.VIEW.PACKING_LIST")}
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key={1}>
                                                <a
                                                    rel="noopener noreferrer"
                                                    style={{ textAlign: "left" }}
                                                    target="_blank"
                                                    className={"ant-btn ant-btn-link"}
                                                    href={"/documents/" + store.invoice!.packingListDocument.id}
                                                >
                                                    {t("DOCUMENTS.FILES.SHOW_PDF")}
                                                </a>
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                                    <Button> {t("ORDERS.ORDER.VIEW.PACKING_LIST")}</Button>
                                </Dropdown>
                            ) : null}

                            {store.invoice?.mergedInvoiceDocument ? (
                                <Link
                                    to={"/merged-invoices/" + store.invoice?.mergedInvoiceDocument.id}
                                >
                                    <Button type={"default"}>
                                        {t("INVOICE.MERGED.TITLE")}
                                    </Button>
                                </Link>
                            ) : null}
                            <a
                                href={"/api/v3/documents/invoices/" + store.invoice?.id + "/export-invoice"}
                            >
                                <Button type={"primary"}>
                                    {t("ORDERS.ORDER.VIEW.GENERATE_EXPORT_INVOICE")}
                                </Button>
                            </a>
                        </Space>

                        <Dropdown
                            placement={"bottomRight"}
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                    {store.invoice?.status === "opened" || store.invoice?.status === "invalid" ? (
                                        <Menu.Item key={0} onClick={() => store.uploadInvoice(false, false)}>
                                            {t("INVOICES.UPLOAD_TO_SECONDARY.ACTION")}
                                        </Menu.Item>
                                    ) : null}
                                    {(store.invoice?.status === "pre_closed" || store.invoice?.dispatchStatus === "not_dispatched") ? (
                                        <>
                                            <Menu.Item key={1} onClick={() => store.handleModal("dispatch", true)}>
                                                {t("INVOICES.DISPATCH.DO_DISPATCH")}
                                            </Menu.Item>
                                            <Menu.Item
                                                key={2}
                                                onClick={() => store.handleModal("editWeight", true)}
                                            >
                                                {t("RECALCULATE_WEIGHT.TITLE")}
                                            </Menu.Item>
                                        </>
                                    ) : null}
                                    <>
                                        {store.invoice?.status !== "sync" && store.invoice?.status !== "cancelled"  ? (
                                            <>
                                                <Menu.Item key={3} onClick={() => store.cancelInvoice(openNotification)}>
                                                    {t("INVOICES.CANCEL")}
                                                </Menu.Item>
                                                <Menu.Item key={4} onClick={() => store.cancelInvoiceWithOrders(openNotification)}>
                                                    {t("INVOICES.CANCEL_WITH_ORDERS")}
                                                </Menu.Item>
                                            </>
                                        ) : null}
                                        {store.invoice?.status === "pre_closed" || store.invoice?.status === "closed" ? (
                                            <Menu.Item key={3} onClick={() => store.handleModal("setDate", true)}>
                                                {t("INVOICES.SET-DATE.TITLE")}
                                            </Menu.Item>
                                        ) : null}
                                        <Menu.Item key={5} onClick={() => store.moveToCart()}>
                                            {t("INVOICES.MOVE_TO_CART")}
                                        </Menu.Item>
                                        <Menu.Item key={6} onClick={() => store.moveToCartWithClear()}>
                                            {t("INVOICES.MOVE_TO_CART_WITH_CLEAR")}
                                        </Menu.Item>
                                        {isAllowed(attributes, "faker") ? (
                                            <Menu.Item key={3} onClick={() => store.handleModal("sourcesList", true)}>
                                                Show Sources List
                                            </Menu.Item>
                                        ) : null}
                                        <Menu.Item key={7} onClick={() => store.sendDocumentToClient()}>
                                            {t("DOCUMENTS.SEND_TO_CLIENT")}
                                        </Menu.Item>
                                    </>
                                </Menu>

                            }
                        >
                            <Button type={"primary"}>{t("ACTION")}</Button>
                        </Dropdown>
                    </Row>

                    {store.invoice?.lastSyncError ? (
                        <Alert
                            showIcon
                            type="error"
                            message={"Last sync ended with an error"}
                            description={store.invoice?.lastSyncError}
                        />
                    ) : null}

                    <div className="data-descriptions-with-edit-wrapper">
                        <div className="data-content-wrapper">
                            <Descriptions
                                className="data-content-wrapper-descriptions"
                                size={"small"}
                                layout={"vertical"}
                                contentStyle={{ fontSize: "17px" }}
                                labelStyle={{ color: "#858585" }}
                                column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
                            >
                                {/*<Descriptions.Item label={t("ORDERS.ORDER.NUMBER")}>*/}
                                {/*    <Link className="link" to={"/orders/" + store.invoice?.masterOrder.id}>*/}
                                {/*        {store.invoice?.masterOrder.id}*/}
                                {/*    </Link>*/}
                                {/*</Descriptions.Item>*/}
                                {/*<Descriptions.Item*/}
                                {/*    label={t("PACKING_LIST")}*/}
                                {/*>*/}
                                {/*    {store.invoice?.mas.packingList ? store.invoice?.order.packingList.docNumber : null}*/}
                                {/*</Descriptions.Item>*/}
                                <Descriptions.Item label={t("CLIENT.NUMBER")}>
                                    <Link
                                        className="link"
                                        to={"/clients/" + store.invoice?.agent.number}
                                    >
                                        {store.invoice?.agent.number} (
                                        {store.invoice?.agent.name})
                                    </Link>
                                </Descriptions.Item>
                                <Descriptions.Item label={t("SHIPPING_WAREHOUSE")}>
                                    {store.invoice?.warehouse.name}
                                </Descriptions.Item>
                                {store.invoice?.customTotalGrossWeight ? (
                                    <Descriptions.Item label={t("CUSTOM_WEIGHT")}>
                                        {store.invoice?.customTotalGrossWeight} {t("KG")}
                                    </Descriptions.Item>
                                ) : null}
                                <Descriptions.Item label={t("DATE")}>
                                    <Date dateString={store.invoice?.date!} showTime withSeconds />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("DOCUMENT.INVOICE.DISPATCH_TYPE.TITLE")}>
                                    {t("DOCUMENT.INVOICE.DISPATCH_TYPE." + store.invoice?.dispatchType.toUpperCase())}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("DOCUMENT.INVOICE.DISPATCH_STATUS.TITLE")}>
                                    {t("INVOICES.DISPATCH." + store.invoice?.dispatchStatus.toUpperCase())}
                                </Descriptions.Item>
                                {store.invoice?.dispatchDate ? (
                                    <Descriptions.Item label={t("INVOICES.DISPATCH.DISPATCH_DATE")}>
                                        <Date dateString={store.invoice?.dispatchDate} />
                                    </Descriptions.Item>
                                ) : null}
                                <Descriptions.Item label={t("PAYMENT_TERMS")}>
                                    {store.invoice?.paymentTerms}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("SHIPMENT_CONDITION")}>
                                    {store.invoice?.shipmentCondition}
                                </Descriptions.Item>
                                {lz.py && store.invoice!.transactions!.length > 0 ? (
                                    <Descriptions.Item label={t("ORDERS.TRANSACTION_ID")}>
                                        {store.invoice?.transactions
                                            .map<React.ReactNode>(t => <>{t.reference}</>)
                                            .reduce((prev, curr) => [prev, ', ', curr])
                                        }
                                    </Descriptions.Item>
                                ) : null}
                            </Descriptions>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    className="data-description-btn btn"
                                    onClick={() => store.handleModal("edit", true)}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </Space>
            </PageHeader>
            <RecalcWeightModal
                visible={store.editWeightModalShown}
                defaultWeight={store.invoice?.totalGrossWeight}
                onClose={() => store.handleModal("editWeight", false)}
                onSubmit={store.recalcWeight}
            />
            <InvoiceEditModal
                visible={store.editModalShown}
                onClose={() => store.handleModal("edit", false)}
            />
            <InvoicesDispatchModal
                visible={store.dispatchModalShown}
                onClose={() => store.handleModal("dispatch", false)}
                invoicesToDispatch={[store.invoice!.id]}
                dispatchAware={invoiceService}
            />
            <InvoicesChangeDateModal
                invoice={store.invoice!}
                visible={store.setDateModalShown}
                onClose={() => store.handleModal("setDate", false)}
                onSuccess={() => store.reload()}
            />
            {store.sourcesListShown ? (
                <SourcesListModal
                    documentId={store.invoice!.id}
                    onClose={() => store.handleModal("sourcesList", false)}
                />
            ) : null}
            {store.wrongDeliveryModalShown ? (
                <WrongDeliveryModal
                    reason={store.wrongDeliveryReason}
                    textReason={store.wrongDeliveryTextReason}
                    onComplete={store.onUploadErrorResolveCompleted}
                    onClose={() => store.handleModal("wrongDelivery", false)}
                />
            ) : null}
            {store.invoice ? (
                <Export
                    title={"ORDERS.ORDER.DOWNLOAD_CSV_INVOICE"}
                    isShown={store.exportModalShown}
                    withBasicExportFields
                    config={{
                        link: "/api/v3/documents/invoices/" + store.invoice!.id,
                    }}
                    onClose={() => store.handleModal("export", false)}
                />
            ) : null}
        </>
    );
});
