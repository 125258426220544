import {Form, Modal, Row, Col, Input, Checkbox} from "antd";
import { AxiosError } from "axios";

import React, { useState } from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import { BranchBank } from "../../../../models/branches/branch-data";
import { ErrorResponseData } from "../../../../models/core/response";
import { useRequiredStore } from "../../../../utils/store";
import { BranchDataStoreContext } from "../branch.data.store";
import {BranchDataBanksStoreContext} from "./branch.data.banks.store";

interface BranchDataBanksModalProps {
    item?: BranchBank;
    visible: boolean;
    onClose: () => void;
}
export const BranchDataBanksModal: React.FC<BranchDataBanksModalProps> = ({
    item,
    visible,
    onClose,
}) => {
    const t = useTranslate();
    const store = useRequiredStore(BranchDataBanksStoreContext);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const defaultValues = item ? { ...item } : undefined;

    const onSubmit = item ? store.editBranchBank : store.createBranchBank;

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            title={
                item ? t("BRANCH.DATA.BANKS.EDIT") : t("BRANCH.DATA.BANKS.ADD")
            }
            okText={item ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"address_form"}
                initialValues={defaultValues}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        onSubmit(values, item!)
                            .then(
                                () => {
                                    !item && form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(
                                            e.response?.data?.fields
                                        );
                                    }
                                }
                            )
                            .then(
                                () =>
                                    // onClose(),
                                    (e: any) =>
                                        form.setFields(e.response?.data?.fields)
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="visibleName"
                            label={t("VISIBLE_NAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={t("NAME")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("NAME")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={t("NAME")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="address"
                            label={t("ADDRESS")}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={t("ADDRESS")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="iban"
                            label={"IBAN"}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={"IBAN"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="swift"
                            label={"SWIFT"}
                            rules={[
                                {
                                    required: true,
                                    message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                },
                            ]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                placeholder={"SWIFT"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="active"
                            label={t("ACTIVE")}
                            valuePropName={"checked"}
                        >
                            <Checkbox disabled={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
