import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { observer } from "mobx-react";
import React, { useState } from "react";
import {useTranslate} from "../../../../../hooks/translate.hook";
import { FilterKey } from "../../../../../models/filtered-table/filtered.table.props";
import { Amount } from "../../../../../models/prices/price";
import { displayPrice } from "../../../../../models/prices/price.helper";
import { TableThresholds } from "../../../../../models/thresholds/threshold";
import { ComponentPropsFromRoute } from "../../../../../routers/routers";
import { useRequiredStore } from "../../../../../utils/store";
import FilteredTable from "../../../../common/filtered-table/filtered.table";
import { AgentDataStoreContext } from "../../agent.data.store";
import { AgentDataThresholdsModal } from "./agent-data-thresholds-modal/agent.data.thresholds.modal";
import { AgentDataThresholdsStore, AgentDataThresholdsStoreContex } from "./agent.data.thresholds.store";

interface AgentDataThresholdsProps extends ComponentPropsFromRoute {}
export const AgentDataThresholds: React.FC<AgentDataThresholdsProps> =  observer(({ name }) => {
    const t = useTranslate();
    const agentDataStore = useRequiredStore(AgentDataStoreContext);

    const [store] = useState(
        () =>
            new AgentDataThresholdsStore(
                agentDataStore.account!.id,
                agentDataStore.accountMeta!.paidInvoices.currencyCode
            )
    );

    const columns: ColumnsType<TableThresholds> = [
        {
            title: t("YEAR"),
            dataIndex: "year",
            key: "year",
        },
        {
            title: t("THRESHOLD"),
            dataIndex: "threshold",
            key: "threshold",
            render: (item: Amount) => {
                return displayPrice(item);
            },
        },
        {
            title: t("AFTERSALE.BONUS"),
            dataIndex: "percentage",
            key: "percentage",
            render: (item: string) => item + "%",
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: 100,
            className: "table-button-cell",
            render: (_: any, record) =>
                record.year !== "" ? (
                    <>
                        <Tooltip placement="topRight" title={t("EDIT")}>
                            <Button
                                className="table-btn btn"
                                onClick={() => store.openEditModal(record)}
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                            />
                        </Tooltip>{" "}
                        <Popconfirm
                            title={t("ARE_YOU_SURE")}
                            onConfirm={() => store.deleteThreshold(record)}
                            placement="left"
                            okText={t("YES")}
                            cancelText={t("NO")}
                        >
                            <Tooltip placement="topRight" title={t("REMOVE")}>
                                <Button
                                    className="table-btn btn-remove btn"
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                />
                            </Tooltip>
                        </Popconfirm>
                    </>
                ) : null,
        },
    ];

    const filterKeys: FilterKey[] = [
        {
            key: "year",
            type: "int",
            label: t("YEAR"),
        },
    ];

    const columnsConfigs = [
        {
            title: "YEAR",
            key: "year",
        },
        {
            title: "THRESHOLD",
            key: "threshold",
        },
        {
            title: "AFTERSALE.BONUS",
            key: "percentage",
        },
    ];

    return (
        <AgentDataThresholdsStoreContex.Provider value={store}>
            <>
                <Button type="primary" onClick={store.openModal}>
                    {t("ADD")}
                </Button>
                <FilteredTable
                    // className="smart-logger-table"
                    rowKey="id"
                    columns={columns}
                    filterKeys={filterKeys}
                    columnsConfigs={{
                        columns: columnsConfigs,
                        pageKey: name,
                    }}
                    dataHandler={store.dataHandler}
                    expandable={{
                        defaultExpandAllRows: true,
                        expandIcon: () => null,
                    }}
                    //loadDataCallback={loadDataCallback}
                    // expandable={expandableConfig}
                />
                {store.modalShown ? <AgentDataThresholdsModal /> : null}
            </>
        </AgentDataThresholdsStoreContex.Provider>
    );
});
