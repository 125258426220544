import React, {useEffect, useMemo, useState} from "react";
import { observer } from "mobx-react";
import { Button, Col, DatePicker, Form, Select, Space, Typography } from "antd";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import {useTranslate} from "../../../../hooks/translate.hook";
import { ActiveInvoicesFilterButton, InvoicesListStore, InvoicesListStoreContext } from "./invoices.list.store";
import {Invoice} from "../../../../models/documents/invoices/invoice";
import { InvoicesDispatchModal } from "../invoices-dispatch-modal/invoices.dispatch.modal";
import { PaginatedRequest } from "../../../../models/core/paginated.request";
import { Export } from "../../../common/export/export";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { InvoicesExportModal } from "../invoices-export-modal/invoices.export.modal";
import { DebounceSelect } from "../../../common/debounce-select/debounce.select";
import { FieldGroupConfig } from "../../../../models/filter/filter.group";
import { FieldTypeName } from "../../../../models/filter/filter.field.type.name";
import {DocumentsMenu} from "../../../common/documents/menu/documents.menu";
import {invoiceService} from "../../../../services/documents/invoices/invoices.service";
import {convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import dayjs from "dayjs";
import {columnsFactory} from "./invoices.list.columns";

interface InvoicesListProps extends ComponentPropsFromRoute {}
export const InvoicesList: React.FC<InvoicesListProps> = observer(({ name }) => {
    const t = useTranslate();
    const {
        commonStore: { warehouses },
        authStore: { lz }
    } = useRequiredStore(RootStoreContext);
    const [store] = useState(() => new InvoicesListStore());

    const [request, setRequest] = useState<PaginatedRequest | null>(null);

    const columns = useMemo(() => columnsFactory(t, lz.py), []);

    const filterGroups: FieldGroupConfig[] = [
        {
            name: "document",
            visibleName: "INVOICES.NUMBER",
            fields: [
                {
                    name: "docNumber",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "order",
            visibleName: "ORDERS.ORDER.TITLE",
            fields: [
                {
                    name: "id",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        // {
        //   name: "packingList",
        //   visibleName: "INVOICES.PACKING_LIST_NR" ,
        //   fields: [
        //       {
        //           name: "packingListDocument",
        //           type: FieldTypeName.STRING
        //       }
        //   ]
        // },
        {
            name: "client",
            visibleName: "CLIENT.NAME",
            fields: [
                {
                    name: "clientName",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "packingList",
            visibleName: "PACKING_LIST",
            fields: [
                {
                    name: "packingList",
                    type: FieldTypeName.STRING,
                },
            ],
        },
        {
            name: "totalAmount",
            visibleName: "TOTAL_AMOUNT",
            fields: [
                {
                    name: "grandTotal",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "weight",
            visibleName: "WEIGHT_BRUTTO",
            fields: [
                {
                    name: "totalGrossWeight",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "paymentTerms",
            visibleName: "PAYMENT_TERMS",
            fields: [
                {
                    name: "clientPaymentTerms",
                    type: FieldTypeName.INTEGER,
                },
            ],
        },
        {
            name: "status",
            visibleName: "INVOICES.DISPATCH.IS_DISPATCHED",
            fields: [
                {
                    name: "isDispatched",
                    type: FieldTypeName.SELECT,
                    multiple: false,
                    choices: [
                        {
                            name: t("INVOICES.DISPATCH.DISPATCHED"),
                            value: "1",
                        },
                        {
                            name: t("INVOICES.DISPATCH.NOT_DISPATCHED"),
                            value: "0",
                        },
                    ],
                },
            ],
        },
        {
            name: "date",
            visibleName: "INVOICES.DISPATCH.DISPATCH_DATE",
            fields: [
                {
                    name: "date",
                    type: FieldTypeName.DATE_RANGE,
                },
            ],
        },
        {
            name: "warehouses",
            visibleName: "WAREHOUSES.ID",
            fields: [
                {
                    name: "warehouses",
                    type: FieldTypeName.SELECT,
                    multiple: true,
                    choices: warehouses.map(({ name, id }) => ({ value: id, name })),
                },
            ],
        },
    ];

    useEffect(() => {
        store.setRequest(request);
    }, [request]);

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: Invoice[]) => {
            store.checkInvoicesToDispatch(selectedRowKeys);
        },
        getCheckboxProps: (record: Invoice) => ({
            name: String(record.id),
            disabled: record.dispatched,
        }),
    };

    return (
        <InvoicesListStoreContext.Provider value={store}>
            <Space direction="vertical" size={[50, 50]}>
                <DocumentsMenu/>
                <Space direction="vertical" size={[0, 0]}>
                    <Typography.Title level={3}>{t("INVOICES.LIST")}</Typography.Title>
                    <Space direction={"vertical"} size={[15, 15]}>
                        <FilteredTable<Invoice, ActiveInvoicesFilterButton>
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: store.checkedInvoices,
                                ...rowSelection,
                            }}
                            columns={columns}
                            columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name }}
                            filterGroups={filterGroups}
                            //filterKeys={filters}
                            dataHandler={store.dataHandler}
                            getRequest={setRequest}
                            filterButtons={store.filterButtonConfigs}
                            buttons={[
                                <Button
                                    key={0}
                                    disabled={store.checkedInvoices.length === 0}
                                    onClick={() => store.handleModal("export", true)}
                                    type="primary"
                                >
                                    {t("ORDERS.ORDER.VIEW.GENERATE_EXPORT_INVOICE")}
                                </Button>,
                                <Button
                                    key={1}
                                    disabled={store.checkedInvoices.length === 0}
                                    onClick={() => store.handleModal("dispatch", true)}
                                    type="primary"
                                >
                                    {t("DISPATCH.DO_DISPATCH")}
                                </Button>,
                                <Button
                                    key={2}
                                    // disabled={store.checkedInvoices.length === 0}
                                    onClick={() => store.handleModal("download", true)}
                                    type="primary"
                                >
                                    {t("INVOICES.DISPATCH.DOWNLOAD")}
                                </Button>,
                            ]}
                        />
                    </Space>
                </Space>
                <InvoicesExportModal
                    visible={store.exportInvoicesModalShown}
                    onSuccess={values => store.createExportInvoices(values)}
                    onClose={() => store.handleModal("export", false)}
                />
                <InvoicesDispatchModal
                    visible={store.dispatchModalShown}
                    onClose={() => store.handleModal("dispatch", false)}
                    onInvoicesDispatched={store.onDispatched}
                    invoicesToDispatch={store.checkedInvoices}
                    dispatchAware={invoiceService}
                />
                <Export
                    title={"INVOICES.DISPATCH.DOWNLOAD"}
                    isShown={store.downloadModalShown}
                    withBasicExportFields={false}
                    onClose={() => store.handleModal("download", false)}
                    config={{
                        link: "/api/v3/invoices",
                    }}
                    additionalDefaultValues={{ date: [dayjs(), dayjs()] }}
                    prefix={null}
                >
                    <Col span={24}>
                        <Form.Item
                            name="date"
                            label={t("INVOICES.DISPATCH.DISPATCH_DATE")}
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                        >
                            <DatePicker.RangePicker
                                style={{ width: "100%" }}
                                //placeholder={t("DATE")}
                                format={store.dateFormat}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="account" label={t("CLIENT.NAME")}>
                            <DebounceSelect
                                placeholder={t("CLIENT.SEARCH")}
                                style={{ width: "100%" }}
                                fetchOptions={store.searchAgents}
                                optionsValueWithLabel={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="warehouses" label={t("WAREHOUSES.TITLE")}>
                            <Select
                                allowClear
                                mode="multiple"
                                showSearch
                                optionFilterProp={"children"}
                                placeholder={t("WAREHOUSES.TITLE")}
                            >
                                {warehouses.map(({ id, name }) => (
                                    <Select.Option key={id} value={id}>
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Export>
            </Space>
        </InvoicesListStoreContext.Provider>
    );
});
