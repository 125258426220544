import React, {useState} from "react";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../utils/store";
import {AgentDataStoreContext} from "../../agent.data.store";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {Button, Checkbox, Col, Form, Input, Row, Typography} from "antd";
import {accountService} from "../../../../../services/accounts/account.service";
import {notifications} from "../../../../../services/notifications/notifications";
import {UIInputWidgetCountry} from "../../../../common/ui/input/widget/country/ui.input.widget.country";
import {Explanation} from "../../../../common/explanation/explanation";

export const AgentDataWebsite: React.FC<ComponentPropsFromRoute> = observer(({name}) => {
    const {account} = useRequiredStore(AgentDataStoreContext);
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    if (!account) {
        return <></>;
    }

    const submit = async (values: any) => {
        setLoading(true)
        try {
            await accountService.updateWebSite(account.id, values)
            notifications.successfully()
        } catch (e: any) {
            if (e.response?.data.fields) {
                form.setFields(e.response?.data?.fields);
            }
        } finally {
            setLoading(false);
        }
    }

    let defaultValues: any = {
        displayInSite: false
    }

    const profile = account.agent.webSiteProfile;
    if (profile) {
        defaultValues = {
            displayInSite: true,
            phone: profile.phone,
            email: profile.email,
            showInCountries: profile.showInCountries.map(c => c.id),
            addressCountry: profile.country?.id,
            addressCity: profile.city,
            addressStreet: profile.address,
            addressZipCode: profile.zipCode
        }
    }

    return <Form
        size={"middle"}
        form={form}
        layout="vertical"
        name={"client_form"}
        initialValues={defaultValues}
        onFinish={async (values) => {
            await form.validateFields();
            await submit(values)
        }}
    >
        <Typography.Title level={3}>{t("CLIENT.WEBSITE.TITLE")}</Typography.Title>
        <Row gutter={[30, 0]} align={"top"}>
            <Col span={12}>
                <Row gutter={[30, 0]} align={"top"}>
                    <Col span={24}>
                        <Form.Item name={["displayInSite"]} valuePropName={"checked"}>
                            <Checkbox>
                                <Explanation
                                    title={t("CLIENT.DISPLAY_IN_SITE")}
                                    tooltip={t("CLIENT.DISPLAY_IN_SITE_EXPL")}
                                />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Form.Item
                        shouldUpdate={(v1, v2) => v1.displayInSite !== v2.displayInSite}
                        noStyle
                    >
                        {() => (
                            <>
                                {form.getFieldValue('displayInSite') ? (
                                    <>
                                        <Col span={24}>
                                            <Form.Item name={["phone"]} label={t("PHONE")}>
                                                <Input disabled={loading} type="tel" placeholder={t("PHONE")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={["email"]} label={t("EMAIL")}>
                                                <Input disabled={loading} type="email" placeholder={t("EMAIL")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={["showInCountries"]} label={t("SITE_COUNTRY")}>
                                                <UIInputWidgetCountry multiple={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={["addressCountry"]} label={t("COUNTRY")}>
                                                <UIInputWidgetCountry multiple={false} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={["addressCity"]} label={t("CITY")}>
                                                <Input disabled={loading} placeholder={t("CITY")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={["addressStreet"]} label={t("ADDRESS")}>
                                                <Input disabled={loading} placeholder={t("CITY")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={["addressZipCode"]} label={t("INDEX")}>
                                                <Input disabled={loading} placeholder={t("INDEX")} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                ) : null}
                            </>
                        )}
                    </Form.Item>
                </Row>
            </Col>
        </Row>
        <Row gutter={[30, 0]} align={"top"}>
            <Col span={24}>
                <Button type={"primary"} onClick={() => form.submit()}>{t("SAVE")}</Button>
            </Col>
        </Row>
    </Form>
});
