import React, {useEffect} from "react";
import {Space} from "antd";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router";
import {ComponentPropsFromRoute} from "../../../../../routers/routers";
import {RouteTabs} from "../../../../common/route-tabs/route.tabs";

export const ShipmentsWholesaleMain: React.FC<ComponentPropsFromRoute> = ({ name, routeChildren }) => {

    const location = useLocation();
    const navigate  = useNavigate();

    useEffect(() => {
        if (location.pathname === "/shipments/wholesale") {
            navigate("/shipments/wholesale/ready-to-ship")
        }
    }, [location.pathname])

    return (
        <Space direction="vertical" size={[0, 0]}>
            <RouteTabs routes={routeChildren!} withoutRootTabPath />
        </Space>
    )
}