import React, {ReactElement} from "react";
import {Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ExplanationProps {
    title: string;
    tooltip: string | ReactElement;
}

export const Explanation: React.FC<ExplanationProps> = ({title, tooltip}) => {
    return (
        <span>
          {title}
            <sup>
              <Tooltip title={tooltip}>
                  <FontAwesomeIcon icon={["fas", "info-circle"]} style={{marginLeft: 6, fontSize: '12px', color: '#1677ff'}} />
              </Tooltip>
            </sup>
        </span>
)
}