import React, {createRef, useState} from "react";
import {observer} from "mobx-react";
import {Col, Descriptions, Row, Space, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {ArticleImageModal} from "../article-data-image-modal/modal/article.image.modal";
import placeholder from "./placeholder.png";
import "./article.data.header.scss";
import {PageHeader} from "@ant-design/pro-components";
import {useNavigate} from "react-router";
import {useArticle} from "../article.context";

export const ArticleDataHeader: React.FC = observer(() => {
    const t = useTranslate();
    const navigate = useNavigate();
    const imageRef = createRef<HTMLImageElement>();
    const schemaImageRef = createRef<HTMLImageElement>();

    const {article} = useArticle();
    const [openedImageLink, setOpenedImageLink] = useState<string| null>(null);

    return (
        <>
            <PageHeader
                style={{padding: 0}}
                ghost={false}
                title={<Typography.Title style={{margin: 0}}>{article.name}</Typography.Title>}
                subTitle={article.ean === "0" || !article.ean ? null : article.ean}
                onBack={() => {
                    navigate("/articles");
                }}
            >
                <Row align="middle" justify="space-between" gutter={[30, 0]}>
                    <Col style={{display: "flex"}} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                        <Space direction={"horizontal"} size={[65, 65]} wrap>
                            <img
                                className={"article-image"}
                                ref={imageRef}
                                src={"/product_photos/200/photos/" + article.name + ".jpg"}
                                alt={article.description}
                                onError={() => imageRef.current!.src = placeholder}
                                onClick={() => {
                                    if (!imageRef.current?.src.includes("placeholder")) {
                                        setOpenedImageLink("/product_photos/1600/photos/" + article!.name + ".jpg")
                                    }
                                }}
                            />
                            <img
                                className={"article-image"}
                                ref={schemaImageRef}
                                src={"/product_photos/200/schemes/" + article.name + ".png"}
                                alt={article.description}
                                onError={() => schemaImageRef.current!.src = placeholder}
                                onClick={() => {
                                    if (!schemaImageRef.current?.src.includes("placeholder")) {
                                        setOpenedImageLink("/product_photos/640/schemes/" + article!.name + ".png");
                                    }
                                }}
                            />
                        </Space>
                    </Col>
                    {article.dimensions ? (
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                            <Descriptions
                                size={"small"}
                                layout={"horizontal"}
                                labelStyle={{color: "#858585"}}
                                column={{xs: 1, sm: 2, md: 3, lg: 3, xl: 2, xxl: 2}}
                            >
                                <Descriptions.Item
                                    label={t("HEIGHT")}>{article!.dimensions!.height}</Descriptions.Item>
                                <Descriptions.Item
                                    label={t("LENGTH")}>{article!.dimensions!.length}</Descriptions.Item>
                                <Descriptions.Item
                                    label={t("WIDTH")}>{article!.dimensions!.width}</Descriptions.Item>
                                <Descriptions.Item
                                    label={t("WEIGHT")}>{article!.dimensions!.weight} ({t("KG")})</Descriptions.Item>
                                <Descriptions.Item
                                    label={t("NETTO_WEIGHT")}>{article!.dimensions!.nettoWeight} ({t("KG")})</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    ) : null}
                </Row>
            </PageHeader>
            {openedImageLink ? (
                <ArticleImageModal
                    opened={true}
                    onClose={() => setOpenedImageLink(null)}
                >
                    <img
                        className="modal-image"
                        style={{maxWidth: "90%", maxHeight: "90%"}}
                        alt={article ? article.name : ""}
                        src={openedImageLink}
                    />
                </ArticleImageModal>
            ) : null}
        </>
    );
});
