import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {useParams} from "react-router-dom";
import {shipmentsService} from "../../../services/shipments/shipments.service";
import {Shipment} from "../../../models/parcels/shipment";
import {LoadingSpin} from "../../common/loading-spin/loading.spin";
import {ShipmentHeader} from "./shipment-header";
import {ShipmentParcels} from "./parcels/shipment.parcels";
import {Space} from "antd";
import {ShipmentLogger} from "./shipment-logger";
import {notifications} from "../../../services/notifications/notifications";
import {ShipmentSmartLogger} from "./shipment-smart-logger";
import {ShipmentIncludedInvoices} from "./included-invoices/shipment.included-invoices";
import {ShipmentConsigneeData} from "../common/consignee-data/shipment.consignee-data";
export const ShipmentsView: React.FC<ComponentPropsFromRoute> = () => {

    const {id} = useParams<{id: string}>();

    const [shipment, setShipment] = useState<Shipment>();

    const reload = () => {
        shipmentsService.getShipment(id!).then(shipment => setShipment(shipment));
    }

    useEffect(() =>  {
        reload();
    }, [])

    if (!shipment) {
        return <LoadingSpin />
    }

    const onConsignorUpdate = async (_: any, data: any): Promise<any> => {
        await shipmentsService.updateConsignor(id!, data);
        reload();
        notifications.successfully();
    }

    const onConsigneeUpdate = async (_: any, data: any): Promise<any> => {
        await shipmentsService.updateConsignee(id!, data);
        reload();
        notifications.successfully();
    }

    return (
        <Space direction={"vertical"} size={[40, 40]}>
            <ShipmentHeader shipment={shipment} reload={reload} />
            <ShipmentConsigneeData consignor={shipment.consignor} consignee={shipment.consignee} onConsignorUpdate={onConsignorUpdate} onConsigneeUpdate={onConsigneeUpdate} />
            <ShipmentIncludedInvoices shipment={shipment} />
            <ShipmentParcels shipment={shipment} reload={reload} />
            <ShipmentLogger shipment={shipment} />
            <ShipmentSmartLogger shipment={shipment} />
        </Space>
    )
}