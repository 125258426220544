import React from "react";
import {Translate} from "../../../../../hooks/translate.hook";
import {ColumnsType} from "antd/es/table";
import {ArticleVehicleModelVariant} from "../../../../../models/articles/article.data";

export const columnsFactory = (t: Translate): ColumnsType<ArticleVehicleModelVariant> => {
    return [
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("YEARS"),
            dataIndex: "years",
            key: "years",
            width: "130px",
            render: (item: string) => <span style={{whiteSpace: "nowrap"}}>{item}</span>
        },
        {
            title: t("ARTICLES.SUBMODEL"),
            dataIndex: "engine",
            key: "engine",
        },
        {
            title: t("ARTICLES.FUEL_TYPE"),
            dataIndex: "fuelType",
            key: "fuelType"
        }
    ];
}