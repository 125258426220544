import React, {useEffect, useState} from "react";
import {Button, Col, Descriptions, Row, Space, Typography} from "antd";
import {useArticle} from "../../../article.context";
import {useRequiredStore} from "../../../../../../utils/store";
import {RootStoreContext} from "../../../../../../stores/root/root.store";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {ArticlesStatus} from "../../../../../../models/articles/articles.status";
import {articlesStatusService} from "../../../../../../services/articles/articles/articles.status.service";
import {YesNo} from "../../../../../common/yes-no/yes-no";
import {isAllowed} from "../../../../../../utils/helpers";

export const ArticleDataStatus: React.FC = () => {
    
    const {article} = useArticle();
    const t = useTranslate();

    const {
        authStore: { attributes },
    } = useRequiredStore(RootStoreContext);

    const [status, setStatus] = useState<ArticlesStatus | null >();

    const reload = () => {
        articlesStatusService.getStatus(article.name).then(status => setStatus(status))
    }

    const hideForClient = (): void => {
        articlesStatusService.hideForClient(article.name).then(() => reload());
    }

    const showForClient = (): void => {
        articlesStatusService.showForClient(article.name).then(() => reload());
    }

    const hideForStatistic = (): void => {
        articlesStatusService.hideForStatistic(article.name).then(() => reload());
    }

    const showForStatistic = (): void => {
        articlesStatusService.showForStatistic(article.name).then(() => reload());
    }

    const hideForCatalog = (): void => {
        articlesStatusService.hideForCatalog(article.name).then(() => reload());
    }

    const showForCatalog = (): void => {
        articlesStatusService.showForCatalog(article.name).then(() => reload());
    }

    const hidePermanentlyForCatalog = (): void => {
        articlesStatusService.hidePermanentlyForCatalog(article.name).then(() => reload());
    }

    const showPermanentlyForCatalog = (): void => {
        articlesStatusService.showPermanentlyForCatalog(article.name).then(() => reload());
    }

    useEffect(() => {
        reload();
    }, [article.name])

    if (!status) {
        return null;
    }

    return (
        <Space direction={"vertical"} size={0}>
            <Typography.Title level={3}>{t("ARTICLES.STATUS.TITLE")}</Typography.Title>
            {article.attributes.length ? (

                <>
                    <Row gutter={[20, 20]}>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Descriptions
                                bordered
                                size={"middle"}
                                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                contentStyle={{height: 40}}
                            >
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_TRANSLATE_SET")}>
                                    <YesNo value={status.isTranslateSet} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_DESCRIPTION_SET")}>
                                    <YesNo value={status.isDescriptionSet} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_OEM_SET")}>
                                    <YesNo value={status.isOemSet} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_EAN_SET")}>
                                    <YesNo value={status.isEanSet} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_CARS_SET")}>
                                    <YesNo value={status.isCarsSet} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_HS_SET")}>
                                    <YesNo value={status.isHsSet} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_PRICE_SET")}>
                                    <YesNo value={status.isPriceSet} />
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Descriptions
                                bordered
                                size={"middle"}
                                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                contentStyle={{height: 40}}
                            >
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_TEMPORARY_HIDDEN")}>
                                    Global: <YesNo value={status.isTemporaryHidden} /> / Branch: <YesNo value={status.isBranchTemporaryHidden} />
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_PERMANENTLY_HIDDEN")}>
                                    <Space direction={"horizontal"}>
                                        <YesNo value={status.isPermanentlyHidden} />
                                        {isAllowed(attributes, "article_global-view-status_management") ? (
                                            <>
                                                {status.isPermanentlyHidden ? (
                                                    <Button
                                                        size={"small"}
                                                        type={"primary"}
                                                        disabled={status.isTemporaryHidden}
                                                        onClick={showPermanentlyForCatalog}
                                                    >{t("SHOW")}</Button>
                                                ) : (
                                                    <Button
                                                        size={"small"}
                                                        type={"primary"}
                                                        onClick={hidePermanentlyForCatalog}
                                                    >{t("HIDE")}</Button>
                                                )}
                                            </>
                                        ) : null }
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_CATALOG_HIDDEN")}>
                                    <Space direction={"horizontal"}>
                                        <YesNo value={status.isManuallyCatalogHidden} />
                                        {isAllowed(attributes, "article_global-view-status_management") ? (
                                            <>
                                                {status.isManuallyCatalogHidden ? (
                                                    <Button
                                                        size={"small"}
                                                        type={"primary"}
                                                        disabled={status.isTemporaryHidden}
                                                        onClick={showForCatalog}
                                                    >{t("SHOW")}</Button>
                                                ) : (
                                                    <Button
                                                        size={"small"}
                                                        type={"primary"}
                                                        onClick={hideForCatalog}
                                                    >{t("HIDE")}</Button>
                                                )}
                                            </>
                                        ) : null }
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_CLIENT_HIDDEN")}>
                                    <Space direction={"horizontal"}>
                                        <YesNo value={status.isManuallyClientHidden} />
                                        {isAllowed(attributes, "article_view-status_management") ? (
                                            <>
                                                {status.isManuallyClientHidden ? (
                                                    <Button
                                                        size={"small"}
                                                        type={"primary"}
                                                        disabled={status.isTemporaryHidden}
                                                        onClick={showForClient}
                                                    >{t("SHOW")}</Button>
                                                ) : (
                                                    <Button
                                                        size={"small"}
                                                        type={"primary"}
                                                        onClick={hideForClient}
                                                    >{t("HIDE")}</Button>
                                                )}
                                            </>
                                        ) : null}
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_STATISTIC_HIDDEN")}>
                                    <Space direction={"horizontal"}>
                                        <YesNo value={status.isStatisticHidden} />
                                        {isAllowed(attributes, "article_view-status_management") ? (
                                            <>
                                                {status.isStatisticHidden ? (
                                                    <Button
                                                        size={"small"}
                                                        type={"primary"}
                                                        disabled={status.isTemporaryHidden}
                                                        onClick={showForStatistic}
                                                    >{t("SHOW")}</Button>
                                                ) : (
                                                    <Button
                                                        size={"small"}
                                                        type={"primary"}
                                                        onClick={hideForStatistic}
                                                    >{t("HIDE")}</Button>
                                                )}
                                            </>
                                        ) : null}
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item label={t("ARTICLES.STATUS.IS_TECDOC_HIDDEN")}>
                                    <YesNo value={status.isTecDocHidden} />
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </>
            ) : t("NO_DATA")}
        </Space>
    );
}