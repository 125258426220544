import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Input, Row, Space, Tooltip, Typography} from "antd";
import {AgentNewContext} from "../../agent.new.modal";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SystemAccesses} from "../../../../../users/users/system-accesses/system.accesses";
import {setGeneratedPassword} from "../../../agent-data-accounts/agent.data.accounts.modal";
import {observer} from "mobx-react";

export const AccountStep: React.FC = observer(() => {

    const {setStep ,setValue, getValue, onComplete, fields} = React.useContext(AgentNewContext);

    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFields(fields);
    }, [fields])

    let initialValues: any = {}
    const value = getValue('user');
    if (value) {
        initialValues = {systemAccesses: value.systemAccesses, user: {user: value.user}}
    } else {
        const addressValues = getValue('billingAddress');
        if (addressValues) {
            initialValues = {
                systemAccesses: [{system: "fms_ws"}],
                user: {
                    user: {
                        firstName: addressValues.firstName,
                        lastName: addressValues.lastName,
                        email: addressValues.email
                    }
                }
            }
        }
    }

    return <Space direction="vertical" size={[15, 15]} style={{marginTop: 40}}>
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFinish={async (values) => {
                await form.validateFields();
                setValue('user', {systemAccesses: values.systemAccesses, user: values.user.user})
                setLoading(true);
                try {
                    setLoading(true)
                    await onComplete()
                } finally {
                    setLoading(false)
                }
            }}
        >
            <Typography.Title level={3}>{"Account"}</Typography.Title>
            <Row>
                <Col span={12}>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                name={["user", "user", "firstName"]}
                                label={t("FIRSTNAME")}
                                rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")}/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name={["user", "user", "lastName"]}
                                label={t("LASTNAME")}
                                rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                            >
                                <Input disabled={loading} placeholder={t("LASTNAME")} type={"text"}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={["user", "user", "email"]}
                                label={t("EMAIL")}
                                rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                            >
                                <Input disabled={loading} placeholder={t("EMAIL")} type={"text"}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={["user", "user", "password"]}
                                label={t("PASSWORD.TITLE")}
                            >
                                <Input.Password
                                    disabled={loading}
                                    placeholder={t("PASSWORD.EDIT")}
                                    allowClear
                                    addonAfter={
                                        <Tooltip title={t("PASSWORD.GENERATE")}>
                                            <FontAwesomeIcon onClick={() => setGeneratedPassword(form, ["user", "user", "password"])}
                                                             style={{cursor: "pointer"}} icon={["fas", "dice"]}/>
                                        </Tooltip>
                                    }
                                    iconRender={visible => (
                                        visible ? (
                                            <Tooltip title={t("PASSWORD.HIDE")}>
                                                <FontAwesomeIcon style={{marginLeft: "3px"}} icon={["fas", "eye-slash"]}/>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={t("PASSWORD.SHOW")}>
                                                <FontAwesomeIcon style={{marginLeft: "3px"}} icon={["fas", "eye"]}/>
                                            </Tooltip>
                                        )
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item noStyle
                                       shouldUpdate={(prevValues, nextValues) => prevValues.password !== nextValues.password}>
                                {({getFieldValue}) => getFieldValue(["user", "user", "password"]) ? (
                                    <Form.Item name={["user", "user", "sendPassword"]} valuePropName={"checked"}>
                                        <Checkbox disabled={loading}>{t("PASSWORD.SEND_PASSWORD")}</Checkbox>
                                    </Form.Item>
                                ) : null}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={5}>{t("USER.PROFILE.SYSTEM_ACCESSES")}</Typography.Title>
                        </Col>
                        <Col span={24}>
                            <SystemAccesses
                                loading={loading}
                                form={form}
                                type={"wholesale"}
                                defaultSelectedSystems={[]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[30, 0]} align={"top"}>
                        <Col span={24}>
                            <Space direction={"horizontal"} size={[10, 10]}>
                                <Button disabled={loading} type={"default"} onClick={() => setStep(5)}>Back</Button>
                                <Button disabled={loading} type={"primary"} onClick={() => form.submit()}>Complete</Button>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    </Space>
});