export interface Config {
    useLoadUnload: boolean;
    useProduct: boolean;
    useIncoterms: boolean;
    usePickupDate: boolean;
    useAutoSelectNearestOffice?: boolean;
    products?: Record<string, string>
    courierServicePayer?: Record<string, string>
}

export const config: Record<string, Config> = {
    ace: {
        useLoadUnload: true,
        useProduct: true,
        useIncoterms: true,
        usePickupDate: false,
        products: {
            aceFlex:    'aceFlex',
            aceFix:     'aceFix',
            aceSpeed:   'aceSpeed',
            aceSpeed10: 'aceSpeed10',
            aceSpeed12: 'aceSpeed12',
            aceSpecial: 'aceSpecial',
            aceLTL:     'aceLTL',
            aceB2C:     'aceB2C',
            aceB2CPlus: 'aceB2CPlus'
        }
    },
    dhl: {
        useLoadUnload: false,
        useProduct: true,
        useIncoterms: false,
        usePickupDate: false,
        products: {
            express       : 'Express',
            express_1200  : 'Express 12:00',
            economy       : 'Economy',
        }
    },
    venipack: {
        useLoadUnload: false,
        useProduct: true,
        useIncoterms: false,
        usePickupDate: false,
        products: {
            tswd     : 'delivery the same working day',
            tswd17   : 'delivery the same working day 17:00-22:00',
            nwd      : 'delivery next working day',
            nwd10    : 'delivery next working day till 10:00 (express!)',
            nwd12    : 'delivery next working day till 12:00',
            nwd8_14  : 'delivery next working day 8:00-14:00',
            nwd14_17 : 'delivery next working day 14:00-17:00',
            nwd18_22 : 'delivery next working day 18:00-22:00',
            nwd18a   : 'delivery next working day after 18:00',
            sat      : 'delivery on saturday'
        }
    },
    speedy: {
        useLoadUnload: false,
        useProduct: true,
        useIncoterms: false,
        usePickupDate: true,
        useAutoSelectNearestOffice: true,
        products: {
            "202"  : 'Speedy Cee Economy',
            "442"  : 'PALLET ONE GR',
            "704"  : 'ГУМИ',
            "992"  : 'ТРАНСБАЛКАН - ЕКСПРЕС',
            "993"  : 'ТРАНСБАЛКАН - КАРГО',
            "707"  : 'TYRES BALKANS',
            "302"  : 'AIR EXPRESS',
            "432"  : 'PALLET ONE RO',
            "306"  : 'DPD ECONOMY',
            "310"  : 'DPD CLASSIC (GREECE)',
            "312"  : 'DPD CEE ECONOMY (GREECE)',
            "505"  : 'СТАНДАРТ 24 ЧАСА',
            "411"  : 'PALLET ONE BG - 6 ЧАСА',
            "412"  : 'PALLET ONE BG - PREMIUM',
            "413"  : 'PALLET ONE BG - ECONOMY',
        },
        courierServicePayer: {
            "SENDER": "Sender (FEBEST)",
            "RECIPIENT": "Recipient (Customer)"
        }
    },
    tnt: {
        useLoadUnload: false,
        useProduct: true,
        useIncoterms: false,
        usePickupDate: false,
        products: {
            "12N"   : '12:00 Express',
            "15N"   : 'Express',
            "48N"   : 'Economy Express'
        }
    },
    fedex: {
        useLoadUnload: false,
        useProduct: true,
        useIncoterms: false,
        usePickupDate: false,
        products: {
            "FEDEX_INTERNATIONAL_PRIORITY_EXPRESS"  : 'FedEx International Priority® Express',
            "FEDEX_INTERNATIONAL_PRIORITY"          : 'FedEx International Priority®',
            "INTERNATIONAL_PRIORITY_FREIGHT"        : 'FedEx International Priority® Freight',
            "INTERNATIONAL_ECONOMY"                 : 'FedEx International Economy®',
            "INTERNATIONAL_ECONOMY_FREIGHT"         : 'FedEx International Economy® Freight',
            "FEDEX_REGIONAL_ECONOMY"                : 'FedEx® Regional Economy'
        }
    },
}