import React, {useMemo, useState} from "react";
import {useArticle} from "../../article.context";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {Button, Space, Typography} from "antd";
import {columnsFactory} from "./restrictions.columns";
import {SmartTable} from "../../../../common/smart-table/smart-table";
import {SmartTableDataHandler} from "../../../../common/smart-table/smart-table.data-handler";
import {articleRestrictionsService} from "../../../../../services/articles/articles/article.restrictions.service";
import {downloadBlobFile} from "../../../../../utils/blob.download";
import {UploadModal} from "./upload/upload.modal";

export const ArticleDataRestrictions: React.FC = () => {

    const {article} = useArticle();
    const t = useTranslate();
    const columns = columnsFactory(t);
    const dataHandler = useMemo(() => new SmartTableDataHandler(r => articleRestrictionsService.getAll(article.id, r)), [])
    const [loading, setLoading] = useState<boolean>(false);
    const [modalShown, setModalShown] = useState<boolean>(false);

    const downloadAsCSV = async () => {
        try {
            setLoading(true);
            const {blob, filename} = await articleRestrictionsService.downloadAsCSV(article.id)
            downloadBlobFile(blob, filename);
        } finally {
            setLoading(false);
        }
    }

    const clearAll = async () => {
        try {
            setLoading(true);
            await articleRestrictionsService.clearAll(article.id)
            await dataHandler.reloadItemsWithLastRequest();
        } finally {
            setLoading(false);
        }
    }

    return (
        <Space direction={"vertical"} size={[20, 20]}>
            <Typography.Title level={3}>{t("ARTICLES.ARTICLE.RESTRICTIONS")}</Typography.Title>
            <Space direction={"horizontal"}>
                <Button
                    type={"primary"}
                    onClick={downloadAsCSV}
                    disabled={loading}
                >Download as CSV</Button>
                <Button
                    type={"primary"}
                    onClick={() => setModalShown(true)}
                    disabled={loading}
                >Upload CSV</Button>
                <Button
                    danger
                    onClick={clearAll}
                    disabled={loading}
                >Clear All</Button>
            </Space>
            <SmartTable
                handler={dataHandler}
                columns={columns}
                />
            {modalShown ? (
                <UploadModal
                    onCancel={() => setModalShown(false)}
                    onComplete={() => dataHandler.reloadItemsWithLastRequest()}
                    article={article}
                    />
            ) : null}
        </Space>
    );
}