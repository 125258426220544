import React from "react";
import {observer} from "mobx-react";
import {Col, Row, Typography} from "antd";
import {
    PayerOrReceiverDetails,
    PayerReceiverFormValues
} from "../../../../models/payer-receiver-seller/payer.reveiver.seller";
import {useTranslate} from "../../../../hooks/translate.hook";
import {PayerReceiverCard} from "../../../common/payer-seller-receiver-data/cards/payer.receiver.card";

interface PayerSellerReceiverDataProps {
    consignor: PayerOrReceiverDetails,
    consignee: PayerOrReceiverDetails,
    onConsignorUpdate: (type: "payer" | "receiver", values: PayerReceiverFormValues) => Promise<void>
    onConsigneeUpdate: (type: "payer" | "receiver", values: PayerReceiverFormValues) => Promise<void>
}

export const ShipmentConsigneeData: React.FC<PayerSellerReceiverDataProps> = observer(({
                                                                                   consignor,
                                                                                   consignee,
                                                                                   onConsignorUpdate,
                                                                                   onConsigneeUpdate
                                                                                         }) => {
    const t = useTranslate();
    return (
        <>
            <Typography.Title level={3}>{t("SHIPMENTS.CONSIGNOR_AND_CONSIGNEE.TITLE")}</Typography.Title>
            <Row gutter={[30, 30]}>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <PayerReceiverCard
                        type={"receiver"}
                        title={"SHIPMENTS.CONSIGNOR.TITLE"}
                        data={consignor}
                        editPayerReceiver={onConsignorUpdate}
                        withAddressType={true}
                    />
                </Col>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <PayerReceiverCard
                        type={"receiver"}
                        title={"SHIPMENTS.CONSIGNEE.TITLE"}
                        data={consignee}
                        editPayerReceiver={onConsigneeUpdate}
                        withAddressType={true}
                    />
                </Col>
            </Row>
        </>
    );
});
