import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {WarehousesTaxesRequest, WarehouseTaxes} from "../../../../models/warehouses/warehouses.taxes";
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tooltip, Typography} from "antd";
import {useTranslate} from "../../../../hooks/translate.hook";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {useRequiredStore} from "../../../../utils/store";
import {RootStoreContext} from "../../../../stores/root/root.store";
import {Country} from "../../../../models/countries/country";
import {NumberInput} from "../../../common/number-input/number.input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Konto} from "../../../../models/konto/konto";
import {kontoService} from "../../../../services/konto/konto.service";
import dayjs from "dayjs";

interface WarehousesTaxesModalProps {
    item?: WarehouseTaxes
    countries: Country[],
    types: string[],
    visible: boolean
    onSuccess: (data: WarehousesTaxesRequest, item?: WarehouseTaxes) => Promise<void>
    onClose: () => void
}

export const WarehousesTaxesModal: React.FC<WarehousesTaxesModalProps> = observer((
    {
        item,
        countries,
        types,
        visible,
        onSuccess,
        onClose
    }) => {
    const {commonStore: {warehouses}} = useRequiredStore(RootStoreContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const t = useTranslate();

    const [konto, setKonto] = useState<Konto[]>([]);
    useEffect(() => {
        if (visible) {
            kontoService.getAll().then(konto => setKonto(konto));
        }
    }, [visible])

    const defaultValues: WarehousesTaxesRequest | Partial<WarehousesTaxesRequest> = item ? {
        warehouseCountry: item.warehouseCountry.id,
        country: item.country ? item.country.id : undefined,
        vatNumber: item.vatNumber,
        taxCoefficient: item.taxCoefficient,
        type: item.type,
        kontos: item.kontos.map(v => ({
            minPrice: parseFloat(v.minPrice),
            maxPrice: parseFloat(v.maxPrice),
            konto: v.konto.id
        })),
        startedAt: dayjs(item.startedAt),
        endedAt: dayjs(item.endedAt)
    } : {country: null};


    return (
        <Modal
            visible={visible}
            title={item ? t("WAREHOUSES.TAXES.EDIT") : t("WAREHOUSES.TAXES.CREATE")}
            okText={item ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
            onOk={() => form.submit()}
            confirmLoading={loading}
            width={500}
        >
            <Form
                layout="vertical"
                form={form}
                initialValues={defaultValues}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        values.kontos?.map((k: any, i: number) => {
                            values.kontos[i].minPrice = parseFloat(k.minPrice)
                            values.kontos[i].maxPrice = parseFloat(k.maxPrice)
                        })
                        setLoading(true);
                        onSuccess(values, item).then(
                            () => {
                                !item && form.resetFields();
                                return onClose();
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                            .then(() => {
                            }, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >

                <Form.Item
                    name="warehouseCountry"
                    label={t("WAREHOUSES.COUNTRY")}
                >
                    <Select
                        showSearch
                        optionFilterProp={"children"}
                        disabled={loading}
                        placeholder={t("COUNTRY_SELECT")}
                    >
                        {countries.map(({id, name}) => (
                            <Select.Option
                                key={id as string}
                                value={id as string}
                            >
                                {name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="country"
                    label={t("COUNTRIES")}
                >
                    <Select
                        showSearch
                        optionFilterProp={"children"}
                        disabled={loading}
                        placeholder={t("COUNTRY_SELECT")}
                    >
                        {countries.map(({id, name}) => (
                            <Select.Option
                                key={id as string}
                                value={id as string}
                            >
                                {name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="vatNumber"
                    label={"VAT"}
                >
                    <Input type="text" placeholder={"VAT"} disabled={loading}/>
                </Form.Item>
                <Form.Item
                    name="taxCoefficient"
                    label={t("TAX.COEFFICIENT")}
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                >
                    <Input type="text" placeholder={t("TAX.COEFFICIENT")} disabled={loading}/>
                </Form.Item>
                <Form.Item
                    name="type"
                    label={t("CLIENT.TYPE")}
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                >
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp={"children"}
                        disabled={loading}
                        placeholder={t("CLIENT.TYPE")}
                    >
                        {types.map(type => (
                            <Select.Option key={type} value={type}>{type}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Typography.Paragraph style={{marginBottom: 8}}>{t("KONTO.TITLE")}</Typography.Paragraph>
                <Form.List
                    name={"kontos"}
                >
                    {(fields, {add, remove}, {errors}) => (
                        <>
                            {fields.map((field, index) => (
                                <Row justify={"space-between"} gutter={5} key={field.key}>
                                    <Col span={14}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(oldValue, newValue) => oldValue.changeNumberEnabled !== newValue.changeNumberEnabled}
                                        >
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "konto"]}
                                                    
                                                    rules={[{
                                                        required: true,
                                                        message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                                    }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        disabled={false}
                                                        placeholder={t("KONTO.TITLE")}
                                                    >
                                                        {konto.map(item => (
                                                            <Select.Option
                                                                key={item.id}
                                                                value={item.id}
                                                                disabled={false}
                                                            >
                                                                {item.productsKonto} / {item.deliveryKonto}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "minPrice"]}
                                            
                                            rules={[{
                                                required: true,
                                                message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                            }]}
                                        >
                                            <NumberInput placeholder={t("PRICES.MINIMAL_PRICE")}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "maxPrice"]}
                                            
                                            rules={[{
                                                required: true,
                                                message: t('FROM.ERROR.PLEASE_SELECT_VALUE')
                                            }]}
                                        >
                                            <NumberInput placeholder={t("PRICES.MAXIMUM_PRICE")}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Tooltip placement="topRight" title={t('REMOVE')}>
                                            <Button
                                                type="default"
                                                danger
                                                onClick={() => remove(field.name)}
                                                icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                                style={{width: "100%"}}
                                                className="btn-flex-center"
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add({minPrice: 0, maxPrice: 0});
                                    }}
                                    block
                                    icon={<FontAwesomeIcon icon={["fas", "plus-circle"]}/>}
                                >
                                    {t("KONTO.ADD")}
                                </Button>
                                <Form.ErrorList errors={errors}/>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item
                    name="startedAt"
                    label={t("TAX.RULE.STARTED_AT")}
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                >
                    <DatePicker disabled={loading}/>
                </Form.Item>
                <Form.Item
                    name="endedAt"
                    label={t("TAX.RULE.ENDED_AT")}
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                >
                    <DatePicker disabled={loading}/>
                </Form.Item>
            </Form>
        </Modal>
    );
});
