import React, {useState} from "react";
import {useTranslate} from "../../../../hooks/translate.hook";
import {Col, DatePicker, Form, Modal, Row} from "antd";
import {AxiosError} from "axios";
import {shipmentsService} from "../../../../services/shipments/shipments.service";
import {ErrorResponseData} from "../../../../models/core/response";
import dayjs from "dayjs";
import {Shipment} from "../../../../models/parcels/shipment";
import {downloadBlobFile} from "../../../../utils/blob.download";

interface ConfirmModalProps {
    shipment: Shipment,
    onClose: () => void,
}
export const DownloadExclusiveLabelsModal: React.FC<ConfirmModalProps> = ({shipment, onClose}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const dateFormat: string = "DD-MM-YYYY";

    return (
        <Modal
            visible={true}
            confirmLoading={loading}
            title={t("SHIPMENTS.DOWNLOAD-EXCLUSIVE-LABELS.TITLE")}
            onOk={() => form.submit()}
            centered
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"merged_invoice_confirm_modal"}
                initialValues={{
                    date: dayjs()
                }}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        shipmentsService.downloadExclusiveLabels(shipment.id, dayjs(values.date).format("YYYY-MM-DD")).then(({blob, filename}) => {
                            downloadBlobFile(blob, filename)
                            form.resetFields();
                            return onClose();
                        }, (e: AxiosError<ErrorResponseData>) => {
                            if (e.response?.data.fields) {
                                form.setFields(e.response?.data?.fields);
                            }
                        })
                            .then(() =>
                                    onClose(),
                                (e: any) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="date"
                            label={t("DATE")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <DatePicker
                                disabled={loading}
                                style={{width: "100%"}}
                                placeholder={t("DATE")}
                                format={dateFormat}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
