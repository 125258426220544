import React, {PropsWithChildren, useEffect, useState} from "react";
import {Button, Form, FormInstance, message, Modal, Row, Space} from "antd";
import {useTranslate} from "../../../hooks/translate.hook";
import { ExportBasicFields } from "./basic-fields/export.basic.fields";
import { ExportStore } from "./export.store";
import { useRequiredStore } from "../../../utils/store";
import { RootStoreContext } from "../../../stores/root/root.store";
import { ExportFormat } from "../../../stores/common/common.store";

interface ExportProps {
    title: string;
    isShown: boolean;
    withBasicExportFields: boolean;
    config: {
        link: string;
        query?: { [k: string]: any };
    };
    onClose: () => void;
    onComplete?: (link: string) => void;
    hasFiltersParams?: boolean;
    additionalDefaultValues?: { [k: string]: any };
    allowedFormats?: ExportFormat[];
    prefix?: string | null;
    customForm?: FormInstance<any>;
}

export const Export: React.FC<PropsWithChildren<ExportProps>> = ({
    title,
    isShown,
    onClose,
    onComplete,
    withBasicExportFields = true,
    config,
    additionalDefaultValues = {},
    allowedFormats,
    hasFiltersParams,
    prefix = "export",
    children,
    customForm
}) => {
    const { commonStore } = useRequiredStore(RootStoreContext);

    const [store] = useState(
        () =>
            new ExportStore(
                prefix,
                config.link,
                additionalDefaultValues,
                hasFiltersParams,
                allowedFormats || commonStore.exportFormats
            )
    );
    const t = useTranslate();

    const [form] = Form.useForm(customForm);

    useEffect(() => {
        store.getFilters(config.query);
    }, [config.query]);

    const isFormInvalid = () => form.getFieldsError().some((item) => item.errors.length > 0);

    return (
        <Modal
            destroyOnClose
            visible={isShown}
            title={t(title)}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
            width={500}
            footer={false}
            centered
        >
            <Form
                onValuesChange={() => form.submit()}
                layout="vertical"
                form={form}
                initialValues={{
                    ...store.defaultValue,
                    ...additionalDefaultValues,
                }}
            >
                <Row gutter={[5, 5]}>
                    {children}
                    {withBasicExportFields ? (
                        <ExportBasicFields
                            formats={(allowedFormats || commonStore.exportFormats).map((format) => ({
                                value: format,
                                label: format.toUpperCase(),
                            }))}
                        />
                    ) : null}
                </Row>
                <Space style={{ justifyContent: "flex-end" }} direction={"horizontal"} size={[5, 5]}>
                    <Button
                        onClick={() => {
                            onClose();
                            form.resetFields();
                        }}
                    >
                        {t("CANCEL")}
                    </Button>
                    <Form.Item noStyle shouldUpdate>
                        {(values) =>
                            !isFormInvalid() ? (
                                <>{onComplete ? (
                                    <a
                                        onClick={() => {
                                            onComplete(store.getDownloadLink(values.getFieldsValue()));
                                            onClose();
                                        }}
                                        className="ant-btn ant-btn-primary"
                                    >
                                        {t("DOWNLOAD.DO_DOWNLOAD")}
                                    </a>
                                ) : (
                                    <a
                                        onClick={() => {
                                            message.success(t("DOWNLOAD.BEGINS"));
                                            onClose();
                                        }}
                                        href={store.getDownloadLink(values.getFieldsValue())}
                                        className="ant-btn ant-btn-primary"
                                    >
                                        {t("DOWNLOAD.DO_DOWNLOAD")}
                                    </a>
                                )}</>

                            ) : (
                                <Button disabled>{t("DOWNLOAD.DO_DOWNLOAD")}</Button>
                            )
                        }
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    );
};
