import React, { useState } from "react";
import { Observer, observer } from "mobx-react";
import { useParams } from "react-router-dom";
import {useTranslate} from "../../../../hooks/translate.hook";
import { WarehousesTaxesItem, WarehouseTaxes } from "../../../../models/warehouses/warehouses.taxes";
import { Country } from "../../../../models/countries/country";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterKey } from "../../../../models/filtered-table/filtered.table.props";
import { useRequiredStore } from "../../../../utils/store";
import { RootStoreContext } from "../../../../stores/root/root.store";
import { BranchDataTaxRulesStore } from "./branch.data.tax-rules.store";
import FilteredTable from "../../../common/filtered-table/filtered.table";
import { ComponentPropsFromRoute } from "../../../../routers/routers";
import { QuickLink, QuickLinks } from "../../../common/quick-links/quick-links";
import {convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import Date from "../../../date/date";
import {WarehousesTaxesModal} from "../../../accounting/warehouses-taxes/warehouses-taxes-modal/warehouses.taxes.modal";

const quickLinks: QuickLink[] = [
    {
        path: "/warehouses/taxes",
        text: "WAREHOUSES.TAXES.TITLE",
        description: "WAREHOUSES.TAXES.DESCRIPTION",
    },
    {
        path: "/articles/stock",
        text: "ARTICLES.WAREHOUSES.TITLE",
        description: "ARTICLES.WAREHOUSES.DESCRIPTION",
    },
    {
        path: "/articles/history",
        text: "ARTICLES.HISTORY",
        description: "ARTICLES.WAREHOUSES.DESCRIPTION",
    },
];

interface BranchDataTaxRulesProps extends ComponentPropsFromRoute {}
export const BranchDataTaxRules: React.FC<BranchDataTaxRulesProps> = observer(({ name }) => {
    const { branchId } = useParams<{ branchId: string }>();
    const t = useTranslate();
    const {
        langStore,
        commonStore: { warehouses, branches },
    } = useRequiredStore(RootStoreContext);
    const [store] = useState(() => new BranchDataTaxRulesStore(branchId!));

    const columns = [
        {
            title: "VAT",
            dataIndex: "vatNumber",
            key: "vatNumber",
        },
        {
            title: t("TAX.PERCENT"),
            dataIndex: "taxPercent",
            key: "taxPercent",
            width: "120px",
            render: (item: string) => <>{item}%</>,
        },
        {
            title: t("WAREHOUSES.COUNTRY"),
            dataIndex: "warehouseCountry",
            key: "warehouseCountry",
            render: (item: Country) => <>{item.name}</>,
        },
        {
            title: t("COUNTRY"),
            dataIndex: "country",
            key: "country",
            render: (item: Country) => (item ? <>{item.name}</> : t("COUNTRY_ALL")),
        },
        {
            title: t("CLIENT.TYPE"),
            dataIndex: "type",
            key: "type",
        },
        {
            title: t("TAX.RULE.STARTED_AT"),
            dataIndex: "startedAt",
            key: "startedAt",
            render: (v: any) => <Date dateString={v} />
        },
        {
            title: t("TAX.RULE.ENDED_AT"),
            dataIndex: "endedAt",
            key: "endedAt",
            render: (v: any) => <Date dateString={v} />
        },
        {
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            className: "table-button-cell",
            render: (_: any, item: WarehouseTaxes) => (
                <>
                    <Tooltip placement="topRight" title={t("EDIT")}>
                        <Button
                            className="table-btn btn"
                            onClick={() => store.openEditModal(item)}
                            type="default"
                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                        />
                    </Tooltip>
                    <Observer>
                        {() => (
                            <WarehousesTaxesModal
                                item={item}
                                countries={langStore.countries}
                                types={store.types}
                                visible={store.isEditModalShown(item)}
                                onSuccess={(request, item) =>
                                    item ? store.updateItem(request, item) : Promise.reject()
                                }
                                onClose={store.closeEditModal}
                            />
                        )}
                    </Observer>{" "}
                    <Popconfirm
                        title={t("ARE_YOU_SURE")}
                        onConfirm={() => store.removeItem(item)}
                        placement="left"
                        okText={t("YES")}
                        cancelText={t("NO")}
                    >
                        <Tooltip placement="topRight" title={t("REMOVE")}>
                            <Button
                                className="table-btn btn-remove btn"
                                type="default"
                                icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                            />
                        </Tooltip>
                    </Popconfirm>
                </>
            ),
        },
    ];

    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "vatNumber",
            label: "VAT.TITLE",
            type: "string",
        },
        {
            key: "taxCoefficient",
            label: t("TAX.COEFFICIENT"),
            type: "int",
        },
        {
            key: "countries",
            label: t("COUNTRIES"),
            type: "select",
            options: langStore.countries.map(({ id, name }) => ({ value: id as string, label: name })),
        },
    ];

    if (warehouses && branches && store.types) {
        filters.splice(
            1,
            0,
            ...([
                {
                    key: "warehouses",
                    label: t("WAREHOUSES.TITLE"),
                    type: "select",
                    options: warehouses.map(({ id, name }) => ({ value: id, label: name })),
                },
                {
                    key: "branches",
                    label: t("WAREHOUSES.AGENCY_NAME"),
                    type: "select",
                    options: branches.map(({ id, name }) => ({ value: id, label: name })),
                },
                {
                    key: "types",
                    label: t("CLIENT.TYPE"),
                    type: "select",
                    options: store.types.map((type) => ({ value: type, label: type })),
                },
            ] as FilterKey[])
        );
    }

    return (
        <Space direction="vertical" size={[20, 20]}>
            <Button type="primary" onClick={store.openCreateModal}>
                {t("WAREHOUSES.TAXES.CREATE")}
            </Button>
            <FilteredTable
                columns={columns}
                columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name }}
                filterKeys={filters}
                dataHandler={store.dataHandler}
            />
            <WarehousesTaxesModal
                countries={[{ id: null, name: t("COUNTRY_ALL") }, ...langStore.countries]}
                types={store.types}
                visible={store.isCreateModalShown()}
                onClose={store.closeCreateModal}
                onSuccess={store.createItem}
            />
            <QuickLinks links={quickLinks} />
        </Space>
    );
});
