import React from "react";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";
import "./invoice-payment.status.scss";

interface InvoicePaymentStatusProps {
    paid: boolean;
    large?: boolean;
}

export const statusColor: Record<string, any> = {
    unpaid: "red",
    paid: "green"
};

export const InvoicePaymentStatus: React.FC<InvoicePaymentStatusProps> = ({ paid, large }) => {

    const { t } = useTranslation("translation", { useSuspense: false });
    const status = paid ? "paid" : "unpaid";
    const color = statusColor[status];

    return (
        <Tag
            className={large ? "payment-status-large payment-status" : "payment-status"}
            color={color}
        >
            {t("INVOICE.PAYMENT_STATUS." + status.toUpperCase())}
        </Tag>
    );
};
