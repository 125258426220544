import { Space, Typography } from "antd";
import { observer } from "mobx-react";
import React, {useMemo} from "react";
import {useTranslate} from "../../../../../../hooks/translate.hook";
import {useArticle} from "../../../article.context";
import {columnsFactory} from "./change.columns";
import {SmartTableDataHandler} from "../../../../../common/smart-table/smart-table.data-handler";
import {articlesHistoryService} from "../../../../../../services/articles/articles/articles.history.service";
import {SmartTable} from "../../../../../common/smart-table/smart-table";

export const ArticleDataAvailabilityHistoryChange: React.FC = observer(() => {

    const t = useTranslate();
    const {article} = useArticle();

    const columns = useMemo(() => columnsFactory(t), [])
    const dataHandler = useMemo(() => new SmartTableDataHandler(r => {
        r.addFilter("id", String(article.id))
        return articlesHistoryService.getAll(r)
    }), [article.id])

    return (
        <Space direction={"vertical"} size={0}>
            <Typography.Title level={3}>{t("ARTICLES.CHANGE")}</Typography.Title>
            <Space direction="vertical" size={[20, 20]}>
                <SmartTable
                    handler={dataHandler}
                    columns={columns}
                />
            </Space>
        </Space>
    );
});
