import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, FormInstance } from "antd";
import { chain, differenceBy, isNil } from "lodash";
import { sortDirection } from "../../../utils/helpers";
import { Group, SystemGroup } from "../group/group";
import { FMSAccountValues } from "./user-data-fms/values/values";
import { FMSAccountRequest } from "./user-data-fms/requests/requests";
import { RetailAccountValues } from "./user-data-retail/values/values";
import { ConnectRequest, ConnectValues } from "./user-connect/user.connect";
import { Warehouse } from "../../warehouses/warehouse";
import { UserSystemAccess } from "./extended.user";
import { EmployeeAccount } from "../../accounts/employee-account/employee.account";

export const getNotUsedWarehouses = (formValues: any, warehouses: Warehouse[]): Warehouse[] => {
    const usedWarehouses = chain(formValues)
        .omitBy((item) => isNil(item) || isNil(item.warehouse))
        .map((item) => ({ id: item.warehouse }))
        .value();
    return differenceBy(warehouses, usedWarehouses, "id");
};

const sortValues = (
    form: FormInstance,
    formListIndex: number,
    direction: string,
    name: string | string[],
    fieldValue: string
) => {
    form.setFieldsValue({
        [fieldValue]: sortDirection(form.getFieldValue(name), formListIndex, direction),
    });
};

export const renderSortButtons = (
    form: FormInstance,
    index: number,
    length: number,
    disabled: boolean,
    name: string | string[],
    fieldValue: string
): JSX.Element => {
    if (length <= 1) {
        return (
            <Col span={4}>
                <Button
                    type="default"
                    icon={<FontAwesomeIcon icon={["fas", "arrow-down"]} />}
                    style={{ marginRight: "auto", width: "100%" }}
                    className="btn-flex-center"
                    disabled
                />
            </Col>
        );
    }
    if (index === 0) {
        return (
            <Col span={4}>
                <Button
                    type="default"
                    onClick={() => sortValues(form, index, "down", name, fieldValue)}
                    icon={<FontAwesomeIcon icon={["fas", "arrow-down"]} />}
                    style={{ marginRight: "auto", width: "100%" }}
                    className="btn-flex-center"
                    disabled={disabled}
                />
            </Col>
        );
    }
    if (index === length - 1) {
        return (
            <Col span={4}>
                <Button
                    type="default"
                    onClick={() => sortValues(form, index, "up", name, fieldValue)}
                    icon={<FontAwesomeIcon icon={["fas", "arrow-up"]} />}
                    style={{ marginRight: "auto", width: "100%" }}
                    className="btn-flex-center"
                    disabled={disabled}
                />
            </Col>
        );
    }
    return (
        <>
            <Col span={2}>
                <Button
                    type="default"
                    onClick={() => sortValues(form, index, "up", name, fieldValue)}
                    icon={<FontAwesomeIcon icon={["fas", "arrow-up"]} />}
                    style={{ marginRight: "auto", width: "100%" }}
                    className="btn-flex-center"
                    disabled={disabled}
                />
            </Col>
            <Col span={2}>
                <Button
                    type="default"
                    onClick={() => sortValues(form, index, "down", name, fieldValue)}
                    icon={<FontAwesomeIcon icon={["fas", "arrow-down"]} />}
                    style={{ marginRight: "auto", width: "100%" }}
                    className="btn-flex-center"
                    disabled={disabled}
                />
            </Col>
        </>
    );
};

export const getGroupsBySystemId = (system: { system: string }, groups: SystemGroup[]): Group[] => {
    if (system) {
        const systemGroup = groups.find((item) => item.id === system.system);
        if (systemGroup) {
            return systemGroup.groups;
        }
    }
    return [];
};

export const getGroupsBySystem = (system: string, systemGroups: SystemGroup[]): Group[] => {
    return systemGroups.find((group) => group.id === system)
        ? systemGroups.find((group) => group.id === system)!.groups
        : [];
};

const createFMSDefaultValues = (account: EmployeeAccount, systemAccesses: UserSystemAccess[]) => ({
    warehouses: account.warehouses
        .slice()
        .sort((a, b) => a.sort! - b.sort!)
        .map((item) => ({ warehouse: item.warehouse.id, maxAmount: item.maxAmount, sort: item.sort ? item.sort : 0 })),
    role: account.role ? account.role.id : null,
    prices: account.prices.map((price) => price.id),
    department: account.department ? account.department.id : null,
    systemAccesses: systemAccesses
        ? systemAccesses.map((item) => ({ system: item.system.id, group: item.group.id }))
        : [],
    status: account.status,
});

export const createDefaultValues = (access: any, type: string, systemAccesses: UserSystemAccess[]) => {
    if ("employee" === type) {
        return createFMSDefaultValues(access.account, systemAccesses);
    }
    return {};
};

const createFMSAccountRequest = ({
    warehouses,
    prices,
    department,
    systemAccesses,
    status,
    role,
    branch
}: FMSAccountValues): FMSAccountRequest => ({
    systemAccesses,
    account: {
        warehouses: warehouses.map(({ warehouse, maxAmount, readonly }, index: number) => ({
            warehouse,
            maxAmount,
            readonly,
            sort: index,
        })),
        prices,
        department,
        status,
        role,
        branch
    },
});


const createCustomerAccountRequest = ({ systemAccesses }: RetailAccountValues): any => ({
    systemAccesses,
});

export const createAccountRequest = (type: string) => {
    if (type === "employee") {
        return createFMSAccountRequest;
    }

    return createCustomerAccountRequest;
};

export const createConnectRequest = (values: ConnectValues) => {
    const result: ConnectRequest[] = [];

    values.accesses.forEach((access: any) => {
        if (access.checked) {
            result.push({
                account: access.account,
                group: access.group,
            });
        }
    });

    return result;
};
