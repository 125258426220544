import {Translate} from "../../../hooks/translate.hook";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import Date from "../../date/date";
import {Container, ContainerStatus} from "../../../models/containers/containers";
import {Link} from "react-router-dom";
import {ContainersStatus} from "../common/status/container.status";

export const columnsFactory = (t: Translate): ColumnsType<Container> => {
    return [
        {
            title: "CODE",
            dataIndex: "name",
            key: "name",
            render: (value: string, item) => <Link to={"/containers/" + item.id}>{value}</Link>
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (value: ContainerStatus) => <ContainersStatus status={value} large={false} />
        },
        {
            title: t("ORDERS.TITLE"),
            dataIndex: "totalOrders",
            key: "totalOrders",
            render: (value: number) => value
        },
        {
            title: t("ESTIMATED_ARRIVAL_DATE"),
            dataIndex: "estimatedArrivalDate",
            key: "estimatedArrivalDate",
            render: (date: string) => <Date dateString={date} />,
        },
        {
            title: t("ARRIVAL_DATE"),
            dataIndex: "arrivalDate",
            key: "arrivalDate",
            render: (date: string) => <Date dateString={date} />,
        }
    ];
}