import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {useTranslate} from "../../../../../hooks/translate.hook";
import {shipmentsService} from "../../../../../services/shipments/shipments.service";

export interface WidgetProps
{
    disabled: boolean;
    value?: any;
    onChange?: (value: any) => void;
}
export const UIInputWidgetAccountShipmentProcedure: React.FC<WidgetProps> = ({disabled, value, onChange}) => {

    const t = useTranslate();

    const [procedures, setProcedures] = useState<string[]>([]);

    useEffect(() => {
        shipmentsService.getProcedures().then(v => setProcedures(v));
    }, []);

    return (
        <Select
            disabled={disabled}
            placeholder={t("SHIPMENT_PROCEDURE")}
            value={value}
            onChange={onChange}
        >
            {procedures.map((procedure) => (
                <Select.Option key={procedure} value={procedure}>
                    {procedure}
                </Select.Option>
            ))}
        </Select>
    )
};