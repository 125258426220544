import React, {useState} from "react";
import {Button, Checkbox, Col, Form, Input, Row, Select, Space} from "antd";
import {ShipContext} from "../ship-modal";
import {observer} from "mobx-react";
import {useRequiredStore} from "../../../../../../../utils/store";
import {useTranslate} from "../../../../../../../hooks/translate.hook";
import {RootStoreContext} from "../../../../../../../stores/root/root.store";

export const AddressStep: React.FC = observer(() => {

    const t = useTranslate();
    const c = React.useContext(ShipContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { langStore } = useRequiredStore(RootStoreContext);

    if (!c.address) {
        return <></>
    }

    return <Space direction="vertical" size={[15, 15]}>
        <Space direction="vertical" size={[15, 15]}>
            <Form
                initialValues={c.address}
                form={form}
                layout="vertical"
                onFinish={values => {
                    form.validateFields().then(() => {
                        c.setAddress(values)
                        c.setStep(3)
                    })
                }}
            >
                <Space direction={"vertical"} size={[10, 10]}>
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form.Item
                                name="addressType"
                                label={t("ADDRESSES.TYPE.TITLE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    disabled={loading}
                                    placeholder={t("ADDRESSES.TYPE")}
                                >
                                    {["short", "full"].map((type) => (
                                        <Select.Option key={type} value={type}>
                                            {t("ADDRESSES.TYPE."  + type.toUpperCase())}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="companyName"
                                label={t("COMPANY_NAME")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("COMPANY_NAME")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="firstName" label={t("FIRSTNAME")}>
                                <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="lastName" label={t("LASTNAME")}>
                                <Input disabled={loading} type="text" placeholder={t("LASTNAME")} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="vat" label={"VAT"}>
                                <Input disabled={loading} type="text" placeholder={"EE00000000"} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="customsCode" label={"EORI"}>
                                <Input disabled={loading} type="text" placeholder={"EORI"} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="regCode" label={t("REG_NUMBER")}>
                                <Input disabled={loading} type="text" placeholder={t("REG_NUMBER")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="country"
                                label={t("COUNTRY")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Select
                                    disabled={loading}
                                    showSearch
                                    optionFilterProp={"children"}
                                    placeholder={t("COUNTRY_SELECT")}
                                >
                                    {langStore.countries.map(({ id, name }) => (
                                        <Select.Option key={id as string} value={id as string}>
                                            {name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="region" label={t("REGION")}>
                                <Input disabled={loading} type="text" placeholder={t("REGION")} />
                            </Form.Item>
                        </Col>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                                prevValues.addressType !== curValues.addressType
                            }
                        >
                            {() => (
                                <>
                                    {form.getFieldValue('addressType') === 'full' ? (
                                        <>
                                            <Col span={4}>
                                                <Form.Item
                                                    name="cityType"
                                                    label={t("ADDRESSES.CITY_TYPE")}
                                                >
                                                    <Input disabled={loading} type="text" placeholder={t("ADDRESSES.CITY_TYPE")} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name="city"
                                                    label={t("CITY")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                        },
                                                    ]}
                                                >
                                                    <Input disabled={loading} type="text" placeholder={t("CITY")} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="index"
                                                    label={t("INDEX")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                        },
                                                    ]}
                                                >
                                                    <Input disabled={loading} type="text" placeholder={t("INDEX")} />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    ) : (
                                        <>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="city"
                                                    label={t("CITY")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                        },
                                                    ]}
                                                >
                                                    <Input disabled={loading} type="text" placeholder={t("CITY")} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="index"
                                                    label={t("INDEX")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                                        },
                                                    ]}
                                                >
                                                    <Input disabled={loading} type="text" placeholder={t("INDEX")} />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                </>
                            )}
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                    prevValues.addressType !== curValues.addressType
                                }
                            >
                                {() => (
                                    <Form.Item
                                        name="address"
                                        label={t("ADDRESS")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                            },
                                        ]}
                                    >
                                        <Input disabled={loading || form.getFieldValue('addressType') === 'full'} type="text" placeholder={t("ADDRESS")} />
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues.addressType !== curValues.addressType
                        }
                    >
                        {() => form.getFieldValue('addressType') === 'full' ? (
                            <Row gutter={10}>
                                <Col span={4}>
                                    <Form.Item
                                        name="complexType"
                                        label={t("ADDRESSES.COMPLEX.TYPE")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.COMPLEX.TYPE")} />
                                    </Form.Item>
                                </Col>
                                <Col span={20}>
                                    <Form.Item
                                        name="complexName"
                                        label={t("ADDRESSES.COMPLEX.NAME")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.COMPLEX.NAME")} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="streetType"
                                        label={t("ADDRESSES.STREET.TYPE")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.TYPE")} />
                                    </Form.Item>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        name="streetName"
                                        label={t("ADDRESSES.STREET.NAME")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.NAME")} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="streetNo"
                                        label={t("ADDRESSES.STREET.NO")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.STREET.NO")} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name="blockNo"
                                        label={t("ADDRESSES.BLOCK.NO")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.BLOCK.NO")} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name="entranceNo"
                                        label={t("ADDRESSES.ENTRANCE.NO")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.ENTRANCE.NO")} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name="floorNo"
                                        label={t("ADDRESSES.FLOOR.NO")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.FLOOR.NO")} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name="apartmentNo"
                                        label={t("ADDRESSES.APARTMENT.NO")}
                                    >
                                        <Input disabled={loading} type="text" placeholder={t("ADDRESSES.APARTMENT.NO")} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : null}
                    </Form.Item>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                                label={t("PHONE")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("PHONE")} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label={t("EMAIL")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("FROM.ERROR.PLEASE_ENTER_VALUE"),
                                    },
                                ]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("EMAIL")} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Space>
            </Form>
        </Space>
        <Space direction="horizontal" size={[15, 15]}>
            <Button type={"primary"} onClick={() => form.submit()}>Next</Button>
            <Button type={"default"} onClick={() => c.setStep(0)}>Go back</Button>
        </Space>
    </Space>
})